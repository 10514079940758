<template>
  <div class="profile-meta" :class="{'profile-meta-single':isSingleLine, 'ba-separator one-color':addSeparator, 'no-indent':noSeparatorIndent}">
    <h3 class="profile-meta-headline">
      <slot name="meta-title"></slot>
    </h3>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      addSeparator: {
        type: Boolean,
        required: false,
      },
      noSeparatorIndent: {
        type: Boolean,
        required: false,
      },
      isSingleLine: {
        type: Boolean,
        required: false,
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";

  .profile-meta {
    padding-top: 10px;
    padding-bottom: 10px;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    &.ba-separator {
      padding-top: 13px;
    }
    p:last-child {
      margin-bottom: 0;
    }
    ul:last-child {
      margin-bottom: 4px;
    }
    span,
    .record-data-flat,
    p,
    li {
      color: $body-color;
      font-size: $font-size-base;
      font-weight: $font-weight-light;
      line-height: 1.5;
    }
    ul {
      margin-left: 15px;
      padding-left: 0;
      li {
        margin: 0 0 12px 0;
        padding-left: 17px;
        list-style: none;
        position: relative;
        &::before {
          content: "";
          background: $ba-stormy-blue;
          display: block;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 6px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .no-data {
      color: $gray-500;
      padding-left: 15px;
    }
    @include media-breakpoint-up(md) {
      padding-top: 0;
      padding-bottom: 15px;
      &.ba-separator {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
      // ba-separator
      &.no-indent::before, &::before,
      &.no-indent::after, &::after {
        display: none;
      }
      .no-data {
        padding-left: 0;
      }
      ul {
        margin-left: 2px;
        li {
          margin-bottom: 4px;
        }
      }
    }
  }
  .profile-meta-headline {
    color: $body-color;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: $font-size-base;
    font-weight: $ba-font-weight-medium;
    line-height: 1.4375;//23px
    text-align: left;

    @include media-breakpoint-up(md) {
      margin-bottom: 15px;
    }

    @include media-breakpoint-up(lg) {
      color: $body-color;
      margin-bottom: 2px;
    }
  }
  .bg-gray-lg {
    .profile-meta-headline {

      @include media-breakpoint-up(lg) {
        color: $ba-dusk;
      }
    }
  }
  .profile-meta-single {
    display: flex;
    justify-content: space-between;
    .profile-meta-headline {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      display: block;
      .profile-meta-headline {
        margin-bottom: 15px;
      }
    }
  }
</style>
