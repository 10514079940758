var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.to
    ? _c("b-link", { staticClass: "ba-icon-link", attrs: { to: _vm.to } }, [
        _c(
          "span",
          { staticClass: "inner-wrap" },
          [
            _c("font-awesome-icon", {
              staticClass: "icon",
              attrs: { size: "sm", icon: _vm.icon }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "text" }, [_vm._t("default")], 2)
          ],
          1
        )
      ])
    : _vm.route && _vm.route.name
    ? _c("b-link", { staticClass: "ba-icon-link", attrs: { to: _vm.route } }, [
        _c(
          "span",
          { staticClass: "inner-wrap" },
          [
            _c("font-awesome-icon", {
              staticClass: "icon",
              attrs: { icon: _vm.icon }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "text" }, [_vm._t("default")], 2)
          ],
          1
        )
      ])
    : _vm.route && _vm.route.url
    ? _c(
        "b-link",
        { staticClass: "ba-icon-link", attrs: { href: _vm.route.url } },
        [
          _c(
            "span",
            { staticClass: "inner-wrap" },
            [
              _c("font-awesome-icon", {
                staticClass: "icon",
                attrs: { icon: _vm.icon }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text" }, [_vm._t("default")], 2)
            ],
            1
          )
        ]
      )
    : _c(
        "b-link",
        { staticClass: "ba-icon-link", on: { click: _vm.onClick } },
        [
          _c(
            "span",
            { staticClass: "inner-wrap" },
            [
              _c("font-awesome-icon", {
                staticClass: "icon",
                attrs: { size: "sm", icon: _vm.icon }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text" }, [_vm._t("default")], 2)
            ],
            1
          )
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }