var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section-action-area" },
    [
      _c(
        "b-link",
        {
          staticClass: "section-link",
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("cancel")
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("abbrechen")))]
      ),
      _vm._v(" "),
      _c(
        "b-btn",
        {
          staticClass: "section-button",
          attrs: { variant: "primary", size: "sm" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("submit")
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("Änderungen speichern")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }