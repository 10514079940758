<template>
  <div :id="'c' + data.id" class="container text-media" :class="frameClasses">
    <div class="section-headline d-lg-none" v-if="data.header">
      <ba-content-common-header :data="data" />
    </div>
    <ba-content-common-media :data="data" :enabled-gradient="true" />
    <div class="content">
      <div class="section-headline d-none d-lg-block" v-if="data.header">
        <ba-content-common-header :data="data" />
      </div>
      <ba-content-common-content :data="data"/>
    </div>
  </div>
</template>

<script>
  import BaContentCommonMixin from '@/mixins/contentCommonMixin';
  import BaContentCommonMedia from '@/components/partials/Content/Common/Media';
  import BaContentCommonContent from '@/components/partials/Content/Common/Content';

  export default {
    mixins: [BaContentCommonMixin],
    components: {
      BaContentCommonMedia,
      BaContentCommonContent,
    },
    computed: {
      frameClasses: function () {
        const classes = this.defaultFrameClasses;
        const imageOrient = this.data.imageorient ? parseInt(this.data.imageorient) : 0;
        classes.push('media-layout-' + imageOrient);
        return classes;
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .text-media {
    .block-media {
      margin-bottom: 18px;
    }
    .asset {
      text-align: center;
    }
    .section-headline {
      margin-bottom: 15px;
    }
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      .block-media {
        flex: 0 1 60%;
        max-width: 670px;
        margin-bottom: 0;
        margin-right: 30px;
      }
      .content {
        flex: 1 1 auto;
      }
      &.media-layout-26 {
        .asset {
          text-align: left;
        }
      }
    }
  }
  .container {
    >.text-media {
      .block-media {
        margin-left: -($grid-gutter-width/2);
        margin-right: -($grid-gutter-width/2);
        @include media-breakpoint-up(lg) {
          margin-left: 0;
          margin-right: 30px;
        }
      }
    }
  }
</style>
