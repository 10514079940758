var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      class: _vm.formGroupClass,
      attrs: { label: _vm.label, "label-for": _vm.id }
    },
    [
      _c(
        "multiselect",
        {
          attrs: {
            id: _vm.id,
            options: _vm.choices,
            multiple: _vm.multiple,
            placeholder: _vm.placeholder
              ? _vm.placeholder
              : _vm.$t("form.select.placeholder.general"),
            "open-direction": "bottom",
            "track-by": "name",
            label: _vm.choiceLabel,
            searchable: true,
            loading: _vm.isLoading,
            "internal-search": false,
            "clear-on-select": false,
            "close-on-select": true,
            "options-limit": 300,
            limit: 3,
            "limit-text": _vm.limitText,
            "max-height": 600,
            "show-no-results": false,
            "hide-selected": false
          },
          on: { input: _vm.onUpdateChoice, "search-change": _vm.asyncFind },
          scopedSlots: _vm._u([
            {
              key: "tag",
              fn: function(ref) {
                var option = ref.option
                var remove = ref.remove
                return [
                  _c("span", { staticClass: "custom__tag" }, [
                    _c("span", [_vm._v(_vm._s(option.name))]),
                    _c(
                      "span",
                      {
                        staticClass: "custom__remove",
                        on: {
                          click: function($event) {
                            return remove(option)
                          }
                        }
                      },
                      [_vm._v("❌")]
                    )
                  ])
                ]
              }
            },
            {
              key: "clear",
              fn: function(props) {
                return [
                  _vm.internalValue && _vm.internalValue.length
                    ? _c("div", {
                        staticClass: "multiselect__clear",
                        on: {
                          mousedown: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.clearAll(props.search)
                          }
                        }
                      })
                    : _vm._e()
                ]
              }
            }
          ]),
          model: {
            value: _vm.internalValue,
            callback: function($$v) {
              _vm.internalValue = $$v
            },
            expression: "internalValue"
          }
        },
        [
          _vm._v(" "),
          _c("template", { slot: "singleLabel" }, [
            _vm.internalValue && _vm.internalValue.name
              ? _c("span", { staticClass: "multiselect__tag" }, [
                  _c("span", [_vm._v(_vm._s(_vm.internalValue.name))]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "multiselect__tag-icon",
                    attrs: { "aria-hidden": "true", tabindex: "1" },
                    on: {
                      mousedown: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.clearValue()
                      }
                    }
                  })
                ])
              : _c("span", { staticClass: "multiselect__placeholder" }, [
                  _vm._v(
                    _vm._s(
                      _vm.placeholder
                        ? _vm.placeholder
                        : _vm.$t("form.select.placeholder.general")
                    )
                  )
                ])
          ]),
          _vm._v(" "),
          _vm._v(" "),
          _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
            _vm._v(_vm._s(_vm.$t("form.select.no_results")))
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }