<template>
  <b-form-group :label="label" :label-for="id" :class="formGroupClass">
    <multiselect
      :id="id"
      v-model="internalInternalValue"
      :options="choices"
      :multiple="false"
      :placeholder="placeholder ? placeholder : $t('form.select.placeholder.general')"
      open-direction="bottom"
      track-by="name"
      label="name"
      @input="onUpdateChoice"
      :searchable="true"
      :loading="isLoading"
      :internal-search="false"
      :close-on-select="true"
      :allow-empty="true"
      :options-limit="300"
      :limit-text="limitText"
      :max-height="600"
      :hide-selected="false"
      @open="lazyLoadValues(value)"
      @search-change="asyncFind"
    >
      <template slot="tag" slot-scope="{ option, remove }">TAG<span class="custom__tag"><span>{{ option.name }}</span><span class="custom__remove" @click="remove(option)">❌</span></span></template>
      <template slot="placeholder">
        <span class="multiselect__tag" v-if="value">
          <span>{{ value }}</span> <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @mousedown.prevent.stop="clearValue()"></i>
        </span>
        <span v-else>{{placeholder ? placeholder : $t('form.select.placeholder.general')}}</span>
      </template>
      <template slot="clear" slot-scope="props">
        <div class="multiselect__clear" v-if="internalInternalValue && internalInternalValue.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
      </template>
      <template slot="option" slot-scope="props">
        <span class="multiselect__option" v-html="wrapSearchString(props.option.name, props.search)"></span>
      </template>
      <span slot="noResult">{{ $t('kein Ergebnis gefunden') }}</span>
    </multiselect>
  </b-form-group>
</template>

<script>
  import { getMatchingCompanies } from '@/api/company';
  import BaMultiSelect from '@/mixins/multiSelect.js';

  export default {
    mixins: [BaMultiSelect],
    data: () => ({
      defaultChoice: {},
      choices: [],
      isLoading: false,
      lastValue: false,
      internalInternalValue: null,
    }),
    created () {
      this.defaultChoice.id = 0;
      this.defaultChoice.name = '';
      this.assignDefaultChoices();
    },
    methods: {
      limitText: function (count) {
        return `und ${count} andere Firmen`;
      },
      assignDefaultChoices: function () {
        this.choices = [this.defaultChoice];
      },
      lazyLoadValues: function (query) {
        if (query && query.trim().length > 0) {
          return new Promise((resolve) => {
            this.isLoading = true;
            this.assignDefaultChoices();
            getMatchingCompanies(query).then(response => {
              const choices = [];
              this.assignDefaultChoices();
              for (let i = 0, n = response.data.length; i < n; ++i) {
                const choice = response.data[i];
                if (choice.name) {
                  const choiceObject = { id: choice.id, name: choice.name };
                  choices.push(choiceObject);
                  this.choices.push(choiceObject);
                }
              }
              this.isLoading = false;
              resolve(choices);
            });
          });
        }
      },
      asyncFind: function (query) {
        this.defaultChoice.name = query;
        this.assignDefaultChoices();
        if (query.length > 2) {
          this.lastValue = query;
          // wait 300ms and check if value has changed since
          setTimeout(() => {
            if (query === this.lastValue) {
              this.lazyLoadValues(query);
            }
          }, 300);
        } else {
        }
      },
      clearAll: function () {
        this.internalValue = null;
      },
      clearValue: function () {
        this.internalValue = '';
        this.$emit('input', '', this.formGroupOffset);
      },
      onUpdateChoice (value) {
        if (value) {
          this.$emit('input', value.name, this.formGroupOffset);
        };
      },
      wrapSearchString (html, search) {
        if (search) {
          html = html.replace(new RegExp(`(${search})`, 'i'), '<strong>$1</strong>');
        }
        return html;
      },
    },
    watch: {
      internalValue: function (value) {
        if (value) {
          const matchingChoices = this.choices.filter(({ name }) => {
            return value == name;
          });
          if (matchingChoices.length > 0) {
            this.internalInternalValue = matchingChoices[0];
            return;
          } else {
            this.defaultChoice.name = value;
            this.lazyLoadValues(value);
          }
          this.internalInternalValue = this.defaultChoices;
        }
      },
    },
  };
</script>

<i18n src="@/mixins/multiSelect.i18n.yaml" lang="yaml"/>
