var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.buttonLink && _vm.data.buttonText
    ? _c(
        "div",
        { staticClass: "block-button" },
        [
          _c(
            "b-btn",
            {
              attrs: {
                variant: _vm.data.buttonLayout,
                href: _vm.data.buttonLink,
                target: "_blank"
              }
            },
            [_vm._v(_vm._s(_vm.data.buttonText))]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }