import { getPage } from '@/api/cms';

export default {
  head: {
    title: function () {
      return {
        inner: this.pageTitle,
      };
    },
  },
  props: {
    pageId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    pageTitle: '...',
    pageData: null,
    isLoadingContent: false,
    groupStatus: null,
    groupBlockCount: 0,
  }),
  created: function () {
    if (this.pageId) {
      this.loadPage(this.pageId);
    }
  },
  computed: {
    groupedBlocks: function () {
      const groups = [];
      let currentGroup = {
        key: 'block',
        class: 'default',
        blocks: [],
      };
      for (let i = 0, n = this.pageData.blocks.length; i < n; i++) {
        const block = this.pageData.blocks[i];
        const blockGroupStatus = this.getBlockGroupStatus(block);
        if (blockGroupStatus === 'createGroup' || blockGroupStatus === 'closeGroup') {
          if (currentGroup.blocks.length > 0) {
            groups.push(currentGroup);
          }
          let blockClass = blockGroupStatus === 'createGroup'
            ? 'group-' + block.type.replace('_', '-') + '-' + block.layout : 'default';
          if (blockClass === 'group-teaser-2') {
            blockClass = 'container ' + blockClass;
          }
          currentGroup = {
            class: blockClass,
            blocks: [],
          };
        }
        currentGroup.key += '-' + block.id;
        currentGroup.blocks.push(block);
      }
      if (currentGroup.blocks.length > 0) {
        groups.push(currentGroup);
      }
      return groups;
    },
  },
  methods: {
    getBlockLayout: function (block) {
      return block.layout ? parseInt(block.layout) : 0;
    },
    getBlockGroupStatus: function (block) {
      let blockGroupStatus = null;
      if (block.type === 'teaser' && this.getBlockLayout(block) === 2) {
        // >= 2 => close after adding third block
        if (this.groupStatus === null || this.groupBlockCount >= 2) {
          blockGroupStatus = 'createGroup';
          this.groupBlockCount = 0;
        } else {
          blockGroupStatus = 'inGroup';
          ++this.groupBlockCount;
        }
        this.groupStatus = blockGroupStatus;
      } else if (this.groupStatus !== null) {
        blockGroupStatus = 'closeGroup';
        this.groupBlockCount = 0;
        this.groupStatus = null;
      } else {
        ++this.groupBlockCount;
      }
      return blockGroupStatus;
    },
    loadPage: function (pageId) {
      const self = this;
      self.isLoadingContent = true;
      getPage(pageId)
        .then(({ data: response }) => {
          self.pageData = response;
          self.pageTitle = response.title;
          self.$emit('updateHead');
        })
        .finally(() => {
          self.isLoadingContent = false;
        })
      ;
    },
  },
};
