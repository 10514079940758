var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container header",
      class: _vm.defaultFrameClasses,
      attrs: { id: "c" + _vm.data.id }
    },
    [
      _vm.headerLayout === 1
        ? _c(
            "div",
            {
              staticClass: "module-header",
              class: { uppercase: _vm.uppercase }
            },
            [
              _c("h1", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.data.header))
              ]),
              _vm._v(" "),
              _vm.data.subheader
                ? _c("h2", { staticClass: "caption" }, [
                    _vm._v("\n      " + _vm._s(_vm.data.subheader) + "\n    ")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.data.bodytext
                ? _c("div", {
                    staticClass: "lead",
                    domProps: { innerHTML: _vm._s(_vm.data.bodytext) }
                  })
                : _vm._e()
            ]
          )
        : _vm.data.header
        ? _c("ba-content-common-header", { attrs: { data: _vm.data } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }