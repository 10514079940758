var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "image-section" }, [
    _c("h3", { staticClass: "d-lg-none" }, [_vm._v(_vm._s(_vm.caption))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "image" },
      [_c("b-img", { attrs: { src: _vm.image, fluid: "" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("h3", { staticClass: "d-none d-lg-block" }, [
          _vm._v(_vm._s(_vm.caption))
        ]),
        _vm._v(" "),
        _vm._t("default", [
          _vm.content ? _c("p", [_vm._v(_vm._s(_vm.content))]) : _vm._e()
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }