var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "matrix",
      staticClass: "company-course-matrix",
      class: {
        "show-details": _vm.showDetails,
        "d-none": _vm.filteredStudyOffers.length === 0
      }
    },
    [
      _vm.showDetails
        ? _c("div", { staticClass: "matrix-top" }, [
            _vm.showSearchedOffersState === 1
              ? _c("h3", { staticClass: "headline" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.partials.company.course_matrix.headline_search_offers"
                      )
                    )
                  )
                ])
              : _vm.showSearchedOffersState === 2
              ? _c("h3", { staticClass: "headline" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.partials.company.course_matrix.headline_other_offers"
                      )
                    )
                  )
                ])
              : _c("h3", { staticClass: "headline" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.partials.company.course_matrix.study_interest"
                      )
                    )
                  )
                ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "matrix-head" }, [
        _c(
          "div",
          {
            staticClass: "matrix-row matrix-head-row",
            class: { "no-bookmarks": !_vm.bookmarksEnabled }
          },
          [
            _vm.showSearchedOffersState === 1
              ? _c("div", { staticClass: "col col-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.partials.company.course_matrix.headline_search_offers"
                      )
                    )
                  )
                ])
              : _vm.showSearchedOffersState === 2
              ? _c("div", { staticClass: "col col-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.partials.company.course_matrix.headline_other_offers"
                      )
                    )
                  )
                ])
              : _c("div", { staticClass: "col col-label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.partials.company.course_matrix.study_interest"
                      )
                    )
                  )
                ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col col-years" },
              _vm._l(_vm.availableStudyYears, function(year) {
                return _c("div", { key: year, staticClass: "col col-year" }, [
                  _vm._v(_vm._s(year))
                ])
              }),
              0
            ),
            _vm._v(" "),
            _vm.bookmarksEnabled
              ? _c("div", { staticClass: "col col-bookmark" }, [_vm._v(" ")])
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "matrix-body" },
        [
          _vm._l(_vm.filteredStudyOffers, function(offer) {
            return [
              _c("ba-company-course-offer", {
                key: _vm.company.id + "_" + offer.studyInterest.id,
                attrs: {
                  offer: offer,
                  "company-name": _vm.company.name,
                  "bookmarks-enabled": _vm.bookmarksEnabled,
                  years: _vm.years,
                  "show-details": _vm.showDetails,
                  "is-bookmarked": _vm.isBookmarked(offer)
                },
                on: { "bookmark-toggle": _vm.onToggleBookmark }
              })
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }