<template>
  <b-row class="profile-nav" :class="{'bottom': isBottom}">
    <div class="col col-left" v-if="hasTextSlot('left')">
      <slot name="left"></slot>
    </div>
    <div class="col" v-if="hasTextSlot('right')" :class="{'col-right': hasTextSlot('left')}">
      <slot name="right"></slot>
    </div>
  </b-row>
</template>

<script>
  export default {
    props: {
      isBottom: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    methods: {
      hasTextSlot (name) {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .profile-nav {
    margin-bottom: .5rem;
    &::before {
      box-shadow: 0 -2px 3px 0 $ba-very-light-pink;
      content: "";
      display: block;
      height: 16px;
      width: 100%;
    }
    &.bottom {
      margin-top: 1.25rem;
      &::before {
        display: none;
      }
    }
    .col {
      text-align: center;
      flex: 0 0 100%;
    }
    .col-left {
      margin-bottom: 20px;
    }

    .ba-icon-link {
      text-transform: uppercase;
      font-size: $ba-font-size-sm;
      font-weight: $ba-font-weight-medium;
      letter-spacing: .1px;
    }

    .ba-icon-link + .ba-icon-link {
      margin-left: 10px;
    }

    @include media-breakpoint-up(sm) {
      box-shadow: none;
      margin-bottom: .5rem;

      .col-left {
        text-align: left;
        margin-bottom: 0;
        flex: 1 1 0;
      }
      .col-right {
        text-align: right;
        flex: 1 1 0;
      }

      &::before {
        display: none;
      }
      .col:not(.col-left) {
        text-align: right;
      }
    }
  }
</style>
