var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ba-card-wrapper" },
    [
      _vm.isHighlighted ? _c("div", [_vm._t("result-highlight")], 2) : _vm._e(),
      _vm._v(" "),
      _c(
        "b-card",
        {
          staticClass: "ba-card card-search card-round-lg",
          class: {
            "no-padding": _vm.disableBodyPadding,
            "jq-clickable": _vm.isClickable,
            highlighted: _vm.isHighlighted,
            "has-actions": _vm.hasActions
          },
          attrs: { "no-body": "", "header-text-variant": "white" }
        },
        [
          _c(
            "b-card-header",
            {
              staticClass: "ba-card-header list-item-header",
              class: { "has-image": _vm.hasHeaderImage }
            },
            [
              _vm._t("result-header-image"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "card-headline",
                  on: { click: _vm.onItemClick }
                },
                [
                  _c("h3", { staticClass: "headline h2" }, [
                    _vm._v(_vm._s(_vm.header))
                  ]),
                  _vm._v(" "),
                  _vm._t("result-header-meta")
                ],
                2
              ),
              _vm._v(" "),
              _c("ba-profile-actions", [_vm._t("result-actions")], 2)
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "b-card-body",
            { staticClass: "ba-card-body", on: { click: _vm.onItemClick } },
            [_vm._t("result-body")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }