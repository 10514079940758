<template>
  <div class="page">
    <div class="loader-outer" v-if="isLoadingContent">
      <div class="loader-dot-slide"></div>
    </div>
    <div class="content-blocks" :class="blockGroup.class" v-for="blockGroup in groupedBlocks" :key="blockGroup.key" v-else>
      <template v-for="block in blockGroup.blocks">
        <component :is="'ba-content-block-' + block.type.replace('_', '-')" :data="block"/>
      </template>
    </div>
  </div>
</template>

<script>
  import BaModuleHeader from '@/components/partials/General/BaModuleHeader';
  import BaSectionImage from '@/components/partials/General/BaSectionImage';
  import BaSectionText from '@/components/partials/General/BaSectionText';
  import BaSectionCallToAction from '@/components/partials/General/BaSectionCallToAction';
  import BaContentBlockHeader from '@/components/partials/Content/Header';
  import BaContentBlockHtml from '@/components/partials/Content/Html';
  import BaContentBlockTeaser from '@/components/partials/Content/Teaser';
  import BaContentBlockText from '@/components/partials/Content/Text';
  import BaContentBlockTextMedia from '@/components/partials/Content/TextMedia';
  import BaPageContentMixin from '@/mixins/pageContentMixin';

  export default {
    mixins: [BaPageContentMixin],
    components: {
      BaModuleHeader,
      BaSectionImage,
      BaSectionText,
      BaSectionCallToAction,
      BaContentBlockHeader,
      BaContentBlockHtml,
      BaContentBlockTeaser,
      BaContentBlockText,
      BaContentBlockTextMedia,
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  @import "../../../style/content/_common";
</style>
