<template>
  <div class="page-result-count hide-while-loading" :class="{'has-reset': showReset}">
    <span class="result-count-info">
      <span class="text-secondary">{{results}}</span>
      <span>{{ $tc('components.partials.general.pagination.results', results) }}</span>
    </span>
    <span class="btn-reset" @click="onResetSearch" v-if="showReset">
      {{ $t('components.partials.general.actions.reset') }}
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      results: {
        type: [Number, String],
        required: true,
      },
      showReset: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      onResetSearch: function () {
        this.$emit('reset', this.search);
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .page-result-count {
    font-weight: $ba-font-weight-medium;
    font-size: $ba-font-size-sm;
    letter-spacing: .9px;
    color: $primary;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 50px;
    &.has-reset {
      min-width: 300px;
    }

    .result-count-info {
      display: block;
      text-align: center;
      margin-bottom: 3px;
    }
    .btn-reset {
      display: inline-block;
      color: $primary;
      cursor: pointer;
      font-size: .75rem;
      text-decoration: underline;

      @include hover-focus-active {
        text-decoration: none;
      }
    }

    @include media-breakpoint-up(md) {
      .result-count-info {
        display: inline-block;
        margin-bottom: 0;
      }
      .btn-reset {
        margin-left: 20px;
      }
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 20px;
      text-align: left;
    }
  }
</style>

<i18n lang="yaml" locale="de">
  components.partials.general.pagination.results: 'Ergebnis | Ergebnisse'
  components.partials.general.actions.reset: 'Suche zurücksetzen'
</i18n>
