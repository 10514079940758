<template>
  <div class="teaser bg-light layout-0">
    <div class="container box-inner">
      <h3 class="headline h2" v-if="caption">{{ caption }}</h3>
      <div class="body">
        <slot><p v-if="content">{{ content }}</p></slot>
        <div class="text-center">
          <b-btn variant="primary" :href="buttonLink" v-if="buttonLink">{{ buttonContent }}</b-btn>
          <b-btn variant="primary" :to="buttonTo" v-else-if="buttonTo">{{ buttonContent }}</b-btn>
          <b-btn variant="primary" @click="(e) => $emit('click', e)" v-else>{{ buttonContent }}</b-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      caption: {
        type: String,
        required: false,
      },
      content: {
        type: String,
        required: false,
      },
      buttonLink: {
        type: String,
        required: false,
      },
      buttonTo: {
        type: [String, Object],
        required: false,
      },
      buttonContent: {
        type: String,
        required: false,
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  @import "../../../style/content/_teaser";
</style>
