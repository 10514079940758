var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "control-button-group form-group-save-search",
      class: {
        "enable-input": _vm.editState,
        "is-saving": _vm.waitingForSave,
        "just-saved": _vm.hasJustBeenSaved
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "form-group control-label-overlay",
          class: { "has-value": _vm.editStateHasValue }
        },
        [
          _c("b-form-input", {
            ref: "nameInput",
            attrs: {
              id: _vm.nameInputId,
              required: "",
              type: "text",
              state: _vm.nameInputState
            },
            on: { input: _vm.onSearchNameInputChange },
            model: {
              value: _vm.modelValue,
              callback: function($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue"
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "control-label", attrs: { for: _vm.nameInputId } },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "components.partials.general.ba_save_named_button.search_name"
                  )
                )
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-btn",
        {
          staticClass: "show-default",
          attrs: { variant: _vm.variant },
          on: { click: _vm.open }
        },
        [
          _vm.hasJustBeenSaved
            ? _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.partials.general.ba_save_named_button.search_saved"
                    )
                  )
                )
              ])
            : _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.partials.general.ba_save_named_button.save_search"
                    )
                  )
                )
              ])
        ]
      ),
      _vm._v(" "),
      _c(
        "b-btn",
        {
          staticClass: "show-enabled",
          class: { "has-icon": _vm.saveIcon },
          attrs: { variant: "primary" },
          on: { click: _vm.save }
        },
        [
          _vm.saveIcon
            ? _c("font-awesome-icon", {
                attrs: { icon: ["fas", _vm.saveIcon] }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("span", { class: { "sr-only": _vm.saveIcon } }, [
            _vm._v(
              _vm._s(
                _vm.$t("components.partials.general.ba_save_named_button.save")
              )
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }