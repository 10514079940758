<template>
  <div class="text-section-content">
    <h3 v-if="caption">{{ caption }}</h3>
    <slot><p v-if="content">{{ content }}</p></slot>
  </div>
</template>

<script>
  export default {
    props: {
      caption: {
        type: String,
        required: false,
      },
      content: {
        type: String,
        required: false,
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  @import "../../../style/content/_text";
</style>
