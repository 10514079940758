<template>
  <div class="profile-card-actions" v-if="hasDefaultSlot">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    computed: {
      hasDefaultSlot () {
        return !!this.$slots.default;
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";

  .profile-card-actions {
    display: flex;
    //align-items: center;
    margin: 9px -15px 0;
    width: calc(100% + 30px);
    flex: 0 0 calc(100% + 30px);
    border-top: 1px solid $ba-list-item-header-border-color;

    > .col {
      padding: 0;

      &:not(:first-child) {
        border-left: 1px solid $ba-card-actions-border-color;
      }

      @include media-breakpoint-up(lg) {
        max-width: 116px;
        &:not(:first-child) {
          border-width: 0;
        }
      }
    }

    &.no-border > .col {
      @include media-breakpoint-up(lg) {
        max-width: initial;
      }
    }

    .btn-action {
      border-radius: 0;
      color: $ba-main-color3;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      min-height: $ba-card-header-with-actions-min-height;
      font-size: $ba-font-size-sm;
      letter-spacing: .1px;
      margin: 0 auto;
      padding: 0;
      text-align: center;
      transition: $transition-base;
      text-transform: uppercase;
      white-space: normal;

      .action-icon {
        color: inherit;
        width: 23px;
        height: 23px;
        transition: $transition-base;
      }

      .text {
        display: block;
        margin-top: 6px;
        padding: 0 13px;
        overflow: visible;
        max-width: 116px;

        &.text-rating {
          padding: 0 8px;
        }
      }

      .text-desktop {
        display: none;
      }

      @include hover-focus {
        color: #fff;
        background-color: $primary;
      }

      @include media-breakpoint-up(lg) {
        min-height: $ba-card-header-with-actions-min-height-lg;
        //height: $ba-card-header-with-actions-height-lg;
        .icon-wrap {
          display: block;
          min-height: 25px;
        }
        .text-mobile {
          display: none;
        }
        .text-desktop {
          display: block;
        }
      }
    }

    .btn-inline-action {
      color: $ba-main-color3;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: $ba-font-size-sm;
      letter-spacing: .1px;
      margin: 0 auto;
      padding: 0;
      text-align: center;
      transition: $transition-base;
      text-transform: uppercase;
      white-space: normal;

      .action-icon {
        width: 20px;
        height: 20px;
        transition: $transition-base;
      }

      .text {
        display: block;
        padding: 4px 10px 0;
        overflow: visible;
      }

      @include hover-focus {
        .text {
          text-decoration: underline;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      border-left: 1px solid #fff;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      border-top-width: 0;
      margin: 0 0 0 auto;
      width: auto;
      flex-basis: auto;
      &.no-border {
        border-left-width: 0;
      }
    }
  }
</style>
