<template>
  <div :class="'csc-header-' + headerLayout" v-if="data.header">
    <h1 class="headline" v-if="headerLayout === 1">{{ data.header }}</h1>
    <h3 class="headline" v-else-if="headerLayout === 3">{{ data.header }}</h3>
    <h4 class="headline" v-else-if="headerLayout === 4">{{ data.header }}</h4>
    <h5 class="headline" v-else-if="headerLayout === 5">{{ data.header }}</h5>
    <h6 class="headline" v-else-if="headerLayout === 6">{{ data.header }}</h6>
    <h2 class="headline" v-else>{{ data.header }}</h2>
    <div class="csc-subheader" v-if="data.subheader">
      <h2 class="subheader" v-if="headerLayout === 1">{{ data.subheader }}</h2>
      <h3 class="subheader" v-else-if="headerLayout === 2">{{ data.subheader }}</h3>
      <h4 class="subheader" v-else-if="headerLayout === 3">{{ data.subheader }}</h4>
      <h5 class="subheader" v-else-if="headerLayout === 4">{{ data.subheader }}</h5>
      <h6 class="subheader" v-else-if="headerLayout === 5">{{ data.subheader }}</h6>
      <p v-else>{{ settings.subheader }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    computed: {
      headerLayout: function () {
        return this.data && this.data.headerLayout ? parseInt(this.data.headerLayout) : 2;
      },
    },
  };
</script>
