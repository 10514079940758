var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-section-content" },
    [
      _vm.caption ? _c("h3", [_vm._v(_vm._s(_vm.caption))]) : _vm._e(),
      _vm._v(" "),
      _vm._t("default", [
        _vm.content ? _c("p", [_vm._v(_vm._s(_vm.content))]) : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }