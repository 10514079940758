<template>
  <b-modal :id="identifier" :title="$t('message.modal.title')" ref="threadModal"
     @hidden="hide"
     @ok="submit"
  >
    <form @submit.prevent="submit" class="modal-form-message-create" :class="{'has-file': newThreadHasAttachment, 'is-saving': isSaving}" ref="form">
      <div class="form-group">
      <b-form-textarea
        no-resize
        v-model="message"
        :placeholder="$t('message.text.input.placeholder')"
      ></b-form-textarea>
      </div>
      <div class="form-group">
        <div class="thread-form attachment-container" v-if="newThreadHasAttachment">
          <div class="attachment-file-wrapper">
            <div class="attachment-file" :class="{'has-error': attachmentValidationState > 0}">
              <font-awesome-icon size="lg" class="inline-icon" :icon="['far', 'file']"/>
              <span class="file-name">{{ threadAttachmentChanges.name }}</span>
              <font-awesome-icon size="lg" class="remove-attachment-icon float-right" icon="times" @click="handleThreadAttachmentRemoval"/>
            </div>
            <div class="attachment-file-error" v-if="attachmentValidationState === 1">{{ $t('components.partials.message.modal.invalid_attachment_size') }}</div>
            <div class="attachment-file-error" v-else-if="attachmentValidationState > 1">{{ $t('components.partials.message.modal.invalid_attachment_type') }}</div>
          </div>
        </div>
        <input
          ref="threadUpload"
          type="file"
          name="threadUpload"
          id="thread-file"
          class="inputfile"
          accept="text/plain, application/pdf, image/*"
          @change="handleThreadAttachmentUpload()"
        />
        <div class="attachment-footer">{{ $t('components.partials.message.modal.attachment_footer') }}</div>
      </div>
    </form>

    <template v-slot:modal-footer="{ cancel }">
      <b-button variant="secondary" @click="cancel()" :disabled="isSaving">{{ $t('message.modal.action.cancel') }}</b-button>
      <b-button variant="primary" @click="submit" :disabled="isSaving">{{ $t('message.modal.action.submit') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
  import { sendMessage, addMessageAttachment } from '@/api/messaging';

  export default {
    props: {
      recipientId: {
        type: Number,
        required: true,
      },
    },
    data: () => ({
      message: '',
      threadAttachmentChanges: null,
      isSaving: false,
      hasGeneralError: false,
      hasErrors: false,
      errors: {
      },
      attachmentMeta: null,
    }),
    computed: {
      identifier: function () {
        return 'ba-compose-message-modal-' + this.recipientId;
      },
      newThreadHasAttachment: function () {
        return this.threadAttachmentChanges;
      },
      attachmentValidationState: function () {
        const attachmentMeta = this.attachmentMeta;
        if (attachmentMeta) {
          if (attachmentMeta.size > 5120000) {
            return 1;
          }
          if (attachmentMeta.type.match(/(text\/plain|application\/pdf|image\/.*)/) === null) {
            return 2;
          }
          if (attachmentMeta.name.match(/.*\.(jpg|png|pdf|svg)$/i) === null) {
            return 2;
          }
        }

        return 0;
      },
    },
    methods: {
      show: function () {
        this.$refs.threadModal.show();
      },
      hide: function () {
        if (this.$refs.threadModal) {
          this.$refs.threadModal.hide();
        }
      },
      submit: function () {
        const self = this;
        self.isSaving = true;
        self.hasGeneralError = false;
        self.hasErrors = false;
        self.errors = {};
        sendMessage(this.recipientId, this.message)
          .then((response) => {
            self.$emit('sent', response.data);
            if (self.threadAttachmentChanges) {
              addMessageAttachment(response.data.id, self.threadAttachmentChanges);
            }
          }).catch(error => {
            if (error.status && error.status === 'VALIDATION_ERROR') {
              for (let ve = 0; ve < error.data.length; ve++) {
                self.setPropertyError(error.data[ve].propertyPath);
              }
            }
            self.hasErrors = true;
            self.isSaving = false;
          })
          .finally(() => {
            self.isSaving = false;
            self.hide();
            self.message = '';
            self.threadAttachmentChanges = null;
            self.$refs.form.reset();
          });
        setTimeout(function () {
          if (self.isSaving) {
            self.hasGeneralError = true;
            self.hasErrors = true;
            self.isSaving = false;
          }
        }, 2000);
      },
      handleThreadAttachmentUpload: function () {
        const file = this.$refs.threadUpload.files[0];
        if (file) {
          this.threadAttachmentChanges = file;
          this.attachmentMeta = {
            name: file.name,
            size: file.size,
            type: file.type,
          };
        }
      },
      handleThreadAttachmentRemoval: function () {
        this.$refs.threadUpload.value = '';
        this.attachmentMeta = null;
        this.threadAttachmentChanges = null;
      },
      setPropertyError (propertyPath) {
        const pathParts = propertyPath.split('.');
        let propertyErrorPath = '';
        let prefix = '';
        const regex = /(\w+)(\[(\d+)\])?/gm;
        for (let i = 0, n = pathParts.length; i < n; i++) {
          let propertySubPath;
          const matches = regex.exec(pathParts[i]);
          // Convert array properties to flat string: myVar[1].bla.blubb => myVar_1_bla_blubb
          if (matches && matches[3] !== undefined) {
            propertySubPath = matches[1] + '_' + matches[3];
          } else {
            propertySubPath = pathParts[i];
          }
          propertyErrorPath = prefix + propertySubPath;
          prefix += propertySubPath + '_';
        }
        this.errors[propertyErrorPath] = true;
      },
    },
  };
</script>

<i18n lang="yaml" locale="de">
  message.text.input.placeholder: 'Nachricht eingeben'
  message.modal.title: 'Nachricht schreiben'
  message.modal.action.submit: 'Senden'
  message.modal.action.cancel: 'Abbrechen'
  components.partials.message.modal.upload_error: 'Bitte lade nur PDF oder ZIP-Dateien mit einer maximalen Dateigröße von 5MB hoch.'
  components.partials.message.modal.attachment_footer: 'Upload möglich bis max. 5 MB und in den Formaten: .pdf, .jpg, .svg, .png'
  components.partials.message.modal.invalid_attachment_size: 'Die hochgeladene Datei ist zu groß. Bitte nur Dateien mit max. 5 MB hochladen.'
  components.partials.message.modal.invalid_attachment_type: 'Der Dateitype der hochgeladenen Datei ist nicht erlaubt. Bitte nur Bilder oder PDF-Dokumente hochladen.'
</i18n>

<style type="text/scss">
  @import "../../../style/_init-vars-functions-mixins";
  .modal-form-message-create {
    &.is-saving {
      .form-control {
        border-color: #fff;
      }
    }
    .attachment-footer {
      font-size: $ba-font-size-sm;
      letter-spacing: 1.6px;
      margin-top: 6px;
    }
    .attachment-file-wrapper {
      width: 100%;
      margin-bottom: 6px;
    }
    .attachment-file-error {
      color: $ba-red;
      border: 1px solid $ba-red;
      font-size: $ba-font-size-xs;
      padding: 2px;
      position: relative;
      &:before, &:after {
        width: 0;
        height: 0;
        -webkit-transform: rotate(360deg);
        border-style: solid;
        border-width: 0 4px 4px 4px;
        border-color: transparent transparent $ba-red transparent;
        content: " ";
        top: -4px;
        position: absolute;
        left: 4px;
      }
      &:after {
        border-color: transparent transparent white transparent;
        top: -3px;
      }
    }
  }
</style>
