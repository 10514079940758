<template>
  <div class="profile-section" :class="{'account-section': isAccountSection, 'editable': editable, 'is-edit-mode': editMode, 'ba-form-submitted': isSaving, 'disabled': disabled}">
    <div class="profile-view">
      <h3 :class="headlineClass" v-html="caption"></h3>
      <div class="profile-section-content">
        <slot v-if="!editMode"></slot>
        <slot name="edit" v-else></slot>
      </div>
      <div class="profile-section-edit" v-if="editable">
        <slot name="edit-actions">
          <span v-if="editMode" @click="cancel" :key="'cancel'" class="text-uppercase">
            <font-awesome-icon class="inline-icon" icon="times"/>
            {{ $t('action_cancel') }}
          </span>
          <span v-else @click="edit" :key="'edit'" class="text-uppercase">
            <font-awesome-icon class="inline-icon" icon="edit"/>
            {{ $t('action_edit') }}
          </span>
        </slot>
      </div>
    </div>

    <ba-section-action @submit="submit" @cancel="cancel" v-if="editMode"></ba-section-action>
  </div>
</template>

<script>
  import BaSectionAction from '@/components/partials/General/BaSectionAction';

  export default {
    components: {
      BaSectionAction,
    },
    props: {
      caption: {
        type: String,
        required: true,
      },
      editable: {
        type: Boolean,
        default: false,
      },
      editMode: {
        type: Boolean,
        default: false,
      },
      isAccountSection: {
        type: Boolean,
        default: false,
      },
      isSaving: {
        type: Boolean,
        required: false,
        default: false,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      headlineClass: {
        type: String,
        default: 'profile-headline ph-center',
      },
    },
    methods: {
      edit: function () {
        this.$emit('edit');
      },
      cancel: function () {
        this.$emit('cancel');
      },
      submit: function () {
        this.$emit('submit');
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .profile-section-edit {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -25px;
    font-size: $font-size-base;
    transition: $transition-base;
    @include hover-focus {
      color: $link-hover-color;
    }
    @include media-breakpoint-up(md) {
      top: 0;
    }
  }
  .profile-headline {
    margin-bottom: 22px;
    text-transform: uppercase;
    font-size: $font-size-base;
    font-weight: $font-weight-bolder;
    line-height: 1.4375;//23px
    text-align: left;
    &.ph-show-edit {}
    @include media-breakpoint-up(lg) {
      font-weight: $ba-font-weight-medium;
    }
  }
  .profile-section {
    &.editable {
      padding-top: 10px;
      @include media-breakpoint-up(md) {
        padding-top: 0;
      }
    }
    .ph-show-edit {
      display: none;
    }
    &.is-edit-mode {
      .ph-show-edit {
        display: block;
      }
    }
  }
  .profile-view {
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
    @include media-breakpoint-up(lg) {
      display: flex;
      .profile-headline {
        flex: 0 0 25%;
        margin-right: 29px;
      }
    }
    @include media-breakpoint-up(xl) {
      .profile-headline {
        flex: 0 0 251px;
        margin-left: 30px;
        max-width: 251px;
      }
    }
  }
  .profile-section-content {
    flex: 1 1 auto;
    .link-add:last-child {
      margin-bottom: 30px;
    }
  }
  .account-section {
    @include media-breakpoint-down(lg) {

      .profile-view {
        display: flex;
        flex-wrap: wrap;
      }
      .profile-headline {
        flex: 0 0 100%;
        font-size: 1.25rem;
        line-height: 27px;
        letter-spacing: .81px;
      }

      .profile-section-edit {
        position: static;
      }
    }
    .profile-view {
      margin: 40px 0;
      @include media-breakpoint-up(xl) {
        .profile-headline {
          padding-left: 0;
        }
      }
    }
  }
  .ba-separator + .account-section {
    padding-top: 0;
  }
  .profile-section {
    &.disabled {
      .profile-view::after {
        display: block;
        content: '';
        background-color: white;
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0.7;
      }
    }
  }
</style>

<i18n lang="yaml" locale="de">
  action_edit: 'bearbeiten'
  action_cancel: 'abbrechen'
</i18n>
