<template>
  <div class="container module-header" :class="{'uppercase': uppercase}">
    <h1 class="title">
      <slot name="title"></slot>
    </h1>
    <h2 class="caption" v-if="hasTextSlot('caption')">
      <slot name="caption"></slot>
    </h2>
    <div class="lead" v-if="hasTextSlot('lead')">
      <slot name="lead"></slot>
    </div>
    <div class="text" v-if="hasTextSlot('text')">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      uppercase: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      hasTextSlot (name) {
        return !!this.$slots[name] || !!this.$scopedSlots[name];
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  @import "../../../style/content/_header";
</style>
