var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.assets && _vm.data.assets.length > 0
    ? _c(
        "div",
        { staticClass: "block-media" },
        _vm._l(_vm.data.assets, function(asset) {
          return _c(
            "div",
            {
              key: asset.id,
              staticClass: "asset",
              class: { "img-gradient": _vm.gradient },
              attrs: { id: "ca" + asset.id }
            },
            [
              _c("b-img", {
                attrs: { src: asset.url, alt: _vm.data.description, fluid: "" }
              })
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }