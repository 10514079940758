<template>
  <b-btn :variant="variant" @click="onClick">
    <font-awesome-icon :class="iconClass" :icon="icon"/>
    <span class="text"><slot></slot></span>
  </b-btn>
</template>
<script>
  export default {
    props: {
      icon: {
        type: String,
        required: true,
      },
      to: {
        type: Object,
        required: false,
      },
      iconClass: {
        type: String,
        required: false,
        default: 'icon',
      },
      variant: {
        type: String,
        required: false,
        default: 'action',
      },
    },
    methods: {
      onClick: function (e) {
        this.$emit('click', e);
        if (this.to) {
          this.$router.push(this.to);
        }
      },
    },
  };
</script>
