var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      class: [_vm.formGroupClass, { "has-error": _vm.state === false }],
      attrs: { label: _vm.label, "label-for": _vm.id }
    },
    [
      _vm.grouped
        ? _c(
            "multiselect",
            {
              attrs: {
                id: _vm.id,
                options: _vm.choices,
                multiple: _vm.multiple,
                "group-values": "groupChoices",
                "group-label": "name",
                "group-select": true,
                placeholder: this.placeholder
                  ? this.placeholder
                  : _vm.$t("form.select.placeholder.general"),
                "track-by": "id",
                label: _vm.choiceLabel,
                openDirection: "bottom",
                searchable: _vm.isSearchable
              },
              on: { input: _vm.onUpdateChoice },
              model: {
                value: _vm.internalValue,
                callback: function($$v) {
                  _vm.internalValue = $$v
                },
                expression: "internalValue"
              }
            },
            [
              _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                _vm._v(_vm._s(_vm.$t("form.select.no_results")))
              ])
            ]
          )
        : _c(
            "multiselect",
            {
              attrs: {
                id: _vm.id,
                options: _vm.choices,
                multiple: _vm.multiple,
                placeholder: this.placeholder
                  ? this.placeholder
                  : _vm.$t("form.select.placeholder.general"),
                "open-direction": "bottom",
                "track-by": "id",
                label: _vm.choiceLabel,
                searchable: _vm.isSearchable
              },
              on: { input: _vm.onUpdateChoice },
              model: {
                value: _vm.internalValue,
                callback: function($$v) {
                  _vm.internalValue = $$v
                },
                expression: "internalValue"
              }
            },
            [
              _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                _vm._v(_vm._s(_vm.$t("form.select.no_results")))
              ])
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }