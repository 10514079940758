var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container text-section-content",
      class: _vm.defaultFrameClasses,
      attrs: { id: "c" + _vm.data.id }
    },
    [_c("ba-content-common-content", { attrs: { data: _vm.data } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }