var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "profile-section",
      class: {
        "account-section": _vm.isAccountSection,
        editable: _vm.editable,
        "is-edit-mode": _vm.editMode,
        "ba-form-submitted": _vm.isSaving,
        disabled: _vm.disabled
      }
    },
    [
      _c("div", { staticClass: "profile-view" }, [
        _c("h3", {
          class: _vm.headlineClass,
          domProps: { innerHTML: _vm._s(_vm.caption) }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "profile-section-content" },
          [!_vm.editMode ? _vm._t("default") : _vm._t("edit")],
          2
        ),
        _vm._v(" "),
        _vm.editable
          ? _c(
              "div",
              { staticClass: "profile-section-edit" },
              [
                _vm._t("edit-actions", [
                  _vm.editMode
                    ? _c(
                        "span",
                        {
                          key: "cancel",
                          staticClass: "text-uppercase",
                          on: { click: _vm.cancel }
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "inline-icon",
                            attrs: { icon: "times" }
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("action_cancel")) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    : _c(
                        "span",
                        {
                          key: "edit",
                          staticClass: "text-uppercase",
                          on: { click: _vm.edit }
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "inline-icon",
                            attrs: { icon: "edit" }
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("action_edit")) +
                              "\n        "
                          )
                        ],
                        1
                      )
                ])
              ],
              2
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.editMode
        ? _c("ba-section-action", {
            on: { submit: _vm.submit, cancel: _vm.cancel }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }