var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-container offer-details-container" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", md: "5", lg: "7", xl: "6" } },
            [
              _c("h3", { staticClass: "headline" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.partials.company.course_offer_year_details.action.offer_details_headline"
                    )
                  )
                )
              ]),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "col-offer-name",
                      attrs: { cols: "12", lg: "6" }
                    },
                    [
                      _vm.yearDetails.applicationContactLastName
                        ? [
                            _c("div", { staticClass: "offer-name-box" }, [
                              _c(
                                "span",
                                { staticClass: "icon-circle bg-info" },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "icon",
                                    attrs: { icon: "user" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "item-meta" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.yearDetails.applicationContactTitle
                                    ) +
                                    "\n                " +
                                    _vm._s(
                                      _vm.yearDetails
                                        .applicationContactFirstName
                                    ) +
                                    " " +
                                    _vm._s(
                                      _vm.yearDetails.applicationContactLastName
                                    ) +
                                    "\n              "
                                )
                              ])
                            ])
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    {
                      staticClass: "col-offer-contact",
                      attrs: { cols: "12", lg: "6" }
                    },
                    [
                      _vm.contactPhone
                        ? _c(
                            "p",
                            { staticClass: "item-meta has-icon" },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "icon",
                                attrs: { icon: "phone-alt" }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "text" }, [
                                _vm._v(_vm._s(_vm.contactPhone))
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.contactEmail
                        ? _c(
                            "p",
                            { staticClass: "item-meta has-icon" },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "icon",
                                attrs: { icon: "envelope-open" }
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: { href: "mailto:" + _vm.contactEmail }
                                },
                                [_vm._v(_vm._s(_vm.contactEmail))]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.contactWww
                        ? _c(
                            "p",
                            { staticClass: "item-meta has-icon" },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "icon",
                                attrs: { icon: "link" }
                              }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.contactWww,
                                    target: "_blank"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.contactWww))]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.yearDetails.info
            ? _c(
                "b-col",
                { attrs: { cols: "12", md: "7", lg: "5", xl: "6" } },
                [
                  _c("h3", { staticClass: "headline" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "components.partials.company.course_offer_year_details.action.offer_details_add_info"
                        )
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "item-meta spacer-top" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.yearDetails.info) + "\n      "
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }