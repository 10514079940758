<template>
  <div class="radio-toggle">
    <div class="switch-wrapper">
      <label class="switch">
        <input v-bind="inputProperties" type="checkbox" v-model="modelValue" />
        <span class="slider round"></span>
      </label>
      <div class="switch-overlay" v-if="preventChange" @click="$emit('prevent-click')"></div>
    </div>
    <span class="switch-label">{{ computedLabel }}</span>
  </div>
</template>

<script>
  export default {
    props: {
      id: {
        type: String,
        required: false,
      },
      labels: {
        type: Object,
        required: false,
      },
      value: {
        type: Boolean,
        default: false,
        required: false,
      },
      preventChange: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    data: () => ({
      internalValue: false,
    }),
    computed: {
      modelValue: {
        set: function (value) {
          this.internalValue = value;
          this.$emit('input', value);
        },
        get: function () {
          return this.internalValue;
        },
      },
      computedLabel: function () {
        return Object.assign({
          true: this.$t('components.partials.form.ba_switch.yes'),
          false: this.$t('components.partials.form.ba_switch.no'),
        }, this.labels)[this.modelValue];
      },
      inputProperties: function () {
        const props = {
        };
        if (this.id) {
          props.id = this.id;
        }

        return props;
      },
    },
    watch: {
      value: function (value) {
        this.internalValue = value;
      },
    },
    mounted: function () {
      this.internalValue = this.value;
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .radio-toggle {
    display: flex;
    align-items: center;

    .slider {
      display: block;
      position: relative;
      cursor: pointer;
      height: 100%;
      width: 100%;
      background-color: $ba-default-background;
      border: 1px solid $gray-400;
      transition: all .4s ease-in-out;
      &:before {
        position: absolute;
        content: "";
        height: 21px;
        width: 21px;
        left: 1px;
        bottom: 1px;
        background-color: white;
        transition: all .4s ease-in-out;
      }
      &.round {
        border-radius: 12.5px;
        &:before {
          border-radius: 50%;
        }
      }
    }
    .switch {
      display: block;
      position: relative;
      width: 48.5px;
      height: 25px;
      margin-bottom: 0;

      input {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
        &:checked + .slider {
          background-color: $info;
          border-color: $info;
        }

        &:focus + .slider {
          box-shadow: 0 0 1px $primary;
        }

        &:checked + .slider:before {
          left: 25px;
        }
      }
    }
    .switch-label {
      margin-left: 4px;
    }
  }
  .switch-wrapper {
    position: relative;
    .switch-overlay {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
    }
  }
</style>

<i18n lang="yaml" locale="de">
  components.partials.form.ba_switch.yes: 'Ja'
  components.partials.form.ba_switch.no: 'Ja'
</i18n>
