var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "profile-meta",
      class: {
        "profile-meta-single": _vm.isSingleLine,
        "ba-separator one-color": _vm.addSeparator,
        "no-indent": _vm.noSeparatorIndent
      }
    },
    [
      _c(
        "h3",
        { staticClass: "profile-meta-headline" },
        [_vm._t("meta-title")],
        2
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }