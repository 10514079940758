
import BaSwitch from '@/components/partials/Form/BaSwitch';
import { getApplicantManagementData, saveApplicantManagement } from '@/api/applicant';

export default {
  components: {
    BaSwitch,
  },
  props: {
    ratedObjectId: {
      type: Number,
      required: true,
    },
    recordType: String,
    managementData: {
      type: [Object, Promise],
      required: false,
    },
  },
  data: () => ({
    rating: '',
    comment: '',
    isBookmarked: false,
    ratingLabel: '',
    isLoading: false,
    isSaving: false,
    wasSaved: false,
    internalManagementData: null,
    waitingToSaveAgain: false,
  }),
  mounted: function () {
    const self = this;
    if (self.managementData !== null && typeof self.managementData !== 'undefined') {
      self.isLoading = true;
      Promise.resolve(self.managementData).then(function (value) {
        self.setInternalData(value);
        self.isLoading = false;
      });
    } else {
      self.loadManagementData(self.ratedObjectId);
    }
  },
  computed: {
    isRated: function () {
      return this.rating && this.rating !== 'unrated';
    },
    hasData: function () {
      return this.isBookmarked || this.isRated || this.comment;
    },
    ratingIcon: function () {
      /* let iconGroup = this.showFullRating ? 'fas' : 'far';
      let iconName;
      switch (this.ratingType) {
        case 'positive':
          iconName = 'smile';
          break;
        case 'negative':
          iconName = 'frown';
          break;
        case 'neutral':
          iconName = 'meh';
          break;
        default:
          iconGroup = 'fas';
          iconName = 'user-cog';
          break;
      }
      return [iconGroup, iconName]; */
      return ['fas', 'user-cog'];
    },
  },
  methods: {
    saveRating: function (ratingType) {
      this.rating = ratingType;
      this.$emit('rating-updated', this.rating);
      this.saveManagementData();
    },
    saveManagementData: function () {
      const self = this;
      if (!self.isSaving) {
        self.isSaving = true;
        self.internalManagementData = {
          bookmarks: {
            default: self.isBookmarked,
          },
          rating: self.rating,
          comment: self.comment,
        };
        saveApplicantManagement(self.ratedObjectId, self.internalManagementData)
          .then(({ data: response }) => {
            if (!self.waitingToSaveAgain) {
              self.setInternalData(response);
              this.$emit('updated', this.internalManagementData);
            }
          })
          .finally(() => {
            self.isSaving = false;
            self.wasSaved = true;
            setTimeout(function () {
              self.wasSaved = false;
            }, 10000);
            if (self.waitingToSaveAgain) {
              self.waitingToSaveAgain = false;
              self.saveManagementData();
            }
          })
        ;
      } else {
        self.waitingToSaveAgain = true;
      }
    },
    loadManagementData: function (objectId) {
      if (objectId) {
        const self = this;
        self.isLoading = true;
        getApplicantManagementData(objectId)
          .then(({ data: value }) => {
            self.setInternalData(value);
          })
          .finally(() => {
            self.isLoading = false;
          })
        ;
      }
    },
    setInternalData: function (value) {
      this.internalManagementData = value;
      this.rating = value.rating;
      this.comment = value.comment;
      this.isBookmarked = value.bookmarks && value.bookmarks.default;
      // Always send update signal to parent, so additional rating info in parent component can be updated
      let label = '';
      switch (this.rating) {
        case 'positive':
          label = this.$t('rating.choices.good');
          break;
        case 'negative':
          label = this.$t('rating.choices.bad');
          break;
        case 'neutral':
          label = this.$t('rating.choices.meh');
      }
      this.ratingLabel = label;
      this.$emit('rating-loaded', this.rating, label);
      // this.$emit('data-loaded', this.internalManagementData);
    },
    onToggleBookmark: function () {
      this.saveManagementData();
    },
  },
};
