var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.listClass },
    _vm._l(_vm.attachments, function(attachment) {
      return _c(
        "a",
        {
          key: attachment.key,
          staticClass: "profile-attachment",
          attrs: { "data-key": attachment.key },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.$emit("download", attachment)
            }
          }
        },
        [
          _c("font-awesome-icon", {
            staticClass: "file-icon",
            attrs: { icon: ["far", _vm.attachmentTypeIcon(attachment)] }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "file-label" }, [
            _vm._v(_vm._s(attachment.name))
          ]),
          _vm._v(" "),
          _vm.appendDownloadIcon
            ? _c("font-awesome-icon", {
                staticClass: "icon-download",
                attrs: { icon: "download" }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }