var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", { staticClass: "text-container" }, [
    _c(
      "h1",
      { staticClass: "text-headline" },
      [_vm._t("headline", [_vm._v(_vm._s(_vm.caption))])],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "content-narrow" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }