<template>
  <hr class="ba-separator" :class="{'no-indent': noIndent, 'one-color': oneColor}">
</template>

<script>
  export default {
    props: {
      noIndent: {
        type: Boolean,
        required: false,
      },
      oneColor: {
        type: Boolean,
        required: false,
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";

  hr.ba-separator {
    display: block;
    padding: 0;
    border-width: 0;
    margin: 17px 0;
    width: 100%;
  }
  .ba-separator {
    position: relative;
    min-height: 3px;
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 3px;
      top: 0;
      left: 15px;
    }
    &::before {
      background: $ba-default-background;
      width: calc(100% - 30px);
    }
    &.bottom {
      &::before,
      &::after {
        top: auto;
        bottom: 0;
      }
    }
    &.one-color {
      &::after {
        display: none;
      }
    }
    &.no-indent {
      &::before,
      &::after {
        left: 0;
      }
      &::before {
        width: 100%;
      }
    }
  }
</style>
