var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "threadModal",
      attrs: { id: _vm.identifier, title: _vm.$t("message.modal.title") },
      on: { hidden: _vm.hide, ok: _vm.submit },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function(ref) {
            var cancel = ref.cancel
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "secondary", disabled: _vm.isSaving },
                  on: {
                    click: function($event) {
                      return cancel()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("message.modal.action.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { variant: "primary", disabled: _vm.isSaving },
                  on: { click: _vm.submit }
                },
                [_vm._v(_vm._s(_vm.$t("message.modal.action.submit")))]
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "form",
        {
          ref: "form",
          staticClass: "modal-form-message-create",
          class: {
            "has-file": _vm.newThreadHasAttachment,
            "is-saving": _vm.isSaving
          },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("b-form-textarea", {
                attrs: {
                  "no-resize": "",
                  placeholder: _vm.$t("message.text.input.placeholder")
                },
                model: {
                  value: _vm.message,
                  callback: function($$v) {
                    _vm.message = $$v
                  },
                  expression: "message"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _vm.newThreadHasAttachment
              ? _c("div", { staticClass: "thread-form attachment-container" }, [
                  _c("div", { staticClass: "attachment-file-wrapper" }, [
                    _c(
                      "div",
                      {
                        staticClass: "attachment-file",
                        class: {
                          "has-error": _vm.attachmentValidationState > 0
                        }
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "inline-icon",
                          attrs: { size: "lg", icon: ["far", "file"] }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "file-name" }, [
                          _vm._v(_vm._s(_vm.threadAttachmentChanges.name))
                        ]),
                        _vm._v(" "),
                        _c("font-awesome-icon", {
                          staticClass: "remove-attachment-icon float-right",
                          attrs: { size: "lg", icon: "times" },
                          on: { click: _vm.handleThreadAttachmentRemoval }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.attachmentValidationState === 1
                      ? _c("div", { staticClass: "attachment-file-error" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "components.partials.message.modal.invalid_attachment_size"
                              )
                            )
                          )
                        ])
                      : _vm.attachmentValidationState > 1
                      ? _c("div", { staticClass: "attachment-file-error" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "components.partials.message.modal.invalid_attachment_type"
                              )
                            )
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              ref: "threadUpload",
              staticClass: "inputfile",
              attrs: {
                type: "file",
                name: "threadUpload",
                id: "thread-file",
                accept: "text/plain, application/pdf, image/*"
              },
              on: {
                change: function($event) {
                  return _vm.handleThreadAttachmentUpload()
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "attachment-footer" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("components.partials.message.modal.attachment_footer")
                )
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }