<template>
  <div class="block-media" v-if="data.assets && data.assets.length > 0">
    <div :id="'ca' + asset.id" class="asset" :class="{'img-gradient': gradient}" v-for="asset in data.assets" :key="asset.id">
      <b-img :src="asset.url" :alt="data.description" fluid/>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        required: true,
      },
      enabledGradient: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    computed: {
      gradient: function () {
        return this.enabledGradient && this.data.header;
      },
    },
  };
</script>

<style lang="scss">
  .img-gradient {
    position: relative;
    display: inline-block;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    }

    & img {
      display: block;
    }
  }
</style>
