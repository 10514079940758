var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _vm.isLoadingContent
        ? _c("div", { staticClass: "loader-outer" }, [
            _c("div", { staticClass: "loader-dot-slide" })
          ])
        : _vm._l(_vm.groupedBlocks, function(blockGroup) {
            return _c(
              "div",
              {
                key: blockGroup.key,
                staticClass: "content-blocks",
                class: blockGroup.class
              },
              [
                _vm._l(blockGroup.blocks, function(block) {
                  return [
                    _c("ba-content-block-" + block.type.replace("_", "-"), {
                      tag: "component",
                      attrs: { data: block }
                    })
                  ]
                })
              ],
              2
            )
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }