<template>
  <div class="section-action-area">
    <b-link @click.prevent="$emit('cancel')" class="section-link">{{ $t('abbrechen') }}</b-link>
    <b-btn variant="primary" size="sm" @click.prevent="$emit('submit')" class="section-button">{{ $t('Änderungen speichern') }}</b-btn>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .section-action-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px 0;
    background-color: $gray-100;

    .section-link {
      text-decoration: underline;
      margin-bottom: 30px;
    }

    .section-button {
      display: inline-block;
    }
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-direction: row;
      .section-link {
        margin-bottom: 0;
        margin-right: 30px;
      }
    }
  }
</style>
