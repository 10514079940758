import { render, staticRenderFns } from "./BaCompanyCourseOfferYearDetails.vue?vue&type=template&id=f34c75ba&"
import script from "./BaCompanyCourseOfferYearDetails.vue?vue&type=script&lang=js&"
export * from "./BaCompanyCourseOfferYearDetails.vue?vue&type=script&lang=js&"
import style0 from "./BaCompanyCourseOfferYearDetails.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BaCompanyCourseOfferYearDetails.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml&locale=de"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/gerthammes/dev/campus-match/weincampus-campus-match-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f34c75ba')) {
      api.createRecord('f34c75ba', component.options)
    } else {
      api.reload('f34c75ba', component.options)
    }
    module.hot.accept("./BaCompanyCourseOfferYearDetails.vue?vue&type=template&id=f34c75ba&", function () {
      api.rerender('f34c75ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/partials/Company/BaCompanyCourseOfferYearDetails.vue"
export default component.exports