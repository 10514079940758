<template>
  <div :id="'c' + data.id" class="teaser" :class="frameClasses">
    <div class="container box-inner" v-if="layout !== 2">
      <ba-content-common-header :data="data"/>
      <ba-content-common-media :data="data"/>
      <div class="body">
        <ba-content-common-content :data="data"/>
        <ba-content-common-button :data="data"/>
      </div>
    </div>
    <div class="card card-rounded" v-else>
      <div class="card-image" v-if="data.assets && data.assets.length > 0">
        <ba-content-common-media :data="data" :enabled-gradient="false"/>
        <h4 class="card-title card-title-media-overlay" v-if="data.header">{{ data.header }}</h4>
      </div>
      <div class="card-body">
        <h4 class="card-title" v-if="data.header && !(data.assets && data.assets.length > 0)">{{ data.header }}</h4>
        <ba-content-common-content :data="data"/>
        <ba-content-common-button :data="data"/>
      </div>
    </div>
  </div>
</template>

<script>
  import BaContentCommonMedia from '@/components/partials/Content/Common/Media';
  import BaContentCommonButton from '@/components/partials/Content/Common/Button';
  import BaContentCommonMixin from '@/mixins/contentCommonMixin';

  export default {
    mixins: [BaContentCommonMixin],
    components: {
      BaContentCommonMedia,
      BaContentCommonButton,
    },
    computed: {
      frameClasses: function () {
        const classes = this.defaultFrameClasses;
        if (this.layout === 0) {
          classes.push('bg-light');
        } else if (this.layout === 2) {
          return ['col ba-teaser-card'];
        }
        return classes;
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  @import "../../../style/content/_teaser";
</style>
