var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    {
      staticClass: "mobile-no-col-space",
      class: { "search-form-above": _vm.showSearchAbove }
    },
    [
      _c(
        "b-col",
        { attrs: { cols: "12", xl: _vm.showSearchAbove ? "12" : "3" } },
        [
          _c(
            "div",
            {
              staticClass: "search-toggle-mobile",
              class: { "search-mobile-show": _vm.toggleSearch },
              on: {
                click: function($event) {
                  _vm.toggleSearch = !_vm.toggleSearch
                }
              }
            },
            [
              _c("span", { staticClass: "icon icon-angle down" }),
              _vm._v(" "),
              _vm.isBookmarkSearch
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("components.search.toggle_mobile_bookmarks")
                      )
                    )
                  ])
                : _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.search.toggle_mobile")))
                  ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "search-form-container mobile-only-space",
              class: { "search-mobile-show": _vm.toggleSearch }
            },
            [
              _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    _vm.isApplicantSearch
                      ? "ba-applicant-search-form"
                      : "ba-company-search-form",
                    {
                      key: _vm.search.id,
                      ref: "search-form",
                      tag: "component",
                      attrs: {
                        value: _vm.search,
                        "search-type": _vm.searchType,
                        "save-enabled": _vm.formCanBeSaved,
                        "search-id": _vm.searchId,
                        "is-saving": _vm.isSaving,
                        "save-form-errors": _vm.errors,
                        "has-errors": _vm.hasErrors,
                        "only-bookmarks": _vm.onlyBookmarks
                      },
                      on: {
                        "save-search": _vm.onSaveSearch,
                        search: _vm.onSearch,
                        searchClicked: _vm.scrollToResults,
                        reset: _vm.onFormReset
                      }
                    }
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "b-col",
            {
              ref: "results",
              attrs: { cols: "12", xl: _vm.showSearchAbove ? "12" : "9" }
            },
            [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "loader-outer" }, [
                  _c("div", { staticClass: "loader-dot-slide" })
                ])
              ])
            ]
          )
        : _c(
            "b-col",
            {
              ref: "results",
              attrs: { cols: "12", xl: _vm.showSearchAbove ? "12" : "9" }
            },
            [
              _c(
                "div",
                { staticClass: "container search-result-container" },
                [
                  _vm._t("search-content-top"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "search-results-top" },
                    [
                      _c("ba-top-pagination", {
                        attrs: {
                          results: _vm.totalResultCount,
                          "show-reset": _vm.showReset
                        },
                        on: { reset: _vm.onPaginationReset }
                      }),
                      _vm._v(" "),
                      _vm._t("list-actions-top"),
                      _vm._v(" "),
                      _vm._t("search-legend")
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "search-results" },
                    [
                      _vm._t("list-items", null, {
                        paginatedRecords: _vm.paginatedRecords
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("ba-bottom-pagination", {
                    attrs: { perPage: _vm.perPage, rows: _vm.totalResultCount },
                    on: { input: _vm.onPageChange },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  }),
                  _vm._v(" "),
                  _vm.formCanBeSaved || _vm.totalResultCount === 0
                    ? _vm._t("search-results-bottom", [
                        _vm.formCanBeSaved && _vm.totalResultCount > 0
                          ? _c(
                              "div",
                              { staticClass: "row-save hide-while-loading" },
                              [
                                _c("h3", { staticClass: "happy-save" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.search.result_info_headline"
                                      )
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("ba-save-named-button", {
                                  attrs: {
                                    variant: "outline-primary",
                                    "name-input-id": "search-name-bottom",
                                    "is-saving": _vm.isSaving,
                                    "has-errors": _vm.hasErrors,
                                    "input-state": _vm.saveFormInputState
                                  },
                                  on: { save: _vm.onUpdateSearchName },
                                  model: {
                                    value: _vm.search.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.search, "name", $$v)
                                    },
                                    expression: "search.name"
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "row-save hide-while-loading" },
                              [
                                _c("h3", { staticClass: "happy-save" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.isApplicantSearch
                                        ? _vm.$t(
                                            "components.search.no_result_info_headline_1_applicant"
                                          )
                                        : _vm.$t(
                                            "components.search.no_result_info_headline_1_company"
                                          )
                                    )
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.search.no_result_info_headline_2"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                      ])
                    : _vm._e()
                ],
                2
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }