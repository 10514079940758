<template>
  <div class="form-errors" v-if="hasErrors">
    <div class="alert alert-danger" v-if="customError">
      {{ customError }}
    </div>
    <div class="alert alert-danger" v-else-if="hasGeneralError">
      {{ $t('components.partials.common.profile.alert.general_error_on_save') }}
    </div>
    <div class="alert alert-danger" v-else>
      {{ $t('components.partials.common.profile.alert.property_error_on_save') }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      hasErrors: Boolean,
      hasGeneralError: Boolean,
      customError: {
        type: String,
        required: false,
        default: '',
      },
    },
  };
</script>

<i18n lang="yaml" locale="de">
  components.partials.common.profile.alert.property_error_on_save: 'Beim Speichern Ihrer Daten ist ein Fehler aufgetreten. Bitte prüfen Sie die eingegebenen Daten.'
  components.partials.common.profile.alert.general_error_on_save: 'Beim Speichern Ihrer Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
</i18n>
