
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['availableStudyInterests', 'availableStudyCourses']),
    recordAvailableStudyInterests: function () {
      return this.availableStudyInterests;
    },
    studyCourseListAsObject: function () {
      const studyCourseList = {};
      for (let i = 0, n = this.availableStudyCourses.length; i < n; i++) {
        const course = this.availableStudyCourses[i];
        studyCourseList[course.id] = course.name;
      }
      return studyCourseList;
    },
    studyInterestGroupedChoices: function () {
      const studyCourseList = {};
      for (let i = 0, n = this.availableStudyCourses.length; i < n; i++) {
        const course = this.availableStudyCourses[i];
        studyCourseList[course.id] = course.name;
      }
      const choiceGroups = [];
      const choiceOffsets = {};
      let groupOffset = 0;
      for (let i = 0, n = this.recordAvailableStudyInterests.length; i < n; i++) {
        const choice = this.recordAvailableStudyInterests[i];
        const groupId = choice.course_id;
        if (Object.prototype.hasOwnProperty.call(studyCourseList, groupId)) {
          if (undefined === choiceOffsets[groupId]) {
            choiceOffsets[groupId] = groupOffset;
            choiceGroups[choiceOffsets[groupId]] = {
              name: studyCourseList[groupId],
              groupChoices: [],
            };
            ++groupOffset;
          }
          choiceGroups[choiceOffsets[groupId]].groupChoices.push(choice);
        }
      }
      choiceGroups.sort(function (a, b) {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;

        return 0;
      });

      for (let i = 0, n = choiceGroups.length; i < n; i++) {
        choiceGroups[i].groupChoices.sort(function (a, b) {
          if (a.name > b.name) return 1;
          if (b.name > a.name) return -1;

          return 0;
        });
      }
      return choiceGroups;
    },
  },
  methods: {

    getDisplayStudyInterests (record) {
      const choices = [];
      const recordStudyInterests = record.studyInterests;
      if (recordStudyInterests.length > 0) {
        const studyCourseList = this.studyCourseListAsObject;
        for (let i = 0, n = recordStudyInterests.length; i < n; i++) {
          const choice = recordStudyInterests[i];
          let choiceLabel = choice.name;
          if (Object.prototype.hasOwnProperty.call(studyCourseList, choice.course_id) &&
            studyCourseList[choice.course_id] !== choiceLabel &&
            studyCourseList[choice.course_id].trim().length > 0
          ) {
            choiceLabel = studyCourseList[choice.course_id] + ' - ' + choiceLabel;
          }
          choices.push({ id: choice.id, name: choiceLabel });
        }
        choices.sort(function (a, b) {
          if (a.name > b.name) return 1;
          if (b.name > a.name) return -1;

          return 0;
        });
      }
      return choices;
    },
    hasStudyInterests: function (record) {
      if (typeof record.studyInterests === 'object') {
        if (typeof record.studyInterests.length === 'undefined') {
          return Object.keys(record.studyInterests).length;
        }
        return record.studyInterests.length > 0;
      }
      return record.studyInterests && record.studyInterests.length > 0;
    },
  },
};
