var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.bodytext
    ? _c("div", {
        staticClass: "block-content",
        domProps: { innerHTML: _vm._s(_vm.data.bodytext) }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }