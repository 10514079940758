<template>
  <div class="block-content" v-if="data.bodytext" v-html="data.bodytext"></div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
  };
</script>
