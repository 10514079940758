<template>
  <div :class="listClass">
    <a @click.prevent="$emit('download', attachment)" class="profile-attachment" :data-key="attachment.key" :key="attachment.key" v-for="attachment in attachments">
      <font-awesome-icon class="file-icon" :icon="['far', attachmentTypeIcon(attachment)]" />
      <span class="file-label">{{ attachment.name }}</span>
      <font-awesome-icon class="icon-download" icon="download" v-if="appendDownloadIcon" />
    </a>
  </div>
</template>

<script>
  export default {
    props: {
      listClass: {
        type: String,
        required: false,
        default: 'box-attachment-list',
      },
      attachments: {
        type: Array,
        required: true,
      },
      appendDownloadIcon: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    methods: {
      attachmentTypeIcon: function (attachment) {
        let icon = '';
        let type = attachment.type;
        if ((!type || type === 'unknown') && attachment.name) {
          let lastDotPos = attachment.name.lastIndexOf('.');
          if (lastDotPos > 0) {
            type = attachment.name.substring(lastDotPos + 1);
          }
        }
        switch (type) {
          case 'pdf':
            icon = 'pdf';
            break;
          case 'doc':
          case 'docx':
            icon = 'pdf';
            break;
          case 'zip':
          case 'tar':
            icon = 'archive';
            break;
        }
        if (icon) {
          return 'file-' + icon;
        }
        return 'file';
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .profile-attachment {
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: $transition-base;
    &:not(:last-child) {
      border-bottom: 2px solid $gray-300;
    }
    .file-icon {
      color: $ba-dusk;
      width: 30px;
      height: 30px;
      transition: color .2s ease-in-out;
    }
    .file-label {
      color: $ba-dusk;
      line-height: 1.5;
      margin-left: 25px;
      transition: color .2s ease-in-out;
      white-space: normal;
      text-overflow: ellipsis;
      overflow: hidden;
      flex: 1 1 auto;
      @include media-breakpoint-up(sm) {
        max-width: 300px;
      }
    }
    @include hover-focus {
      .file-icon {
        color: $ba-file-link-hover-color;
      }
      .file-label {
        color: $ba-file-link-hover-color;
      }
    }
    @include media-breakpoint-up(xl) {
      flex-direction: column;
      padding: 0 26px;
      border-width: 0;
      .file-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 17px;
      }
      .file-label {
        font-weight: $ba-font-weight-medium;
        text-transform: uppercase;
        margin-left: 0;
        text-align: center;
      }
    }
  }
  .box-message-attachment-list {
    display: flex;
    flex-wrap: wrap;
    .profile-attachment {
      padding: 0;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      display: flex;
      margin: 0 15px 0 0;
      .file-icon {
        color: $ba-dusk;
        width: 50px;
        height: 50px;
        margin: 0;
      }
      .file-label {
        flex: 1 1 auto;
        text-align: left;
        color: $ba-dusk;
        transition: color .2s linear;
        margin-left: 15px;
      }
      .icon-download {
        opacity: .5;
        color: $ba-dusk;
        width: 25px;
        height: 25px;
        margin-left: 15px;
        transition: opacity .2s linear;
      }
      @include hover-focus {
        .file-icon {
          color: $link-hover-color;
        }
        .file-label {
          color: $link-hover-color;
        }
        .icon-download {
          opacity: 1;
        }
      }
    }
    &.inversed {
      .profile-attachment {
        .file-icon {
          color: #fff;
        }
        .file-label {
          color: #fff;
        }
        .icon-download {
          color: #fff;
        }
        @include hover-focus {
          .file-icon {
            color: $link-hover-color;
          }
          .file-label {
            color: $link-hover-color;
          }
        }
      }
    }
  }
  .box-attachment-list {
    display: flex;
    flex-direction: column;
    .profile-attachment {
      padding: 19px 0 23px;
    }
    @include media-breakpoint-up(xl) {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 65px -20px -10px;
      .profile-attachment {
        padding: 0 10px;
        margin-bottom: 20px;
        border-width: 0;
        width: 25%;
        hyphens: auto;
        word-break: break-word;
      }
    }
  }
  .profile-form-attachment-group {
    @include media-breakpoint-up(xl) {
      .profile-attachment {
        flex-direction: row;
        padding: 0;
        .file-icon {
          margin-bottom: 4px;
        }
      }
    }
  }
  .attachment-container {
    color: $ba-box-header-color;
    cursor: pointer;
    span {
      margin: 1rem;
      font-size: 16px;
      font-weight: $ba-font-weight-medium;
      line-height: 1.44;
      text-align: center;
    }
  }
</style>
