<template>
  <b-form-group :label="label" :label-for="id" :class="[formGroupClass, {'has-error': state === false}]">
    <multiselect
      :id="id"
      v-model="internalValue"
      :options="choices"
      :multiple="multiple"
      group-values="groupChoices"
      group-label="name"
      :group-select="true"
      :placeholder="this.placeholder ? this.placeholder : $t('form.select.placeholder.general')"
      track-by="id"
      :label="choiceLabel"
      openDirection="bottom"
      @input="onUpdateChoice"
      :searchable="isSearchable"
      v-if="grouped"
    >
      <span slot="noResult">{{ $t('form.select.no_results') }}</span>
    </multiselect>
    <multiselect
      :id="id"
      v-model="internalValue"
      :options="choices"
      :multiple="multiple"
      :placeholder="this.placeholder ? this.placeholder : $t('form.select.placeholder.general')"
      open-direction="bottom"
      track-by="id"
      :label="choiceLabel"
      @input="onUpdateChoice"
      :searchable="isSearchable"
      v-else
    >
      <span slot="noResult">{{ $t('form.select.no_results') }}</span>
    </multiselect>
  </b-form-group>
</template>

<script>
  import BaMultiSelect from '@/mixins/multiSelect.js';

  export default {
    mixins: [BaMultiSelect],
    props: {
      choices: {
        type: [Object, Array],
        required: true,
      },
      placeholder: {
        type: String,
        require: false,
      },
      state: {
        type: Boolean,
        require: false,
        default: null,
      },
    },
  };
</script>

<i18n src="@/mixins/multiSelect.i18n.yaml" lang="yaml"/>
