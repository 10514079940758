import Vue from 'vue';

import 'leaflet/dist/leaflet.css';

import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
//import { Icon } from 'leaflet';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-popup', LPopup);

//delete Icon.Default.prototype._getIconUrl;

// const leafletIconBaseUrl = '/';

// Icon.Default.mergeOptions({
//   iconRetinaUrl: leafletIconBaseUrl + require('leaflet/dist/images/marker-icon-2x.png'),
//   iconUrl: leafletIconBaseUrl + require('leaflet/dist/images/marker-icon.png'),
//   shadowUrl: leafletIconBaseUrl + require('leaflet/dist/images/marker-shadow.png'),
// });
