export default {
  data: () => ({
    isSaving: false,
    hasGeneralError: false,
    hasErrors: false,
    formGroupPrefix: null,
    errors: {
      name: false,
    },
    customError: '',
  }),
  methods: {
    beforeSubmit: function () {
      const self = this;
      self.isSaving = true;
      self.hasGeneralError = false;
      self.hasErrors = false;
      self.errors = {};
    },
    afterSubmitHasErrorState: function (error) {
      const self = this;
      if (error.status && error.status === 'VALIDATION_ERROR') {
        for (let ve = 0; ve < error.data.length; ve++) {
          const errorDetails = error.data[ve];
          self.setPropertyError(errorDetails.propertyPath, errorDetails.constraint);
        }
      }
      self.hasErrors = true;
      self.isSaving = false;
    },
    afterSubmitFinally: function () {
      const self = this;
      self.isSaving = false;
      setTimeout(function () {
        if (self.editMode && self.isSaving) {
          self.hasGeneralError = true;
          self.hasErrors = true;
          self.isSaving = false;
        }
      }, 2000);
    },
    /*
    submit: function () {
      const self = this;
      self.beforeSubmit();
      saveTheData(this.submitData)
        .then(({ data: response }) => {
          self.$emit('update', response);
          self.editMode = false;
        }).catch(error => {
          self.afterSubmitHasErrorState(error);
        })
        .finally(() => {
          self.afterSubmitFinally();
        });
    },
    */
    setPropertyError (propertyPath, constraint) {
      const pathParts = propertyPath.split('.');
      let propertyErrorPath = '';
      let prefix = '';
      const regex = /(\w+)(\[(\d+)\])?/gm;
      for (let i = 0, n = pathParts.length; i < n; i++) {
        let propertySubPath;
        const matches = regex.exec(pathParts[i]);
        // Convert array properties to flat string: myVar[1].bla.blubb => myVar_1_bla_blubb
        if (matches && matches[3] !== undefined) {
          propertySubPath = matches[1] + '_' + matches[3];
        } else {
          propertySubPath = pathParts[i];
        }
        propertyErrorPath = prefix + propertySubPath;
        prefix += propertySubPath + '_';
      }
      if (!this.errors[propertyErrorPath]) {
        let errorType = true;
        if (typeof constraint !== 'undefined') {
          if (constraint.indexOf('UniqueEntity') >= 0) {
            errorType = 'unique';
          } else {
            errorType = constraint;
          }
        }
        this.errors[propertyErrorPath] = errorType;
      }
    },
    getBlockInputState: function (fieldName, blockIndex) {
      return this.getInputState(fieldName + '_' + blockIndex);
    },
    getInputState: function (fieldName) {
      return this.isFieldValid(fieldName) ? null : false;
    },
    isFieldValid: function (fieldName) {
      if (this.isSaving) {
        return true;
      }
      const errorFieldName = (this.formGroupPrefix ? this.formGroupPrefix + '_' : '') + fieldName;
      return !this.errors[errorFieldName];
    },
  },
};
