var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container text-media",
      class: _vm.frameClasses,
      attrs: { id: "c" + _vm.data.id }
    },
    [
      _vm.data.header
        ? _c(
            "div",
            { staticClass: "section-headline d-lg-none" },
            [_c("ba-content-common-header", { attrs: { data: _vm.data } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ba-content-common-media", {
        attrs: { data: _vm.data, "enabled-gradient": true }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _vm.data.header
            ? _c(
                "div",
                { staticClass: "section-headline d-none d-lg-block" },
                [_c("ba-content-common-header", { attrs: { data: _vm.data } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("ba-content-common-content", { attrs: { data: _vm.data } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }