var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.header
    ? _c("div", { class: "csc-header-" + _vm.headerLayout }, [
        _vm.headerLayout === 1
          ? _c("h1", { staticClass: "headline" }, [
              _vm._v(_vm._s(_vm.data.header))
            ])
          : _vm.headerLayout === 3
          ? _c("h3", { staticClass: "headline" }, [
              _vm._v(_vm._s(_vm.data.header))
            ])
          : _vm.headerLayout === 4
          ? _c("h4", { staticClass: "headline" }, [
              _vm._v(_vm._s(_vm.data.header))
            ])
          : _vm.headerLayout === 5
          ? _c("h5", { staticClass: "headline" }, [
              _vm._v(_vm._s(_vm.data.header))
            ])
          : _vm.headerLayout === 6
          ? _c("h6", { staticClass: "headline" }, [
              _vm._v(_vm._s(_vm.data.header))
            ])
          : _c("h2", { staticClass: "headline" }, [
              _vm._v(_vm._s(_vm.data.header))
            ]),
        _vm._v(" "),
        _vm.data.subheader
          ? _c("div", { staticClass: "csc-subheader" }, [
              _vm.headerLayout === 1
                ? _c("h2", { staticClass: "subheader" }, [
                    _vm._v(_vm._s(_vm.data.subheader))
                  ])
                : _vm.headerLayout === 2
                ? _c("h3", { staticClass: "subheader" }, [
                    _vm._v(_vm._s(_vm.data.subheader))
                  ])
                : _vm.headerLayout === 3
                ? _c("h4", { staticClass: "subheader" }, [
                    _vm._v(_vm._s(_vm.data.subheader))
                  ])
                : _vm.headerLayout === 4
                ? _c("h5", { staticClass: "subheader" }, [
                    _vm._v(_vm._s(_vm.data.subheader))
                  ])
                : _vm.headerLayout === 5
                ? _c("h6", { staticClass: "subheader" }, [
                    _vm._v(_vm._s(_vm.data.subheader))
                  ])
                : _c("p", [_vm._v(_vm._s(_vm.settings.subheader))])
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }