<template>
  <div class="card-highlight">
    <font-awesome-icon class="highlight-icon" icon="info-circle"/>
    <a
      v-if="link"
      :href="link"
      target="_blank"
      class="highlight-link"
    ><!--{{ $t('components.partials.company.card_highlight.link') }}-->
    <span class="highlight-text">{{ text }}</span>
    </a>
  </div>
</template>

<script>
  export default {
    name: 'BaCardHighlight',
    props: {
      link: {
        required: false,
        type: String,
        default: '',
      },
      text: {
        required: true,
        type: String,
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .card-highlight {
    @if $ba-default-enable-rounded {
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;
    }
    font-size: 1.125rem;
    line-height: 1.444444;
    font-weight: $ba-font-weight-medium;
    color: #fff;
    background-color: $ba-main-color1;
    max-width: 90%;
    width: -moz-fit-content;
    width: fit-content;
    padding: 7px 15px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    .highlight-icon {
      margin-right: 8px;
    }
    a {
      color: #fff;
      text-decoration: none;
      @include hover-focus {
        text-decoration: underline;
      }
    }
  }
</style>
<i18n lang="yaml" locale="de">
  components.partials.company.card_highlight.link: 'Hier Klicken'
</i18n>
