<template>
  <b-container class="text-container">
    <h1 class="text-headline">
      <slot name="headline">{{ caption }}</slot>
    </h1>
    <div class="content-narrow">
      <slot></slot>
    </div>
  </b-container>
</template>

<script>
  export default {
    props: {
      caption: {
        type: String,
        required: false,
      },
    },
  };
</script>

<style lang="scss">
  @import "../style/_init-vars-functions-mixins";
  .text-container {
    padding-top: 80px;
    padding-bottom: 76px;
    .content-narrow {
      width: 100%;
      max-width: 550px;
      margin: 40px auto 0;
      text-align: center;
    }
    .icon-confirm {
      display: block;
      margin: 40px auto;
      width: 70px;
      height: 70px;
    }
    p:last-child {
      margin-bottom: 0;
    }
    .btn-primary {
      margin-top: 20px;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 160px;
      padding-bottom: 156px;
      .content-narrow {
        margin-top: 48px;
      }
    }
  }
</style>
