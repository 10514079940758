<template>
  <b-row class="mobile-no-col-space" :class="{'search-form-above': showSearchAbove}">
    <b-col cols="12" :xl="showSearchAbove ? '12' : '3'">
      <div class="search-toggle-mobile" :class="{'search-mobile-show': toggleSearch}" @click="toggleSearch = !toggleSearch">
        <span class="icon icon-angle down"></span>
        <span v-if="isBookmarkSearch">{{ $t('components.search.toggle_mobile_bookmarks') }}</span>
        <span v-else>{{ $t('components.search.toggle_mobile') }}</span>
      </div>
      <div class="search-form-container mobile-only-space" :class="{'search-mobile-show': toggleSearch}">
        <div class="container">
<!--        <h2 class="search-profile-header" v-if="search.name">{{ search.name }}</h2>-->
        <component
          :is="isApplicantSearch ? 'ba-applicant-search-form' : 'ba-company-search-form'"
          :value="search"
          :search-type="searchType"
          :save-enabled="formCanBeSaved"
          :search-id="searchId"
          :is-saving="isSaving"
          :key="search.id"
          :save-form-errors="errors"
          :has-errors="hasErrors"
          :only-bookmarks="onlyBookmarks"
          @save-search="onSaveSearch"
          @search="onSearch"
          @searchClicked="scrollToResults"
          @reset="onFormReset"
          ref="search-form"
        />
        </div>
      </div>
    </b-col>
    <b-col cols="12" :xl="showSearchAbove ? '12' : '9'" v-if="isLoading" ref="results">
      <div class="container">
        <div class="loader-outer">
          <div class="loader-dot-slide"></div>
        </div>
      </div>
    </b-col>
    <b-col cols="12" :xl="showSearchAbove ? '12' : '9'" v-else ref="results">
      <div class="container search-result-container">
        <slot name="search-content-top">
        </slot>
        <div class="search-results-top">
          <ba-top-pagination :results="totalResultCount" @reset="onPaginationReset" :show-reset="showReset"/>
          <slot name="list-actions-top">
          </slot>
          <slot name="search-legend">
          </slot>
        </div>
        <div class="search-results">
          <slot name="list-items" v-bind:paginatedRecords="paginatedRecords">
          </slot>
        </div>
        <ba-bottom-pagination
          :perPage="perPage"
          :rows="totalResultCount"
          v-model="currentPage"
          @input="onPageChange"
        />
        <slot name="search-results-bottom" v-if="formCanBeSaved || totalResultCount === 0">
          <div class="row-save hide-while-loading" v-if="formCanBeSaved && totalResultCount > 0">
            <h3 class="happy-save">{{ $t('components.search.result_info_headline') }}</h3>
            <ba-save-named-button
              variant="outline-primary"
              @save="onUpdateSearchName"
              name-input-id="search-name-bottom"
              v-model="search.name"
              :is-saving="isSaving"
              :has-errors="hasErrors"
              :input-state="saveFormInputState"
            />
          </div>
          <div class="row-save hide-while-loading" v-else>
            <h3 class="happy-save">{{ isApplicantSearch ? $t('components.search.no_result_info_headline_1_applicant') : $t('components.search.no_result_info_headline_1_company') }}<br>{{ $t('components.search.no_result_info_headline_2') }}</h3>
          </div>
        </slot>
      </div>
    </b-col>
  </b-row>
</template>

<script>
  import BaApplicantSearchForm from '@/components/partials/Search/Applicant/BaApplicantSearchForm';
  import BaCompanySearchForm from '@/components/partials/Search/Company/BaCompanySearchForm';
  import BaTopPagination from '@/components/partials/General/BaTopPagination';
  import BaBottomPagination from '@/components/partials/General/BaBottomPagination';
  import BaSaveNamedButton from '@/components/partials/General/BaSaveNamedButton';
  import BaFormValidationMixin from '@/mixins/formValidation';

  import { executeSearch, saveSearch } from '@/api/search';
  import { RECORD_TYPE } from '@/api/constants';

  export default {
    mixins: [BaFormValidationMixin],
    components: {
      BaApplicantSearchForm,
      BaCompanySearchForm,
      BaBottomPagination,
      BaSaveNamedButton,
      BaTopPagination,
    },
    data: () => ({
      search: {},
      records: [],
      rows: 100,
      perPage: 10,
      currentPage: 1,
      orderBy: null,
      orderDirection: 'ASC',
      totalResultCount: 0,
      toggleSearch: false,
      isPaginatedResult: false,
      customSearchName: '',
      isLoading: true,
      showReset: false,
      // disable search update in form (when loading saved search)
    }),
    props: {
      searchType: {
        type: String,
        required: true,
      },
      searchRoute: {
        type: String,
        required: true,
      },
      saveEnabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      onlyBookmarks: {
        type: Boolean,
        required: false,
        default: false,
      },
      showSearchAbove: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      searchId: {
        set: function (value) {
          this.search.id = value ? parseInt(value) : null;
          // this.$router.push({ name: this.searchRoute });
        },
        get: function () {
          return this.search.id;
        },
      },
      paginatedRecords: function () {
        if (this.isPaginatedResult) {
          return this.records;
        }
        const start = (this.currentPage - 1) * this.perPage;
        const end = start + this.perPage;
        return Array.isArray(this.records) ? this.records.slice(start, end) : this.records;
      },
      isApplicantSearch: function () {
        return this.searchType === RECORD_TYPE.APPLICANT || this.searchType === RECORD_TYPE.APPLICANT_BOOKMARK;
      },
      isSortedAsc: function () {
        return this.orderDirection !== 'DESC';
      },
      isApplicantBookmarkSearch: function () {
        return this.searchType === RECORD_TYPE.APPLICANT_BOOKMARK;
      },
      isBookmarkSearch: function () {
        return this.isApplicantBookmarkSearch || this.searchType === RECORD_TYPE.COMPANY_BOOKMARK;
      },
      saveFormInputState: function () {
        if (this.errors.name !== undefined) {
          return this.errors.name;
        }
        return null;
      },
      formCanBeSaved: function () {
        return this.saveEnabled && (!this.searchId && !this.$route.params.searchId);
      },
    },
    beforeMount: function () {
      if (undefined !== this.$route.params.searchId) {
        this.searchId = this.$route.params.searchId;
        this.$store.dispatch('unsetSearchStorageEntry', this.searchType);
      } else {
        const query = this.$route.query;
        if (query.enterpriseName) {
          this.search = { companyName: query.enterpriseName };
          this.onSearch(this.search);
          this.$forceUpdate();
        }
      }
    },
    watch: {
      '$route.params.searchId': {
        handler: function (newVal, oldVal) {
          if (oldVal && !newVal) {
            this.search = {};
            // this.$forceUpdate();
          }
        },
        // deep: true,
      },
    },
    methods: {
      onPaginationReset: function () {
        if (Object.prototype.hasOwnProperty.call(this.$refs, 'search-form')) {
          this.$refs['search-form'].onResetSearch();
        }
      },
      onFormReset: function (search) {
        const self = this;
        self.onSearch(search, false);
        if (this.searchId || this.$route.params.searchId) {
          this.$router.push({ name: this.searchRoute });
        }
        this.showReset = false;
      },
      onPageChange: function (value) {
        this.currentPage = parseInt(value);
        if (this.isPaginatedResult) {
          this.onSearch(this.search, false);
        }
      },
      setOrderBy (field, direction) {
        this.orderBy = field;
        this.orderDirection = direction;
        this.currentPage = 1;
        this.onSearch(this.search, false);
      },
      onSearch: function (search, restorePaginationAndSorting) {
        const self = this;
        this.search = search;
        const searchProfileType = self.isApplicantSearch ? RECORD_TYPE.SEARCH_FOR_APPLICANTS : RECORD_TYPE.SEARCH_FOR_COMPANIES;
        self.isLoading = true;
        if (typeof search.notificationInterval !== 'undefined' &&
          (!search.notificationInterval || !search.notificationInterval.id)) {
          delete search.notificationInterval;
        }
        if (self.onlyBookmarks) {
          search.onlyBookmarks = true;
        }
        if (restorePaginationAndSorting) {
          // self.perPage = search.itemsPerPage;
          self.currentPage = search.pageNumber;
          self.orderBy = search.orderBy;
          self.orderDirection = search.orderDirection;
        }
        search.itemsPerPage = self.perPage;
        search.pageNumber = self.currentPage;
        search.orderBy = self.orderBy;
        search.orderDirection = self.orderDirection;
        // Save search in storage
        if (this.$refs['search-form']) {
          this.$refs['search-form'].storeSearch();
        }
        this.$nextTick(() => {
          this.showReset = this.$refs['search-form'].isActiveSearch;
        });
        self.$emit('search', self.search);
        executeSearch(searchProfileType, search)
          .then(({ data: response }) => {
            if (typeof response.pageResults !== 'undefined') {
              self.records = response.pageResults || [];
              self.totalResultCount = response.resultCount;
              self.currentPage = response.resultPage;
              self.isPaginatedResult = true;
            } else {
              self.records = response || [];
              self.totalResultCount = self.records.length;
              self.isPaginatedResult = false;
            }
            self.$emit('result-count-updated', self.totalResultCount);
            self.$nextTick(() => {
              self.$emit('search-executed', search);
            });
          })
          .finally(() => {
            self.isLoading = false;
          })
        ;
      },
      scrollToResults () {
        this.$nextTick(() => {
          const resultsElement = this.$refs.results;
          if (resultsElement) {
            resultsElement.scrollIntoView();
          }
        });
      },
      storeScroll (recordId) {
        this.$store.dispatch('setSearchScrollToEntry', {
          key: this.searchType,
          recordId: recordId,
        });
      },
      onUpdateSearchName (name) {
        // this.search.name = name;
        this.onSaveSearch(this.search);
      },
      onSaveSearch (search) {
        const self = this;
        self.beforeSubmit();
        self.search = search;
        if (typeof search.notificationInterval !== 'undefined' &&
          (!search.notificationInterval || !search.notificationInterval.id)) {
          delete search.notificationInterval;
        }
        saveSearch(this.searchType, this.search)
          .then(({ data: searchData }) => {
            // self.search = searchData;
            this.search = {};
          })
          .catch(error => {
            self.afterSubmitHasErrorState(error);
          })
          .finally(() => {
            self.afterSubmitFinally();
          })
        ;
      },
    },
  };
</script>

<style lang="scss">
  @import "../style/_init-vars-functions-mixins";
  @import "../style/content/form/form-container";
  @include media-breakpoint-down(xs) {
    .mobile-no-col-space > div {
      padding-left: 0;
      padding-right: 0;
    }
    .mobile-only-space {
      padding-left: $grid-gutter-width/2;
      padding-right: $grid-gutter-width/2;
    }
  }
  .row-save {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
    .happy-save {
      font-size: 22px;
      font-weight: $font-weight-light;
      line-height: 1.41;
      letter-spacing: .02px;
      text-align: center;
      + .control-button-group {
        margin: 0 auto;
      }
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }
  }
  .search-toggle-mobile {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    line-height: 26px;
    margin-bottom: 20px;
    letter-spacing: 0.02px;
    background: #fff;
    border-top: 2px solid $gray-400;
    border-bottom: 2px solid $gray-400;
    position: relative;
    padding: 18px 24px 14px;
    height: 59px;
    white-space: nowrap;
    transition: all .2s linear;
    .icon {
      display: block;
      margin-right: 11px;
    }
    &.search-mobile-show {
      color: #fff;
      justify-content: left;
      border-color: $ba-light-primary;
      background: $ba-light-primary;
      text-transform: uppercase;
      font-weight: $ba-font-weight-medium;
      .icon {
        display: none;
      }
      &::before,
      &::after {
        content: "";
        background-color: #fff;
        display: block;
        position: absolute;
        top: 10px;
        height: 36px;
        width: 3px;
        right: 36px;
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }
  .search-form-above {
    &.row {
      margin-left: 0;
      margin-right: 0;
      > div {
        padding-left: 0;
        padding-right: 0;
      }
    }
    @include media-breakpoint-up(xl) {
      .ba-form-search {
        display: flex;
        flex-wrap: wrap;
        > .form-group {
          flex: 0 0 33.3333%;
          max-width: 33.3333%;
          padding-right: 15px;
        }
        .ba-form-group-ratings {
          order: 0;
        }
        .ba-form-group-applicant-name,
        .ba-form-group-company-name {
          order: 1;
        }
        .ba-form-group-study-interests {
          order: 2;
        }
        .ba-form-group-desired-study-years {
          order: 3;
        }
        .ba-form-group-applicant-grade {
          order: 4;
        }
        .ba-form-group-place {
          order: 5;
        }
        .ba-form-group-place-radius {
          order: 6;
        }
        .ba-form-group-subject-areas {
          order: 7;
        }
        .ba-form-group-qualifications {
          order: 8;
        }
        .ba-form-group-required-documents {
          order: 10;
        }
        .ba-form-group-include-occupied {
          order: 12;
        }
        .ba-form-group-exclude-bookmarks {
          order: 20;
        }
        .ba-form-group-company-type {
          order: 11;
        }
        .ba-form-group-region {
          order: 17;
        }
        .ba-form-group-enterprise-size {
          order: 18;
        }
        .ba-form-group-focus {
          order: 19;
        }
        &.has-rating {
          .ba-form-group-applicant-grade {
            order: 9;
          }
        }
      }
      .btn-reset {
        display: inline-block;
        position: static;
        font-weight: $ba-font-weight-bold;
        text-transform: uppercase;
        text-align: right;
      }
      .row-form-actions {
        .btn-reset {
          display: block;
          position: absolute;
          bottom: -50px;
          right: 0;
          margin: 0;
        }
      }
    }
  }
  .company-search {
    @include media-breakpoint-up(xl) {
      .search-form-above {
        .ba-form-search {
          .ba-form-group-ratings {
            order: 0;
          }
          .ba-form-group-applicant-name,
          .ba-form-group-company-name {
            order: 1;
          }
          .ba-form-group-place {
            order: 2;
          }
          .ba-form-group-place-radius {
            order: 3;
          }
          .ba-form-group-include-occupied {
            order: 6;
          }
          .ba-form-group-desired-study-years {
            order: 7;
          }
          .ba-form-group-subject-areas {
            order: 15;
          }
          .ba-form-group-study-interests {
            order: 16;
          }
          .ba-form-group-qualifications {
            order: 17;
          }
          .ba-form-group-required-documents {
            order: 18;
          }
          .ba-form-group-company-type {
            order: 8;
          }
          .ba-form-group-region {
            order: 9;
          }
          .ba-form-group-enterprise-size {
            order: 10;
          }
          .ba-form-group-focus {
            order: 11;
          }
          &.has-rating {
            .ba-form-group-applicant-grade {
              order: 9;
            }
          }
        }
      }
    }
  }
  .search-results-top {
    margin-bottom: 50px;
    .page-result-count {
      flex: 1 1 auto;
      order: 0;
      margin-bottom: 30px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .page-result-count {
        margin-bottom: 0;
      }
    }
  }
  .record-list-actions {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    .list-action {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-right: 30px;
      }
    }
    .icon {
      display: block;
      margin-right: 10px;
    }
    .text {
      line-height: 1;
      text-transform: uppercase;
    }

    @include media-breakpoint-up(md) {
      justify-content: flex-end;
      margin-bottom: 0;
    }
  }
</style>

<i18n lang="yaml" locale="de">
  components.search.toggle_mobile_bookmarks: 'Anzeige eingrenzen'
  components.search.toggle_mobile: 'Suche anpassen'
  components.search.result_info_headline: 'Zufrieden mit dem Ergebnis?'
  components.search.no_result_info_headline_1_company: 'Leider konnten für Ihre Suche keine Unternehmen gefunden werden.'
  components.search.no_result_info_headline_1_applicant: 'Leider konnten für Ihre Suche keine Bewerber*innen gefunden werden.'
  components.search.no_result_info_headline_2: 'Bitte passen Sie Ihre Suche an.'
  components.search.error_feedback.unique_search: 'Sie haben bereits einen Suchauftrag mit den gleichen Filtereinstellungen erstellt. Bitte ändern Sie die Filtereinstellungen.'
</i18n>
