var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container module-header",
      class: { uppercase: _vm.uppercase }
    },
    [
      _c("h1", { staticClass: "title" }, [_vm._t("title")], 2),
      _vm._v(" "),
      _vm.hasTextSlot("caption")
        ? _c("h2", { staticClass: "caption" }, [_vm._t("caption")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm.hasTextSlot("lead")
        ? _c("div", { staticClass: "lead" }, [_vm._t("lead")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm.hasTextSlot("text")
        ? _c("div", { staticClass: "text" }, [_vm._t("text")], 2)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }