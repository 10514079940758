<template>
  <b-dropdown
    class="rating-dropdown record-management-dropdown"
    :class="{'is-saving': isSaving, 'was-saved': wasSaved, 'has-data': hasData}"
    menu-class="rating-menu"
    @hide="(event) => { preventCloseDropdown && event.preventDefault(); }"
    variant="action"
    no-caret
    ref="ratingDropdown"
  >
    <template v-slot:button-content>
      <span class="icon-wrap ba-icon-overlay">
        <font-awesome-icon class="action-icon" :icon="ratingIcon"/>
        <span class="has-data-marker" v-if="hasData">
          <font-awesome-icon class="overlay-icon" :icon="['fas', 'check']"/>
        </span>
      </span>
      <span class="text text-rating">{{ $t('rating.text') }}</span>
    </template>
    <li class="management-save-info">{{ $t('rating.message.saved') }}</li>
    <li class="management-close" @click="closeDropdown">
      <font-awesome-icon class="icon" icon="times"/>
      <span class="text">{{ $t('rating.cancel') }}</span>
    </li>
    <b-dropdown-form class="dropdown-rating-form">
      <b-form-group class="management-form-row" :label="$t('rating.bookmark.label')" :label-for="'rating-bookmark-toggle-' + ratedObjectId" label-class="management-form-label">
        <ba-switch
          :id="'rating-bookmark-toggle-' + ratedObjectId"
          v-model="isBookmarked"
          @input="saveManagementData"
          :prevent-change="isBookmarked"
          @prevent-click="showConfirmModal = true"
          :labels="{true: $t('rating.bookmark.yes'), false: $t('rating.bookmark.no')}"
        />
      </b-form-group>
      <div role="group" class="form-group management-form-row">
        <span class="management-form-label">{{ $t('rating.text.rate') }}</span>
        <ul class="dropdown-group-rating">
          <li role="presentation" :class="{'active': rating==='positive'}">
            <a role="menuitem" class="dropdown-item" @click="saveRating( 'positive')" :title="$t('rating.choices.good')">
              <font-awesome-icon class="icon" :icon="['far', 'smile']"/>
              <span class="sr-only">{{$t('rating.choices.good')}}</span>
            </a>
          </li>
          <li role="presentation" :class="{'active': rating==='neutral'}">
            <a role="menuitem" class="dropdown-item" @click="saveRating('neutral')">
              <font-awesome-icon class="icon" :icon="['far', 'meh']" :title="$t('rating.choices.meh')"/>
              <span class="sr-only">{{$t('rating.choices.meh')}}</span>
            </a>
          </li>
          <li role="presentation" :class="{'active': rating==='negative'}">
            <a role="menuitem" class="dropdown-item" @click="saveRating( 'negative')">
              <font-awesome-icon class="icon" :icon="['far', 'frown']" :title="$t('rating.choices.bad')"/>
              <span class="sr-only">{{$t('rating.choices.bad')}}</span>
            </a>
          </li>
          <li class="management-rating-label" v-if="ratingLabel">{{ ratingLabel }}</li>
          <li class="management-rating-label placeholder" v-else>{{ $t('rating.choices.placeholder') }}</li>
        </ul>
      </div>
      <b-form-group class="management-form-row" :label="$t('rating.comment.input.label')" :label-for="'rating-comment-' + ratedObjectId" label-class="management-form-label" @submit.stop.prevent>
        <b-form-textarea
          no-resize
          v-model="comment"
          @input="saveManagementData"
          :id="'rating-comment-'+ ratedObjectId"
          :placeholder="$t('rating.comment.input.placeholder')"
        ></b-form-textarea>
      </b-form-group>
      <!--
      <div class="form-actions">
        <div class="icon-wrap management-form-save-action" @click="saveManagementData()">
          <font-awesome-icon class="form-icon" :icon="['far', 'save']"/>
          <span class="icon-text">{{ $t('rating.actions.save') }}</span>
        </div>
      </div>
      -->
    </b-dropdown-form>

    <b-modal v-model="showConfirmModal" @hidden="preventCloseDropdown = false" @show="preventCloseDropdown = true">
      <template slot="modal-title">
        <span>{{ $t('rating.bookmark.modal.title') }}</span>
      </template>
      <div class="modal-body-remove-bookmark">
        <h4 class="subtitle">{{ $t('rating.bookmark.modal.sub_title') }}</h4>
        <p v-html="$t('rating.bookmark.modal.text')"></p>
      </div>
      <template v-slot:modal-footer>
        <div class="modal-footer-remove-bookmark">
          <b-button variant="primary" @click="changeBookmarked">{{ $t('rating.bookmark.modal.confirm') }}</b-button>
          <b-link @click="showConfirmModal = false" class="link-modal-close">{{ $t('rating.bookmark.modal.cancel') }}</b-link>
        </div>
      </template>
    </b-modal>
  </b-dropdown>
</template>

<script>
  import BaRecordRating from '@/mixins/recordRating';

  export default {
    mixins: [BaRecordRating],
    data: () => ({
      showConfirmModal: false,
      preventCloseDropdown: false,
    }),
    methods: {
      closeDropdown: function () {
        if (this.$refs.ratingDropdown) {
          this.$refs.ratingDropdown.hide(true);
        }
      },
      changeBookmarked: function () {
        this.isBookmarked = !this.isBookmarked;
        this.saveManagementData();
        this.showConfirmModal = false;
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";

  .modal-footer-remove-bookmark {
    .link-modal-close {
      margin-left: 10px;
    }
  }
  .col-rating {
    position: static;
    @include media-breakpoint-up(md) {
      position: relative;
    }
  }
  .record-management-dropdown {
    .management-form-label {
      text-align: center;
      text-transform: uppercase;
      font-weight: $ba-font-weight-medium;
      margin-bottom: 10px;

      @include media-breakpoint-up(lg) {
        flex: 0 0 111px;
        padding: 0;
        margin: 0;
        text-align: left;
      }
    }

    .management-save-info {
      background: $ba-default-background;
      display: block;
      color: $ba-stormy-blue;
      font-size: .75rem;
      line-height: 1.5;
      padding: 16px 15px;
      text-align: center;
      opacity: 0;
      transition: opacity .2s ease-in-out;

      @include media-breakpoint-up(lg) {
        padding: 2px 0;
      }
    }
    &.was-saved {
      .management-save-info {
        opacity: 1;
        margin-bottom: 30px;
      }
    }
    .dropdown-rating-form {
      .b-dropdown-form {
        padding-bottom: 20px;
        &:focus {
          outline: none !important;
        }
      }
      .form-control {
        min-height: 100px;
      }
      .management-form-save-action {
        cursor: pointer;
        color: $ba-dark-grey-blue;
        margin-left: auto;
        width: 70px;
        text-align: center;
        transition: color .2s ease-in-out;
        .form-icon {
          width: 16px;
          height: 16px;
        }
        .icon-text {
          display: block;
          text-transform: uppercase;
          text-align: center;
          font-size: .75rem;
          font-weight: $ba-font-weight-medium;
        }
        @include hover-focus-active {
          color: $link-hover-color;
        }
      }
    }

    .management-rating-label {
      display: block;
      text-align: center;
      flex: 0 0 100%;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: 23px;
      margin-top: 11px;

      &.placeholder {
        color: $ba-grey;
        text-transform: none;
      }

      @include media-breakpoint-up(lg) {
        text-align: left;
        flex: 0 0 auto;
        margin-top: 0;
        padding-left: 5px;
      }
    }

    .management-close {
      color: $ba-stormy-blue;
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      text-transform: uppercase;
      position: absolute;
      right: 14px;
      .icon {
        width: 34px;
        height: 34px;
      }
      .text {
        display: none;
      }

      @include media-breakpoint-up(lg) {
        .icon {
          margin-right: 6px;
          width: 17px;
          height: 17px;
        }
        .text {
          display: block;
        }
      }
    }
    .management-form-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:not(:last-child) {
        margin-bottom: 33px;
      }
      > div {
        flex: 0 0 100%;
        width: 100%;
        display: flex;
        justify-content: center;
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        > div {
          flex: 1 1 auto;
          width: auto;
          display: block;
        }
      }
    }
    &.is-saving {
      .dropdown-rating-form {
        cursor: progress;
        .management-form-save-action {
          cursor: progress;
          color: $gray-400;
        }
      }
    }
  }
  .dropdown-group-rating {
    list-style: none;
    flex: 0 0 100%;
    width: 100%;
    max-width: 260px;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    > li {
      display: block;
      flex: 0 0 33.33%;
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
    }
    .icon {
      color: $ba-very-light-pink;
      width: 45px;
      height: 45px;
      transition: color .2s ease-in-out;
    }
    .dropdown-item {
      cursor: pointer;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      @include hover-focus {
        background: transparent;
        .icon {
          color: $ba-dark-grey-blue;
        }
      }
    }
    .active {
      .icon {
        color: $ba-bg-primary-lighter;
      }
      .dropdown-item {
        background-color: transparent;
        @include hover-focus {
          .icon {
            color: $ba-dark-grey-blue;
          }
        }
      }
    }
    @include media-breakpoint-up(lg) {
      max-width: none;
      align-items: center;
      > li {
        flex: 0 0 auto;
        margin-right: 10px;
      }
      .icon {
        width: 25px;
        height: 25px;
      }
    }
  }
  .ba-icon-overlay {
    position: relative;
    .has-data-marker {
      position: absolute;
      top: 11px;
      left: 15px;
      width: 17px;
      height: 17px;
      background: $ba-main-color6;
      border-radius: 50%;
      padding-top: 2px;
      padding-left: 1px;
      line-height: 12px;
    }
    .overlay-icon {
      color: #fff;
      width: 10px;
      height: 10px;
    }
  }
  .rating-dropdown {
    position: static;
    .dropdown-header {
      display: none;
      color: $dropdown-color;
      font-size: $font-size-base;
      font-weight: $ba-font-weight-medium;
      padding-bottom: 0;
      text-transform: uppercase;
    }
    &.show {
      .dropdown-toggle {
        position: relative;
        z-index: 1001;
        &::before {
          content: "";
          display: block;
          position: absolute;
          left: calc(50% - 24px);
          //width: 43px;
          //height: 14px;
          z-index: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 21.5px 14px 21.5px;
          border-color: transparent transparent $ba-stormy-blue transparent;
          bottom: -14px;
          transform: rotate(180deg);
          transition: border-bottom-color .2s ease-in-out;
        }

        @include hover-focus-active {
          &:before {
            border-bottom-color: $ba-dark-grey-blue;
          }
        }

        @include media-breakpoint-up(lg) {
          &::before {
            border-bottom-color: #fff;
            bottom: -2px;
            transform: rotate(0);
          }
          @include hover-focus-active {
            &::before {
              border-bottom-color: #fff;
            }
          }
        }
      }
      .rating-menu {
        float: none;
        width: 100%;
        left: 0;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #ffffff;
        margin-left: 0;
        margin-right: 0;
        margin-top: -1px;
        overflow: visible;
        // Disable transform of dropdown plugin
        transform: none !important;
        top: auto !important;
      }
      .dropdown-header {
        display: block;
      }
      @include media-breakpoint-up(sm) {
        .rating-menu {
          max-width: 540px;
          margin-left: 0;
        }
      }
      @include media-breakpoint-up(md) {
        position: relative;
        .rating-menu {
          width: 540px;
          max-width: none;
          margin-left: -150px;
        }
      }
      @include media-breakpoint-up(lg) {
        .rating-menu {
          margin-left: -410px;
        }
      }
    }
    &.was-saved {
      @include media-breakpoint-up(lg) {
        .dropdown-toggle {
          &::before {
            border-bottom-color: $gray-100;
          }
          @include hover-focus-active {
            &::before {
              border-bottom-color: $gray-100;
            }
          }
        }
      }
    }
    .action-icon {
      color: #fff;
    }
    @include media-breakpoint-up(xl) {
      &.show {
        .rating-menu {
          width: 630px;
          margin-left: -500px;
        }
        .form-control {
          min-height: 160px;
        }
      }
    }
    &.is-saving {
      cursor: progress;
      .dropdown-group-rating {
        .active .dropdown-item {
          cursor: progress;
          .icon {
            color: $ba-dark-grey-blue;
          }
        }
      }
    }
  }
  .message-rating-choice {
    &.show {
      @include media-breakpoint-down(lg) {
        .dropdown-toggle {
          &::before {
            border-bottom-color: $ba-dark-grey-blue;
          }
          @include hover-focus-active {
            &::before {
              border-bottom-color: $ba-dark-grey-blue;
            }
          }
        }
      }
      @include media-breakpoint-up(sm) {
        .rating-menu {
          margin-left: 0;
        }
      }
      @include media-breakpoint-up(md) {
        .rating-menu {
          margin-left: 0;
        }
      }
      @include media-breakpoint-up(lg) {
        .rating-menu {
          margin-left: -300px;
        }
      }
      @include media-breakpoint-up(xl) {
        .rating-menu {
          margin-left: -300px;
        }
      }
    }
  }
</style>

<i18n src="@/mixins/recordRating.i18n.yaml" lang="yaml"/>
