import Multiselect from 'vue-multiselect';
import isMobileMixin from '@/mixins/isMobile';

export default {
  mixins: [isMobileMixin],
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: [Object, String, Array],
      required: false,
    },
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    choiceLabel: {
      type: String,
      required: false,
      default: 'name',
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    grouped: {
      type: Boolean,
      required: false,
      default: false,
    },
    formGroupClass: {
      type: String,
      required: false,
      default: 'form-group-multi-select',
    },
    formGroupOffset: {
      type: Number,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    internalValue: null,
  }),
  created: function () {
    if (this.value) {
      this.internalValue = this.value;
    }
  },
  computed: {
    isSearchable: function () {
      return this.currentViewport !== 'xs';
    },
  },
  methods: {
    onUpdateChoice (value) {
      this.$emit('input', value, this.formGroupOffset);
    },
    reset () {
      this.internalValue = null;
    },
  },
  watch: {
    value: function (value) {
      if (value) {
        this.internalValue = value;
      }
    },
  },
};
