<template>
  <div class="image-section">
    <h3 class="d-lg-none">{{ caption }}</h3>
    <div class="image">
      <b-img :src="image" fluid />
    </div>
    <div class="content">
      <h3 class="d-none d-lg-block">{{ caption }}</h3>
      <slot><p v-if="content">{{ content }}</p></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      image: {
        type: String,
        required: true,
      },
      caption: {
        type: String,
        required: false,
      },
      content: {
        type: String,
        required: false,
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .image-section {
    margin: 31px auto;
    .image {
      margin-bottom: 18px;
    }
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      margin: 56px auto;
      .image {
        flex: 0 1 60%;
        max-width: 670px;
        margin-bottom: 0;
        margin-right: 30px;
      }
      .content {
        flex: 1 1 auto;
      }
    }
  }
  .container {
    >.image-section {
      .image {
        margin-left: -($grid-gutter-width/2);
        margin-right: -($grid-gutter-width/2);
        @include media-breakpoint-up(lg) {
          margin-left: 0;
          margin-right: 30px;
        }
      }
    }
  }
</style>
