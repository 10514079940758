import BaCompanyCourseMatrix from '@/components/partials/Company/BaCompanyCourseMatrix';
import { getBookmarkType } from '@/api/bookmark';

export default {
  components: {
    BaCompanyCourseMatrix,
  },
  props: {
    bookmarksEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => ({
    internalBookmarksResolved: false,
    internalBookmarks: {},
  }),
  mounted: function () {
    const self = this;
    if (self.bookmarksEnabled) {
      self.internalBookmarksResolved = false;
      if (self.bookmark !== null) {
        Promise.resolve(self.bookmark).then(function (value) {
          self.internalBookmarks = value;
        }).finally(() => {
          self.internalBookmarksResolved = true;
        });
      } else {
        getBookmarkType(self.recordType, self.recordId)
          .then(({ data: response }) => {
            self.internalBookmarks = response;
          })
          .finally(() => {
            self.internalBookmarksResolved = true;
          })
        ;
      }
    } else {
      self.internalBookmarksResolved = true;
    }
  },
};
