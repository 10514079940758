var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "profile-nav", class: { bottom: _vm.isBottom } },
    [
      _vm.hasTextSlot("left")
        ? _c("div", { staticClass: "col col-left" }, [_vm._t("left")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm.hasTextSlot("right")
        ? _c(
            "div",
            {
              staticClass: "col",
              class: { "col-right": _vm.hasTextSlot("left") }
            },
            [_vm._t("right")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }