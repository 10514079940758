import BaContentCommonHeader from '@/components/partials/Content/Common/Header';
import BaContentCommonContent from '@/components/partials/Content/Common/Content';

export default {
  components: {
    BaContentCommonHeader,
    BaContentCommonContent,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    defaultFrameClasses: function () {
      const spaceAfterClass = this.data.spaceAfterClass;
      return [
        'content-frame content-' + this.data.type.replace('_', '-'),
        'layout-' + this.layout,
        'space-after-' + (spaceAfterClass || 'medium'),
      ];
    },
    headerLayout: function () {
      return this.data && this.data.headerLayout ? parseInt(this.data.headerLayout) : 2;
    },
    layout: function () {
      return this.data.layout ? parseInt(this.data.layout) : 0;
    },
  },
};
