<template>
  <div :id="'c' + data.id" class="container header" :class="defaultFrameClasses">
    <div class="module-header" :class="{'uppercase': uppercase}" v-if="headerLayout === 1">
      <h1 class="title">{{data.header}}</h1>
      <h2 class="caption" v-if="data.subheader">
        {{data.subheader}}
      </h2>
      <div class="lead" v-if="data.bodytext" v-html="data.bodytext"></div>
      <!--
      <div class="text" v-if="hasTextSlot('text')">
        <slot name="text"></slot>
      </div>
      -->
    </div>
    <ba-content-common-header :data="data" v-else-if="data.header"/>
  </div>
</template>

<script>
  import BaContentCommonMixin from '@/mixins/contentCommonMixin';

  export default {
    mixins: [BaContentCommonMixin],
    props: {
      uppercase: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  @import "../../../style/content/_header";
</style>
