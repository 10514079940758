var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-btn",
    { attrs: { variant: _vm.variant }, on: { click: _vm.onClick } },
    [
      _c("font-awesome-icon", {
        class: _vm.iconClass,
        attrs: { icon: _vm.icon }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "text" }, [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }