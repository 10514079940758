<template>
  <div class="ba-card-wrapper">
    <div v-if="isHighlighted">
      <slot name="result-highlight"></slot>
    </div>
    <b-card no-body header-text-variant="white" class="ba-card card-search card-round-lg" :class="{'no-padding': disableBodyPadding, 'jq-clickable': isClickable, 'highlighted': isHighlighted, 'has-actions': hasActions}">
      <b-card-header class="ba-card-header list-item-header" :class="{'has-image': hasHeaderImage}">
        <slot name="result-header-image"></slot>
        <div class="card-headline" @click="onItemClick">
          <h3 class="headline h2">{{ header }}</h3>
          <slot name="result-header-meta"></slot>
        </div>
        <ba-profile-actions>
          <slot name="result-actions"></slot>
        </ba-profile-actions>
      </b-card-header>

      <b-card-body class="ba-card-body" @click="onItemClick">
        <slot name="result-body"></slot>
      </b-card-body>

    </b-card>
  </div>
</template>

<script>
  import BaProfileActions from '@/components/partials/Profile/BaProfileActions';

  export default {
    components: {
      BaProfileActions,
    },
    props: {
      header: {
        type: String,
        required: true,
      },
      disableBodyPadding: {
        type: Boolean,
        required: false,
        default: false,
      },
      isClickable: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    computed: {
      isHighlighted: function () {
        return !!this.$slots['result-highlight'];
      },
      hasActions: function () {
        return !!this.$slots['result-actions'];
      },
      hasHeaderImage: function () {
        return !!this.$slots['result-header-image'];
      },
    },
    methods: {
      onItemClick () {
        this.$emit('item-clicked');
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .ba-card-wrapper {
    + .ba-card-wrapper {
      margin-top: 21px;
    }
  }

  $ba-card-header-image-size: 98px;
  $ba-card-header-image-space-x: 16px;
  $ba-card-header-image-space-y: 8px;
  $ba-card-header-with-image-min-height: $ba-card-header-image-size + 2 * $ba-card-header-image-space-y;
  .ba-card-header-image {
    background-color: #fff;
    width: $ba-card-header-image-size;
    height: $ba-card-header-image-size;
    float: left;
    border-radius: 50%;
    margin-right: $ba-card-header-image-space-x;
    overflow: hidden;
    text-align: center;
    &.has-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon {
      color: $primary;
      width: 40px;
      height: 40px;
    }
    img {
      width: 100%;
      height: auto;
    }
    @include media-breakpoint-up(sm) {
      float: none;
    }
    @include media-breakpoint-up(lg) {
      margin: 0 16px 0 -5px;
    }
  }
  .row-card-meta {
    //font-weight: $ba-font-weight-medium;
    font-size: $ba-list-item-meta-font-size;
    line-height: 1.666667;
    display: flex;
    align-items: center;
    .meta-icon {
      color: $ba-list-item-color;
      margin-right: 12px;
    }
    .card-caption {
      display: none;
    }
    .text {
      display: block;
      margin-right: 15px;
      //text-transform: uppercase;
    }
    .meta {
      color: $ba-list-item-color;
      display: flex;
      align-items: center;
      + .meta {
        display: none;
      }
      + .text {
        display: none;
      }
    }
    @include media-breakpoint-up(lg) {
      .meta-icon {
        display: none;
      }
      .card-caption {
        display: block;
        text-transform: uppercase;
      }
      .meta {
        color: $ba-list-item-color;
        + .meta {
          display: flex;
          margin-left: 60px;
        }
        + .text {
          display: block;
        }
      }
      //.value {
      //  font-weight: $font-weight-light;
      //}
    }
  }

  .ba-card-header {
    background-color: $ba-list-item-background-color;//$ba-stormy-blue;
    border-bottom-width: 0;
    color: $ba-list-item-color;
    padding: 5px 0 0 0;
    display: block;
    transition: background-color .2s linear;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: calc(100% + 30px);
      background: $ba-list-item-header-border-color;
      height: 1px;
      margin-left: -15px;
    }
    .card-headline {
      flex: 1 1 auto;
      align-self: center;
      min-height: $ba-card-header-image-size;
      padding-top: 0;
      .headline {
        min-height: 65px;
      }
    }
    &.has-image {
      .card-headline {
        min-height: $ba-card-header-with-image-min-height;
      }
      .profile-card-actions {
        min-height: $ba-card-header-with-image-min-height;
        .col, .btn-action {
          min-height: $ba-card-header-with-image-min-height;
        }
      }
    }

    h2, h3 {
      color: $ba-list-item-color;
      line-height: 40/26;
      font-weight: bold;
      text-align: left;
      //text-transform: uppercase;
    }

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 10px;
      .card-headline {
        padding-top: 0;
        display: block;
        height: auto;
        min-height: 0;
        .headline {
          min-height: 0;
        }
      }
    }
    @include media-breakpoint-up(md) {
      &::after {
        margin-left: -30px;
        margin-right: -30px;
        width: calc(100% + 60px);
      }
    }

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
      align-items: center;
      padding-top: 0;
      &::after {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      .card-headline {
        padding-right: 20px;
      }
      h2, h3 {
        margin-bottom: 0;
      }
    }
  }
  .is-saving > .ba-card-header {
    background-color: $gray-400;
  }
  .ba-card {
    background: $ba-list-item-background-color;
    padding: 0 15px 15px;
    transition: all .2s ease-in-out;
    border: none;
    .card-headline {
      padding-top: 15px;
      padding-bottom: 10px;
    }
    + .ba-card {
      margin-top: 30px;
    }
    &.jq-clickable {
      cursor: pointer;
      @include hover-focus-active {
        box-shadow: 2px 2px 4px 0 $gray-500;
        .icon-angle {
          &::before,
          &::after {
            background: $primary;
          }
        }
      }
    }
    &.highlighted {
      border: 3px solid $ba-box-header-color;
    }
    .ba-card-body {
      padding: 15px 0 0;

      @include media-breakpoint-up(lg) {
        padding: 20px 0 0;
      }
    }
    @include media-breakpoint-up(sm) {
      &.card-round-lg {
        @if $ba-default-enable-rounded {
          border-radius: 13px;
        }
        overflow: visible;
        //.ba-card-header {
        //  border-top-left-radius: 13px;
        //  border-top-right-radius: 13px;
        //}
        //.ba-card-body {
        //  //border-bottom-left-radius: 13px;
        //  //border-bottom-right-radius: 13px;
        //  overflow: auto;
        //}
      }
      + .ba-card {
        margin-top: 30px;
      }
    }

    //@include media-breakpoint-up(lg) {
    //  &.card-round-lg {
    //    .profile-card-actions .col:last-child {
    //      .btn-action {
    //        border-top-right-radius: 13px;
    //      }
    //    }
    //  }
    //}
    @include media-breakpoint-up(md) {
      padding: 0 30px 20px;
      .card-headline {
        padding-top: 20px;
      }
    }
    &.no-padding {
      padding: 0;
      .ba-card-body {
        padding: 0;
      }
      .ba-card-body-text {
        padding: $card-spacer-y $card-spacer-x;
      }
      .ba-card-header {
        padding: 0 $card-spacer-x;
      }
    }
  }
</style>
