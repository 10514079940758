var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "matrix-row matrix-body-row company-offer-row",
      class: {
        "show-details": _vm.showDetails,
        "no-bookmarks": !_vm.bookmarksEnabled
      },
      attrs: { "data-sid": _vm.offer.studyInterest.id }
    },
    [
      _c("div", { staticClass: "col col-label" }, [
        _vm._v(_vm._s(_vm.offerLabel))
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col col-years",
          class: {
            "has-open-details":
              _vm.showDetails && _vm.yearDetailsEnabled !== null
          }
        },
        _vm._l(_vm.availableStudyYears, function(year) {
          return _c(
            "div",
            {
              key: year,
              staticClass: "col col-year",
              class: {
                active: _vm.showDetails && _vm.yearDetailsEnabled === year
              }
            },
            [
              _c("div", { staticClass: "label-year" }, [_vm._v(_vm._s(year))]),
              _vm._v(" "),
              _vm.isAvailable(year) === "check"
                ? _c(
                    "div",
                    { staticClass: "place-info available" },
                    [
                      _c("font-awesome-icon", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.right",
                            value: _vm.$t(
                              "components.partials.company.course_offer.places_available"
                            ),
                            expression:
                              "$t('components.partials.company.course_offer.places_available')",
                            modifiers: { hover: true, right: true }
                          }
                        ],
                        staticClass: "icon check",
                        attrs: { icon: "check" }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "label-available" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.partials.company.course_offer.places_available"
                            )
                          )
                        )
                      ])
                    ],
                    1
                  )
                : _vm.isAvailable(year) === "times"
                ? _c(
                    "div",
                    { staticClass: "place-info unavailable" },
                    [
                      _c("font-awesome-icon", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.right",
                            value: _vm.$t(
                              "components.partials.company.course_offer.no_places_available"
                            ),
                            expression:
                              "$t('components.partials.company.course_offer.no_places_available')",
                            modifiers: { hover: true, right: true }
                          }
                        ],
                        staticClass: "icon times",
                        attrs: { icon: "times" }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "label-available" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.partials.company.course_offer.no_places_available"
                            )
                          )
                        )
                      ])
                    ],
                    1
                  )
                : _c("div", { staticClass: "place-info unknown" }, [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.right",
                            value: _vm.$t(
                              "components.partials.company.course_offer.places_unknown_tooltip"
                            ),
                            expression:
                              "$t('components.partials.company.course_offer.places_unknown_tooltip')",
                            modifiers: { hover: true, right: true }
                          }
                        ],
                        staticClass: "place-symbol icon question"
                      },
                      [_vm._v("a.A.*")]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "label-available" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.partials.company.course_offer.places_unknown"
                          )
                        )
                      )
                    ])
                  ]),
              _vm._v(" "),
              _vm.showDetails && _vm.hasYearDetails(year)
                ? _c(
                    "span",
                    {
                      staticClass: "link-year-details",
                      on: {
                        click: function($event) {
                          return _vm.toggleYearDetails(year)
                        }
                      }
                    },
                    [
                      _vm.yearDetailsEnabled === year
                        ? _c("span", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.partials.company.course_offer.action.close_details"
                                )
                              )
                            )
                          ])
                        : _c("span", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.partials.company.course_offer.action.show_details"
                                )
                              )
                            )
                          ])
                    ]
                  )
                : _vm.showDetails
                ? _c("span", { staticClass: "link-year-details empty" }, [
                    _vm._v("\n         \n      ")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showDetailsBelowYear && _vm.hasYearDetails(year)
                ? [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.yearDetailsEnabled === year,
                            expression: "yearDetailsEnabled === year"
                          }
                        ],
                        staticClass: "col col-year-details"
                      },
                      [
                        _c("ba-company-course-offer-year-details", {
                          attrs: {
                            "year-details": _vm.getOfferYearDetails(year)
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.bookmarksEnabled
        ? _c(
            "div",
            { staticClass: "col col-bookmark" },
            [
              _vm.internalIsBookmarked
                ? [
                    _c(
                      "b-btn",
                      {
                        staticClass: "has-bookmark",
                        attrs: { variant: "action" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.showDeleteModal = !_vm.showDeleteModal
                          }
                        }
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "action-icon",
                          attrs: { icon: "bookmark" }
                        }),
                        _vm._v(" "),
                        _vm.showDetails
                          ? _c("span", { staticClass: "text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.partials.company.course_offer.action.bookmark_remove_details"
                                  )
                                )
                              )
                            ])
                          : _c("span", { staticClass: "text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.partials.company.course_offer.action.bookmark_remove"
                                  )
                                )
                              )
                            ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-modal",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "modal-footer",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "modal-footer-account-delete"
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "primary" },
                                          on: { click: _vm.onToggleBookmark }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.partials.company.course_offer.action.bookmark_remove.confirm.action.yes"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-link",
                                        {
                                          staticClass: "link-modal-close",
                                          on: {
                                            click: function($event) {
                                              _vm.showDeleteModal = !_vm.showDeleteModal
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.partials.company.course_offer.action.bookmark_remove.confirm.action.cancel"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2198057535
                        ),
                        model: {
                          value: _vm.showDeleteModal,
                          callback: function($$v) {
                            _vm.showDeleteModal = $$v
                          },
                          expression: "showDeleteModal"
                        }
                      },
                      [
                        _c("template", { slot: "modal-title" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.partials.company.course_offer.action.bookmark_remove_details"
                                )
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "modal-body-offer-delete" }, [
                          _c("h4", { staticClass: "subtitle" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "components.partials.company.course_offer.action.bookmark_remove_confirm",
                                    {
                                      studyOfferName: _vm.offerLabel,
                                      companyName: _vm.companyName
                                    }
                                  )
                                ) +
                                "\n          "
                            )
                          ])
                        ])
                      ],
                      2
                    )
                  ]
                : _c(
                    "b-btn",
                    {
                      attrs: { variant: "action" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.onToggleBookmark($event)
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "action-icon",
                        attrs: { icon: "bookmark" }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.partials.company.course_offer.action.bookmark_add"
                            )
                          )
                        )
                      ])
                    ],
                    1
                  )
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showDetails && !_vm.showDetailsBelowYear
        ? _vm._l(_vm.yearsWithDetails, function(yearDetails) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.yearDetailsEnabled === yearDetails.year,
                    expression: "yearDetailsEnabled === yearDetails.year"
                  }
                ],
                key: yearDetails.year,
                staticClass: "col col-year-details"
              },
              [
                _c("ba-company-course-offer-year-details", {
                  attrs: { "year-details": yearDetails }
                })
              ],
              1
            )
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }