var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-input",
        _vm._b(
          {
            staticClass: "range-slider",
            attrs: {
              min: _vm.min,
              max: _vm.stepCount,
              type: "range",
              state: _vm.state,
              required: ""
            },
            model: {
              value: _vm.modelValue,
              callback: function($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue"
            }
          },
          "b-form-input",
          _vm.inputProperties,
          false
        )
      ),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.label))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }