<template id="ba-search-form-template">
  <b-form class="ba-form ba-form-search" :class="{'has-rating': ratingsEnabled}" @submit.prevent="onExecuteSearch">

    <b-form-group class="ba-form-group-custom ba-form-group-ratings"
                  :label="$t('components.partials.search.form.rating')" label-for="search-ratings-0"
                  v-if="ratingsEnabled">
      <b-form-checkbox-group
          class="ba-checkbox-group-default"
          id="checkbox-group-2"
          name="ratings"
          v-model="search.ratings"
          @focusout="() => { this.updateComponent(); }"
      >
        <b-form-checkbox value="positive" inline>{{
            $t('components.partials.search.form.rating.positive')
          }}
        </b-form-checkbox>
        <b-form-checkbox value="neutral" inline>{{
            $t('components.partials.search.form.rating.neutral')
          }}
        </b-form-checkbox>
        <b-form-checkbox value="negative" inline>{{
            $t('components.partials.search.form.rating.negative')
          }}
        </b-form-checkbox>
        <b-form-checkbox id="search-ratings-0" value="unrated" inline>
          {{ $t('components.partials.search.form.rating.unrated') }}
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>

    <b-form-group class="ba-form-group-applicant-name" :label="$t('components.partials.search.form.name')"
                  label-for="search-applicant-name" v-if="isApplicantSearch && isBookmarkSearch">
      <b-form-input
          id="search-applicant-name"
          type="text"
          :key="componentKey + 'name'"
          :placeholder="$t('components.partials.search.form.name.placeholder')"
          v-model="search.name"
          @focusout="() => { this.updateComponent(); }"
      ></b-form-input>
    </b-form-group>

    <b-form-group class="ba-form-group-company-name" :label="$t('components.partials.search.form.company_name')"
                  label-for="search-company-name" v-if="isCompanySearch">
      <ba-company-name-select
          id="search-company-name"
          :key="componentKey + 'companyName'"
          v-model="search.companyName"
          form-group-class="form-group-search-company-name"
          :placeholder="$t('components.partials.search.form.company_name.placeholder')"
          ref="search-company-name-select"
          @input="() => { this.updateComponent(); }"
      />
    </b-form-group>

    <ba-multi-select
        v-model="search.subjectAreas"
        @focusout="() => { this.updateComponent(); }"
        :choices="availableSubjectAreas"
        :label="$t('components.partials.search.form.subject_areas')"
        :placeholder="$t('components.partials.search.form.subject_areas_placeholder')"
        id="search-subject-areas"
        ref="search-subject-areas"
        class="ba-form-group-subject-areas search-additional-form-group"
        :class="{'search-additional-show': toggleAdditionalFields}"
        multiple
        v-if="isCompanySearch && availableSubjectAreas.length > 0"/>

    <ba-multi-select
        :value="tmpStudyInterests"
        @input="setSelectedStudyInterests"
        @focusout="() => { this.updateComponent(); }"
        :choices="studyInterestGroupedChoices"
        :label="$t('components.partials.search.form.study_interests_default')"
        :placeholder="$t('components.partials.search.form.study_interests_placeholder')"
        id="search-study-interests"
        ref="search-study-interests"
        class="ba-form-group-study-interests search-additional-form-group"
        :class="{'search-additional-show': toggleAdditionalFields}"
        multiple
        grouped v-if="studyOffersVisible" v-show="false"/>

    <b-form-group class="ba-form-group-custom ba-form-group-desired-study-years"
                  :label="$t('components.partials.search.form.desired_study_year')"
                  label-for="search-desired-study-years"
                  v-if="availableStudyYears.length > 0"
    >
      <b-form-checkbox-group
          class="ba-checkbox-group-lg-down"
          id="search-desired-study-years"
          name="desiredStudyYears"
          v-model="search.desiredStudyYears"
          @input="() => { this.updateComponent(); }"
      >
        <b-form-checkbox
            v-for="availableStudyYear in availableStudyYears" :key="availableStudyYear"
            :id="'search-desired-study-year-' + availableStudyYear"
            :value="availableStudyYear"
            inline
        ><span class="text">{{ availableStudyYear }}</span>
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>

    <b-form-group :label="$t('components.partials.search.form.place')" label-for="search-place"
                  class="ba-form-group-place">
      <ba-location-select
          id="search-place"
          :value="tmpSearchPlace" @input="setSearchPlace"
          form-group-class="ba-form-group-search-place"
          :placeholder="$t('components.partials.search.form.place.placeholder')"
          ref="search-location-select"
      />
    </b-form-group>

    <b-form-group class="ba-form-group-place-radius" :label="$t('components.partials.search.form.place.radius')"
                  label-for="search-place-range">
      <ba-range :value="tmpSearchRadius" @input="setSearchRadius" :steps="availableDistancesWithoutNull" unit="km"
                prefix="+" id="search-place-range"/>
    </b-form-group>

    <ba-multi-select
        :value="tmpQualifications"
        @input="setSelectedQualifications"
        :choices="availableQualifications"
        :label="$t('components.partials.search.form.qualifications')"
        :placeholder="$t('components.partials.search.form.qualifications_placeholder')"
        class="ba-form-group-qualifications"
        id="search-qualifications"
        multiple
        v-if="isApplicantSearch"
    />

    <b-form-group class="ba-form-group-applicant-grade" :label="$t('components.partials.search.form.grade')"
                  label-for="search-applicant-grade" v-if="isApplicantSearch">
      <ba-range v-model="search.grade" :steps="availableGrades"
                :prefix="$t('components.partials.search.form.grade.prefix')"
                :empty-label="$t('components.partials.search.form.search.grade.empty')"/>
    </b-form-group>

    <b-form-group class="ba-form-group-custom ba-form-group-required-documents"
                  :label="$t('components.partials.search.form.required_documents')"
                  label-for="search-required-documents" v-if="isApplicantSearch">
      <b-form-checkbox-group
          class="ba-checkbox-group-default"
          id="search-required-documents"
          :options="applicantSearchDocuments"
          v-model="tmpRequiredDocuments"
          @input="setSelectedRequiredDocuments"
          name="requiredDocuments"
          stacked>
        <!--
        <b-form-checkbox
          v-for="(requiredDocument, documentIndex) in availableSearchDocuments" :key="documentIndex"
          :id="'search-required-document-' + documentIndex"
          :value="documentIndex"
        >{{requiredDocument}}
        </b-form-checkbox>
        -->
      </b-form-checkbox-group>
    </b-form-group>

    <b-form-group class="ba-form-group-exclude-bookmarks"
                  :label="$t(isApplicantSearch ? 'components.partials.search.form.exclude_applicant_bookmarks' : 'components.partials.search.form.exclude_company_bookmarks')"
                  label-for="search-exclude-bookmarks" v-if="isAuthenticated && !isBookmarkSearch">
      <ba-switch id="search-exclude-bookmarks" :value="tmpExcludeBookmarks" @input="setExcludeBookmarks"/>
    </b-form-group>

    <b-form-group class="ba-form-group-include-occupied" :label="$t('components.partials.search.form.exclude_occupied')"
                  label-for="search-include-occupied"
                  v-if="isCompanySearch">
      <ba-switch id="search-include-occupied" :value="tmpExcludeOccupiedCompanies"
                 @input="setExcludeOccupiedCompanies"/>
    </b-form-group>

    <ba-multi-select
        :value="search.notificationInterval || {}"
        @input="setSelectedSearchNotificationInterval"
        :choices="availableSearchNotificationIntervals"
        :label="$t('components.partials.search.form.notification_interval')"
        id="search-notification-interval"
        ref="search-notification-interval"
        class="ba-form-group-notification-interval"
        v-if="isApplicantSearch && searchId && availableSearchNotificationIntervals"
    />

    <ba-multi-select
        v-model="tmpRegion"
        :choices="availableRegionChoices"
        choice-label="text"
        :label="$t('components.partials.search.form.region')"
        :placeholder="$t('components.partials.search.form.region_placeholder')"
        class="ba-form-group-region search-additional-form-group"
        :class="{'search-additional-show': toggleAdditionalFields}"
        v-if="isCompanySearch"
        id="search-region"
    />
    <ba-multi-select
        v-model="tmpEnterpriseSize"
        :choices="availableEnterpriseSizesChoices"
        choice-label="text"
        :label="$t('components.partials.search.form.enterprise_size')"
        :placeholder="$t('components.partials.search.form.enterprise_size_placeholder')"
        class="ba-form-group-enterprise-size search-additional-form-group"
        :class="{'search-additional-show': toggleAdditionalFields}"
        v-if="isCompanySearch"
        id="search-enterprise-size"
    />
    <b-form-group class="ba-form-group-custom ba-form-group-company-type search-additional-form-group"
                  :class="{'search-additional-show': toggleAdditionalFields}"
                  v-if="isCompanySearch"
                  :label="$t('components.partials.search.form.company_type')"
                  label-for="search-company-type">
      <b-form-checkbox-group
          class="ba-checkbox-group-lg-down"
          id="search-company-type"
          name="company-type"
          :multiple="true"
          v-model="tmpCompanyType"
          @input="() => { this.updateComponent(); }"
      >
        <b-form-checkbox
            v-for="ctChoiceObject in availableCompanyTypeChoices" :key="'companyType-'+ctChoiceObject.id"
            :id="'search-company-type-' + ctChoiceObject.id"
            :value="ctChoiceObject.id"
            inline
        ><span class="text">{{ctChoiceObject.text}}</span>
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>

    <ba-multi-select
        v-model="tmpFocus"
        :choices="availableFocusChoices"
        choice-label="text"
        :label="$t('components.partials.search.form.focus')"
        :placeholder="$t('components.partials.search.form.focus_placeholder')"
        class="ba-form-group-focus"
        v-if="isCompanySearch"
        id="search-focus"
    />
    <!--<div class="search-additional-toggle"
         v-if="isCompanySearch"
         :class="{'search-additional-show': toggleAdditionalFields}"
         @click="onAdditionSearchToggleClickOnce">
      <span class="icon icon-angle down"></span>
      <span>{{ $t('components.partials.search.form.show_additional_filters') }}</span>
    </div>-->

    <div class="row-form-actions" v-if="isBookmarkSearch">
      <b-btn variant="primary" class="btn-ba-action" @click="onExecuteSearch">
        {{ $t('components.partials.search.form.actions.filter') }}
      </b-btn>
      <b-btn variant="text" class="btn-ba-action btn-reset d-none d-xl-block" @click="onResetSearch">
        {{ $t('components.partials.search.form.actions.reset') }}
      </b-btn>
    </div>
    <div class="row-form-actions" v-else>
      <b-btn variant="primary" class="btn-ba-action" @click="onExecuteSearch">
        {{ $t('components.partials.search.form.actions.save') }}
      </b-btn>
      <b-btn variant="text" class="btn-ba-action btn-reset d-none d-xl-block" @click="onResetSearch"
             v-if="isActiveSearch">
        {{ $t('components.partials.search.form.actions.reset') }}
      </b-btn>
      <ba-save-named-button
          variant="outline-primary"
          save-icon="check"
          @save="onUpdateSearchName"
          v-model="search.name"
          v-if="saveEnabled"
          :is-saving="isSaving"
          :has-errors="hasErrors"
          :input-state="saveFormInputState"
      />
      <div class="form-errors" v-if="saveFormErrors && saveFormErrors.name">
        <div class="alert alert-danger" v-if="saveFormErrors.name === 'unique'">
          {{ $t('components.partials.search.form.error_feedback.name.unique') }}
        </div>
        <div class="alert alert-danger" v-else-if="saveFormErrors.name.indexOf('UniqueSearch') >= 0">
          {{ $t('components.partials.search.form.error_feedback.unique_search') }}
        </div>
        <div class="alert alert-danger" v-else>
          {{ $t('components.partials.search.form.error_feedback.name.required') }}
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import BaSwitch from '@/components/partials/Form/BaSwitch';
import BaRange from '@/components/partials/Form/BaRange';
import BaSaveNamedButton from '@/components/partials/General/BaSaveNamedButton';
import BaMultiSelect from '@/components/partials/Form/BaMultiSelect';
import BaRecordStudyInfoMixin from '@/mixins/recordStudyInfo';
import BaLocationSelect from '@/components/partials/Form/BaLocationSelect';
import BaCompanyNameSelect from '@/components/partials/Form/BaCompanyNameSelect';

import {getSearch} from '@/api/search';
import {RECORD_TYPE} from '@/api/constants';
import {mapGetters} from 'vuex';

export default {
  mixins: [BaRecordStudyInfoMixin],
  components: {
    BaLocationSelect,
    BaCompanyNameSelect,
    BaSaveNamedButton,
    BaRange,
    BaSwitch,
    BaMultiSelect,
  },
  props: {
    searchType: {
      type: String,
      required: true,
    },
    value: Object,
    saveEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    searchId: {
      type: Number,
      required: false,
    },
    isSaving: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasErrors: {
      type: Boolean,
      required: false,
      default: false,
    },
    saveFormErrors: {
      type: Object,
      required: false,
      default: null,
    },
    onlyBookmarks: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
        search: {},
        componentKey: 0,
        defaultSearchOptions: {
          name: '',
          companyName: '',
          studyCourses: [],
          subjectAreas: [],
          studyInterests: [],
          grade: '',
          qualifications: [],
          requiredDocuments: [],
          desiredStudyYears: [],
          notificationInterval: {
            id: 0,
            name: null,
          },
          place: {
            place: '',
            radius: null,
          },
          excludeBookmarks: false,
          onlyBookmarks: false,
          includeOccupiedCompanies: false,
          additionalAttributes: {
          },
        },
        // tmp** variables are required so update of v-select will be triggered and selected value is visible
        tmpStudyInterests: [],
        tmpQualifications: [],
        tmpRequiredDocuments: [],
        tmpSearchRadius: 250,
        tmpSearchPlace: '',
        toggleAdditionalFields: true,//Always enabled as per customer wishes
        tmpRegion: null,
        tmpCompanyType: [],
        tmpEnterpriseSize: null,
        tmpFocus: null,
        tmpExcludeOccupiedCompanies: false,
        tmpExcludeBookmarks: false,
        enableSearchSave: true,
      }
  ),

  created: function () {
    const self = this;
    if (this.isApplicantBookmarkSearch) {
      this.defaultSearchOptions.ratings = ['positive', 'neutral', 'unrated'];
      // this.defaultSearchOptions['onlyBookmarks'] = true;
    }
    if (undefined !== this.searchId) {
      const searchProfileType = this.isApplicantSearch ? RECORD_TYPE.SEARCH_FOR_APPLICANTS : RECORD_TYPE.SEARCH_FOR_COMPANIES;
      getSearch(searchProfileType, this.searchId)
          .then(({data: response}) => {
            self.mergeSearchObjects(response, false);
            delete this.search.id;
            delete this.search.name;
            self.$emit('search', self.search, true);
          }).catch(error => {
        self.mergeSearchObjects(self.value, false);
        self.$emit('search', self.search, true);
      });
    } else {
      this.mergeSearchObjects(this.value, true);
      this.$emit('search', this.search, true);
    }
  },
  mounted() {
    const watchProperties = Object.keys(this).filter((name) => {
      return name.indexOf('tmp') === 0;
    });
    watchProperties.forEach((property) => {
      this.$watch(property, this.updateComponent);
    });
  },
  computed: {
    ...mapGetters(['availableGrades', 'availableDistances', 'availableSearchNotificationIntervals', 'availableStudyYears', 'availableSubjectAreas', 'isAuthenticated']),
    isActiveSearch: function () {
      if (this.componentKey === false) {
        // this wont happen but ensures method is updated if component key changes
        return;
      }
      for (const searchProperty in this.defaultSearchOptions) {
        if (Object.prototype.hasOwnProperty.call(this.defaultSearchOptions, searchProperty) && Object.prototype.hasOwnProperty.call(this.search, searchProperty)) {
          const defaultValue = this.defaultSearchOptions[searchProperty];
          const currentValue = this.search[searchProperty];
          if (currentValue === defaultValue) {
            continue;
          }
          switch (searchProperty) {
            case 'place':
              if (currentValue.place == '') {
                continue;
              }
              break;
            default:
              const currentValueLength = Array.isArray(currentValue) ? currentValue.length : typeof currentValue === 'object' ? Object.keys(currentValue).length : undefined;
              const defaultValueLength = Array.isArray(defaultValue) ? defaultValue.length : typeof defaultValue === 'object' ? Object.keys(defaultValue).length : undefined;
              if (currentValueLength === 0 && defaultValueLength === 0) {
                continue;
              }
          }
          return true;
        }
      }
      return false;
    },
    availableRegionChoices: function () {
      const choiceList = ['Pfalz', 'Ahr', 'Rheinhessen', 'Sachsen', 'Württemberg', 'Baden', 'Franken', 'Mosel', 'Nahe', 'Rheingau', 'Mittelrhein', 'Saale-Unstrut', 'Hessische Bergstraße'];
      const options = [
        {text: this.$t('components.partials.search.form.region.none'), id: null},
      ];
      for (let i = 0, n = choiceList.length; i < n; i++) {
        options.push({text: choiceList[i], id: i + 1});
      }
      return options;
    },
    availableEnterpriseSizesChoices: function () {
      const options = [
        {text: this.$t('components.partials.search.form.enterprise_size.none'), id: null},
        {text: this.$t('components.partials.search.form.enterprise_size.1'), id: 1},
        {text: this.$t('components.partials.search.form.enterprise_size.2'), id: 2},
        {text: this.$t('components.partials.search.form.enterprise_size.3'), id: 3}
      ];
      return options;
    },
    availableFocusChoices: function () {
      const options = [
        {text: this.$t('components.partials.search.form.focus.none'), id: null},
        {text: this.$t('components.partials.search.form.focus_sekt'), id: 1},
        {text: this.$t('components.partials.search.form.focus_riesling'), id: 2},
        {text: this.$t('components.partials.search.form.focus_redwine'), id: 3},
      ];
      return options;
    },
    availableCompanyTypeChoices: function () {
      const options = [
        {text: this.$t('components.partials.search.form.bio_enterprise'), id: 1},
        {text: this.$t('components.partials.search.form.vdp_enterprise'), id: 2},
      ];
      return options;
    },
    availableDistancesWithoutNull: function () {
      return this.availableDistances ? this.availableDistances.filter((value) => {
        return value;
      }) : this.availableDistances;
    },
    defaultDistanceValue: function () {
      const availableDistances = this.availableDistancesWithoutNull;
      let defaultDistance = null;
      if (availableDistances) {
        for (let i = 0; i < availableDistances.length; i++) {
          if (!defaultDistance || defaultDistance < 50) {
            defaultDistance = availableDistances[i];
          }
          if (defaultDistance >= 50) {
            break;
          }
        }
      }
      return defaultDistance;
    },
    isApplicantSearch: function () {
      return this.searchType === RECORD_TYPE.APPLICANT || this.searchType === RECORD_TYPE.APPLICANT_BOOKMARK;
    },
    isCompanySearch: function () {
      return this.searchType === RECORD_TYPE.COMPANY || this.searchType === RECORD_TYPE.COMPANY_BOOKMARK;
    },
    isApplicantBookmarkSearch: function () {
      return this.searchType === RECORD_TYPE.APPLICANT_BOOKMARK;
    },
    isBookmarkSearch: function () {
      return this.isApplicantBookmarkSearch || this.searchType === RECORD_TYPE.COMPANY_BOOKMARK;
    },
    isExcludeBookmarksEnabled: function () {
      return this.isAuthenticated && !this.isBookmarkSearch;
    },
    ratingsEnabled: function () {
      if (this.isApplicantSearch && this.onlyBookmarks && this.isSearchPropertyDefined('ratings')) {
        // Hide ratings if exclude bookmarks is enabled
        if (this.isExcludeBookmarksEnabled) {
          return !this.tmpExcludeBookmarks;
        }
        return true;
      }
      return false;
    },
    saveFormInputState: function () {
      if (this.saveFormErrors.name !== undefined) {
        return this.saveFormErrors.name;
      }
      return null;
    },
    studyOffersVisible: function () {
      return false;
    },
  },
  methods: {
    updateComponent() {
      ++this.componentKey;
      this.$forceUpdate();
    },
    onAdditionSearchToggleClickOnce() {
      this.toggleAdditionalFields = true;
    },
    onExecuteSearch() {
      if (this.isCompanySearch) {
        this.search.additionalAttributes = {};
        if (this.tmpCompanyType && this.tmpCompanyType.length > 0) {
          if (this.tmpCompanyType.includes(1)) {
            this.search.additionalAttributes['bioEnterprise'] = true;
          }
          if (this.tmpCompanyType.includes(2)) {
            this.search.additionalAttributes['vdpEnterprise'] = true;
          }
        }
        if (this.tmpEnterpriseSize && this.tmpEnterpriseSize.id) {
          this.search.additionalAttributes['enterpriseSize'] = this.tmpEnterpriseSize.id;
        } else {
          this.search.additionalAttributes['enterpriseSize'] = null;
        }
        if (this.tmpFocus && this.tmpFocus.id) {
          this.search.additionalAttributes['focus'] = this.tmpFocus.text;
        }
        if (this.tmpRegion && this.tmpRegion.id) {
          this.search.additionalAttributes['region'] = this.tmpRegion.text;
        }
      }
      this.$emit('search', this.search, false);
      this.$emit('searchClicked', this.search);
    },
    storeSearch() {
      const self = this;
      const storeSearch = {};
      this.updateComponent();
      // Only store search properties that with values
      Object.keys(this.search).forEach(function (key) {
        if (key !== 'id' && key !== 'name' &&
            (!Array.isArray(self.search[key]) || self.search[key].length > 0) &&
            (!Object.prototype.hasOwnProperty.call(self.defaultSearchOptions, key) ||
                self.defaultSearchOptions[key] !== self.search[key])) {
          storeSearch[key] = self.search[key];
        }
      });
      if (storeSearch.recordId) {
        storeSearch.recordId = null;
      }
      this.$store.dispatch('updateSearchStorageEntry', {
        key: this.searchType,
        data: storeSearch,
      });
    },
    reset() {
      const self = this;
      Object.keys(self.search).forEach(function (key, index) {
        if (typeof self.search[key] === 'string') {
          self.search[key] = '';
        } else if (typeof self.search[key] === 'boolean') {
          self.search[key] = false;
        }
      });
      this.search = {};
      this.$store.dispatch('unsetSearchStorageEntry', this.searchType);
      this.mergeSearchObjects({
        excludeBookmarks: false,
      }, false);
      this.search.name = '';
      this.search.companyName = '';
      this.search.place.place = '';
      this.search.place.radius = this.defaultDistanceValue;
      this.tmpRequiredDocuments = [];
      this.tmpExcludeOccupiedCompanies = false;
      this.tmpExcludeBookmarks = this.search.excludeBookmarks;
      this.tmpQualifications = [];
      this.tmpSearchRadius = this.defaultDistanceValue;
      this.tmpSearchPlace = null;
      this.setSelectedStudyInterests([]);
      this.search.desiredStudyYears = [];
      this.search.requiredDocuments = {};
      if (Object.prototype.hasOwnProperty.call(this.$refs, 'search-study-interests')) {
        this.$refs['search-study-interests'].reset();
      }
      if (Object.prototype.hasOwnProperty.call(this.$refs, 'search-notification-interval')) {
        this.$refs['search-notification-interval'].reset();
      }
      if (this.$refs['search-location-select']) {
        this.$refs['search-location-select'].reset();
      }
    },
    onResetSearch() {
      this.reset();
      this.$emit('reset', this.search);
    },
    onUpdateSearchName(name) {
      this.search.name = name;
      this.$emit('save-search', this.search);
    },
    isSearchPropertyDefined(property) {
      return typeof this.search[property] !== 'undefined';
    },
    setExcludeOccupiedCompanies(value) {
      this.search.includeOccupiedCompanies = !value;
      this.tmpExcludeOccupiedCompanies = !this.search.includeOccupiedCompanies;
    },
    setOrganicOnlyCompanies(value) {
      this.tmpBioEnterprise = !this.tmpBioEnterprise;
    },
    setExcludeBookmarks(value) {
      this.search.excludeBookmarks = value;
      this.tmpExcludeBookmarks = value;
    },
    setSelectedStudyInterests(value) {
      this.tmpStudyInterests = value;
      this.search.studyInterests = value;
      this.search.studyCourses = [];
      // #31486-17
      // const selectedCourses = {};
      // for (let i = 0, n = value.length; i < n; i++) {
      //   const courseId = value[i].course_id;
      //   if (courseId && !Object.prototype.hasOwnProperty.call(selectedCourses, courseId)) {
      //     this.search.studyCourses.push({ id: courseId, name: 'TMP-NAME-' + courseId });
      //     selectedCourses[courseId] = courseId;
      //   }
      // }
    },
    setSelectedQualifications(value) {
      this.tmpQualifications = value;
      this.search.qualifications = value;
    },
    setSearchRadius(value) {
      this.tmpSearchRadius = value;
      this.search.place = {
        place: this.search.place.place,
        radius: value,
      };
    },
    setSearchPlace(value) {
      let radius = this.search.place.radius;
      let place = value ? value.name : null;
      if (place === null || place === '') {
        radius = null;
      } else if (!radius) {
        radius = this.defaultDistanceValue;
        this.tmpSearchRadius = radius;
      }
      this.tmpSearchPlace = value;
      this.search.place = {
        radius: radius,
        place: place,
      };
    },
    setSelectedRequiredDocuments(value) {
      this.tmpRequiredDocuments = value;
      this.search.requiredDocuments = {};
      const validSearchItems = this.availableSearchDocuments;
      for (let i = 0, n = value.length; i < n; i++) {
        const prop = value[i];
        this.search.requiredDocuments[prop] = Object.prototype.hasOwnProperty.call(validSearchItems, prop) ? validSearchItems[prop] : prop;
      }
    },
    setSelectedSearchNotificationInterval(value) {
      this.search.notificationInterval = value;
    },
    mergeSearchObjects: function (mergeObject, loadFromStorage) {
      Object.assign(this.search, this.defaultSearchOptions, mergeObject);
      if (loadFromStorage && !this.search.id) {
        const storedSearch = this.$store.getters.getSearchStorageEntryByKey(this.searchType);
        if (storedSearch !== null && typeof storedSearch !== 'undefined') {
          Object.assign(this.search, storedSearch, mergeObject);
        }
      }
      if (!this.search.notificationInterval && this.search.id) {
        this.search.notificationInterval = this.defaultSearchOptions.notificationInterval;
      }
      this.tmpExcludeOccupiedCompanies = false;
      this.tmpExcludeBookmarks = this.search.excludeBookmarks;
      if (this.isSearchPropertyDefined('studyInterests')) {
        this.tmpStudyInterests = this.search.studyInterests;
      }
      if (this.isSearchPropertyDefined('qualifications')) {
        this.tmpQualifications = this.search.qualifications;
      }
      if (typeof this.search.place !== 'undefined') {
        if (typeof this.search.place.radius !== 'undefined') {
          this.tmpSearchRadius = this.search.place.radius;
        }
        if (typeof this.search.place.place !== 'undefined') {
          if (this.search.place.place.length > 0) {
            this.tmpSearchPlace = {name: this.search.place.place};
          } else {
            this.tmpSearchPlace = null;
          }
        }
      }
      if (this.isSearchPropertyDefined('requiredDocuments')) {
        this.tmpRequiredDocuments = [];
        for (const prop in this.search.requiredDocuments) {
          if (Object.prototype.hasOwnProperty.call(this.search.requiredDocuments, prop)) {
            this.tmpRequiredDocuments.push(prop);
          }
        }
      }
    },
  },
  watch: {
    search: {
      handler: function () {
        this.updateComponent();
      },
      deep: true,
    },
  },
};
</script>

<i18n lang="yaml" locale="de">
components.partials.search.form.rating: 'Bewertung'
components.partials.search.form.rating.negative: 'Negativ'
components.partials.search.form.rating.positive: 'Favorit*in'
components.partials.search.form.rating.neutral: 'Neutral'
components.partials.search.form.rating.unrated: 'Unbewertet'
components.partials.search.form.name: 'Name'
components.partials.search.form.name.placeholder: 'Name des*der Bewerber*in'
components.partials.search.form.required_documents: 'Benötigte Dokumente'
components.partials.search.form.qualifications: 'Hochschulzugangsberechtigungen'
components.partials.search.form.qualifications_placeholder: 'Berechtigungen auswählen'
components.partials.search.form.notification_interval: 'Benachrichtigungsintervall'
components.partials.search.form.exclude_occupied: 'Nur Partner mit freien Studienplätzen anzeigen'
components.partials.search.form.include_occupied: 'Unternehmen ohne freie Plätze suchen'
components.partials.search.form.grade: 'Abschlussnote'
components.partials.search.form.grade.prefix: 'Besser als'
components.partials.search.form.exclude_applicant_bookmarks: 'Gemerkte / Bewertete ausschliessen'
components.partials.search.form.exclude_company_bookmarks: 'Gemerkte Ausbildungsbetriebe ausschliessen'
components.partials.search.form.search.grade.empty: 'beliebig'
components.partials.search.form.actions.filter: 'Merkliste filtern'
components.partials.search.form.actions.reset: 'Suche zurücksetzen'
components.partials.search.form.actions.save: 'Suchen'
components.partials.search.form.company_name: 'Ausbildungsbetrieb'
components.partials.search.form.company_name.placeholder: 'Name des Kooperationsbetriebes'
components.partials.search.form.desired_study_year: 'Studienbeginn'
components.partials.search.form.place: 'Standort'
components.partials.search.form.place.placeholder: 'Ort oder Postleitzahl eingeben'
components.partials.search.form.place.radius: 'Suchradius'
components.partials.search.form.study_interests_placeholder: 'Studiengang/ -richtung auswählen'
components.partials.search.form.study_interests_default: 'Studienwunsch'
components.partials.search.form.subject_areas: 'Studiengang'
components.partials.search.form.subject_areas_placeholder: 'Studiengang auswählen'
components.partials.search.form.error_feedback.name.required: 'Bitte geben Sie einen Namen ein.'
components.partials.search.form.error_feedback.name.unique: 'Sie haben bereits einen Suchauftrag mit diesem Namen erstellt. Bitte geben Sie einen anderen Namen ein.'
components.partials.search.form.error_feedback.unique_search: 'Sie haben bereits einen Suchauftrag mit diesen Kriterien erstellt. Bitte passen Sie die Anforderungen an, um einen neuen Suchauftrag anzulegen.'
components.partials.search.form.company_type: 'Art des Betriebs'
components.partials.search.form.vdp_enterprise: 'VDP Betrieb'
components.partials.search.form.bio_enterprise: 'Bio-Betrieb (zertifiziert)'
components.partials.search.form.enterprise_size: 'Größe des Betriebs'
components.partials.search.form.enterprise_size_placeholder: 'Größe des Betriebs wählen'
components.partials.search.form.enterprise_size.none: 'beliebig'
components.partials.search.form.enterprise_size.1: 'bis 20 ha'
components.partials.search.form.enterprise_size.2: 'ab 20 ha'
components.partials.search.form.enterprise_size.3: 'ab 100 ha'
components.partials.search.form.focus.none: 'beliebig'
components.partials.search.form.focus_sekt: 'Sekt'
components.partials.search.form.focus_riesling: 'Riesling'
components.partials.search.form.focus_redwine: 'Rotwein'
components.partials.search.form.region: 'Weinanbauregion'
components.partials.search.form.region_placeholder: 'Weinanbauregion auswählen'
components.partials.search.form.region.none: 'beliebig'
components.partials.search.form.focus: 'Schwerpunkt'
components.partials.search.form.focus_placeholder: 'Schwerpunkt auswählen'
components.partials.search.form.show_additional_filters: 'Weitere Filter anzeigen'

</i18n>

<style lang="scss">
@import "../../../style/_init-vars-functions-mixins";

.search-additional-toggle {
  display: none;

  @include media-breakpoint-up(xl) {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 26px;
    font-weight: $ba-font-weight-bold;
    background: #fff;
    color: $ba-main-color1;
    padding: 18px 24px 14px 0;
    height: 59px;
    white-space: nowrap;
    transition: all .2s linear;
    z-index: 10;
    order: 32;
    margin-top: -92px;
    .icon {
      display: block;
      margin-right: 20px;
      width: 11px;
      margin-top: 2px;
      &::before,
      &::after {
        background-color: $ba-main-color1;
        height: 14px;
      }
    }
    @include hover-focus {
      color: $ba-main-color3;
      .icon {
        &::before,
        &::after {
          background-color: $ba-main-color3;
        }
      }
    }
    &.search-additional-show {
      opacity: 0;
    }
  }
}

.search-additional-form-group {

  @include media-breakpoint-up(xl) {
    display: none;
    //max-height: 0;
    //overflow: hidden;
    transition: all .2s linear;
    &.search-additional-show {
      display: block;
    }
  }
}

.row-form-actions {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;

  .btn-ba-action {
    display: block;
    width: 100%;
    max-width: 260px;
    margin: 0 auto;

    + .btn-ba-action {
      margin-top: 20px;
    }

    + .control-button-group {
      margin-top: 20px;
    }
  }

  .btn-reset {
    text-transform: none;
    border-radius: 0;
    padding: 0;
    text-decoration: underline;

    @include hover-focus-active {
      text-decoration: none;
    }
  }

  @include media-breakpoint-up(lg) {
    flex: 0 0 100%;
    order: 20;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 30px;
    > .btn {
      margin: 0 0 0 20px;
      z-index: 10;
    }
    .control-button-group {
      margin: 0 0 0 20px;
    }
  }
}

.ba-form-search {
  @include media-breakpoint-up(lg) {
    .form-group {
      margin-bottom: 30px;

      &.control-label-overlay {
        margin-bottom: 0;
      }
    }
    .ba-form-group-custom {
      margin-bottom: 22px;
    }
  }
}
</style>
