<template>
  <div class="center-content bottom-pagination" v-if="showPagination">
    <b-pagination
      hide-goto-end-buttons
      :value="value"
      @input="onInput"
      :total-rows="rows"
      :per-page="perPage"
    >
      <template v-slot:prev-text>
        <span class="icon-angle icon-2x left"></span>
        <span class="sr-only">&nbsp;{{ $t('components.partials.general.pagination.bottom.prev') }}
        {{ $t('components.partials.general.pagination.bottom.page') }}</span>
      </template>
      <template v-slot:next-text>
        <span class="sr-only">&nbsp;{{ $t('components.partials.general.pagination.bottom.next') }}
        {{ $t('components.partials.general.pagination.bottom.page') }}</span>
        <span class="icon-angle icon-2x right"></span>
      </template>
    </b-pagination>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Number,
      },
      rows: {
        type: Number,
        required: true,
      },
      perPage: {
        type: Number,
        required: false,
        default: 10,
      },
    },
    computed: {
      showPagination: function () {
        return this.rows > this.perPage;
      },
    },
    methods: {
      onInput: function (value) {
        const appElt = document.getElementById('app-container');
        if (appElt !== null && typeof window.scroll !== 'undefined') {
          window.scroll({
            top: appElt.offsetTop,
            left: 0,
            behavior: 'smooth',
          });
        }
        this.$emit('input', value);
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .bottom-pagination {
    display: flex;
    justify-content: center;
    margin: 60px 0 50px 0;
  }
</style>

<i18n lang="yaml" locale="de">
  components.partials.general.pagination.bottom.prev: 'Vorherige'
  components.partials.general.pagination.bottom.next: 'Nächste'
  components.partials.general.pagination.bottom.page: 'Seite'
</i18n>
