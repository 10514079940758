<template>
  <div class="data-container offer-details-container">
    <b-row>
      <b-col cols="12" md="5" lg="7" xl="6">
        <h3 class="headline">{{ $t('components.partials.company.course_offer_year_details.action.offer_details_headline') }}</h3>
        <b-row>
          <b-col class="col-offer-name" cols="12" lg="6">
            <template v-if="yearDetails.applicationContactLastName">
              <div class="offer-name-box">
                <span class="icon-circle bg-info">
                  <font-awesome-icon class="icon" icon="user"/>
                </span>
                <p class="item-meta">
                  {{yearDetails.applicationContactTitle}}
                  {{yearDetails.applicationContactFirstName}} {{yearDetails.applicationContactLastName}}
                </p>
              </div>
            </template>
          </b-col>
          <b-col class="col-offer-contact" cols="12" lg="6">
            <p class="item-meta has-icon" v-if="contactPhone">
              <font-awesome-icon class="icon" icon="phone-alt"/>
              <span class="text">{{contactPhone}}</span>
            </p>
            <p class="item-meta has-icon" v-if="contactEmail">
              <font-awesome-icon class="icon" icon="envelope-open"/>
              <a :href="'mailto:' + contactEmail">{{contactEmail}}</a>
            </p>
            <p class="item-meta has-icon" v-if="contactWww">
              <font-awesome-icon class="icon" icon="link"/>
              <a :href="contactWww" target="_blank">{{contactWww}}</a>
            </p>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="7" lg="5" xl="6" v-if="yearDetails.info">
        <h3 class="headline">{{ $t('components.partials.company.course_offer_year_details.action.offer_details_add_info') }}</h3>
        <p class="item-meta spacer-top">
          {{yearDetails.info}}
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    props: {
      yearDetails: {
        type: Object,
        required: true,
      },
    },
    computed: {
      contactPhone: function () {
        return this.yearDetails.applicationContactPhone ? this.yearDetails.applicationContactPhone : this.yearDetails.generalPhone;
      },
      contactEmail: function () {
        return this.yearDetails.applicationContactEmail ? this.yearDetails.applicationContactEmail : this.yearDetails.generalEmail;
      },
      contactWww: function () {
        return this.yearDetails.generalWww;
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";

  .offer-details-container {
    background: $ba-default-background;
    text-align: center;
    padding: 30px 15px;

    .headline {
      margin-bottom: 8px;
    }

    .col-offer-contact {
      font-size: .75rem;
      letter-spacing: .1px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 15px;

      @include media-breakpoint-up(md) {
        align-items: flex-start;
        margin-bottom: 0;
        padding-top: 13px;
      }
    }
    .offer-name-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon-circle {
        color: #fff;
        margin-bottom: 10px;
        flex: 0 0 53px;
        width: 53px;
        height: 53px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .icon {
        width: 25px;
        height: 25px;
      }
      @include media-breakpoint-up(md) {
        flex-direction: row;
        .icon-circle {
          margin-right: 16px;
          margin-bottom: 0;
        }
      }
    }
    .item-meta {
      &.has-icon {
        color: $ba-dark-grey-blue;
        display: flex;
        align-items: center;
        letter-spacing: .1px;
        margin-bottom: 10px;
      }
      a {
        color: $ba-dark-grey-blue;
      }
      .icon {
        color: $ba-dark-grey-blue;
        height: 16px;
        width: 14px;
        margin-right: 9px;
      }
    }
    @include media-breakpoint-up(md) {
      text-align: left;
      padding: 26px 45px 26px;
      .spacer-top {
        padding-top: 15px;
      }
    }
  }
</style>

<i18n lang="yaml" locale="de">
  components.partials.company.course_offer_year_details.action.offer_details_headline: 'Bewerbungskontakt'
  components.partials.company.course_offer_year_details.action.offer_details_add_info: 'Weitere Informationen'
</i18n>
