<template>
  <div class="block-button" v-if="data.buttonLink && data.buttonText">
    <b-btn :variant="data.buttonLayout" :href="data.buttonLink" target="_blank">{{ data.buttonText }}</b-btn>
  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
  };
</script>
