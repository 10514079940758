var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasErrors
    ? _c("div", { staticClass: "form-errors" }, [
        _vm.customError
          ? _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v("\n    " + _vm._s(_vm.customError) + "\n  ")
            ])
          : _vm.hasGeneralError
          ? _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t(
                      "components.partials.common.profile.alert.general_error_on_save"
                    )
                  ) +
                  "\n  "
              )
            ])
          : _c("div", { staticClass: "alert alert-danger" }, [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t(
                      "components.partials.common.profile.alert.property_error_on_save"
                    )
                  ) +
                  "\n  "
              )
            ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }