<script>
  import Config from '@/services/config';
  import BaSearchForm from '@/components/partials/Search/BaSearchForm';
  import {mapGetters} from "vuex";

  export default {
    extends: BaSearchForm,
    template: '#ba-search-form-template',
    data: () => ({
      search: {
        // place must be set or we get an error!
        place: {
          place: '',
          radius: null,
        },
      },
      defaultSearchOptions: {
        companyName: '',
        studyCourses: [],
        subjectAreas: [],
        studyInterests: [],
        desiredStudyYears: [],
        place: {
          place: '',
          radius: null,
        },
        excludeBookmarks: false,
        includeOccupiedCompanies: true,
      },
    }),
    computed: {
      ...mapGetters(['isAuthenticated']),
      studyOffersVisible: function () {
        return Config.company.studyOffersVisibility === 'all' || this.isAuthenticated;
      },
    },
  };
</script>
