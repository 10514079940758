import { render, staticRenderFns } from "./BaComposeMessageModal.vue?vue&type=template&id=7e10ff38&"
import script from "./BaComposeMessageModal.vue?vue&type=script&lang=js&"
export * from "./BaComposeMessageModal.vue?vue&type=script&lang=js&"
import style0 from "./BaComposeMessageModal.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BaComposeMessageModal.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml&locale=de"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/gerthammes/dev/campus-match/weincampus-campus-match-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7e10ff38')) {
      api.createRecord('7e10ff38', component.options)
    } else {
      api.reload('7e10ff38', component.options)
    }
    module.hot.accept("./BaComposeMessageModal.vue?vue&type=template&id=7e10ff38&", function () {
      api.rerender('7e10ff38', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/partials/Message/BaComposeMessageModal.vue"
export default component.exports