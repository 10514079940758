<template>
  <div class="matrix-row matrix-body-row company-offer-row" :class="{'show-details': showDetails, 'no-bookmarks': !bookmarksEnabled}" :data-sid="offer.studyInterest.id">
    <div class="col col-label">{{ offerLabel }}</div>
    <div class="col col-years" :class="{'has-open-details': showDetails && yearDetailsEnabled !== null}">
      <div class="col col-year" v-for="year in availableStudyYears" :key="year" :class="{'active': showDetails && yearDetailsEnabled === year}">
        <div class="label-year">{{ year }}</div>
        <div class="place-info available" v-if="isAvailable(year) === 'check'">
          <font-awesome-icon class="icon check" icon="check" v-b-tooltip.hover.right="$t('components.partials.company.course_offer.places_available')" />
          <span class="label-available">{{ $t('components.partials.company.course_offer.places_available') }}</span>
        </div>
        <div class="place-info unavailable" v-else-if="isAvailable(year) === 'times'">
          <font-awesome-icon class="icon times" icon="times" v-b-tooltip.hover.right="$t('components.partials.company.course_offer.no_places_available')" />
          <span class="label-available">{{ $t('components.partials.company.course_offer.no_places_available') }}</span>
        </div>
        <div class="place-info unknown" v-else>
          <span class="place-symbol icon question" v-b-tooltip.hover.right="$t('components.partials.company.course_offer.places_unknown_tooltip')">a.A.*</span>
          <span class="label-available">{{ $t('components.partials.company.course_offer.places_unknown') }}</span>
        </div>
        <span class="link-year-details" v-if="showDetails && hasYearDetails(year)" @click="toggleYearDetails(year)">
          <span class="text" v-if="yearDetailsEnabled === year">{{ $t('components.partials.company.course_offer.action.close_details') }}</span>
          <span class="text" v-else>{{ $t('components.partials.company.course_offer.action.show_details') }}</span>
        </span>
        <span class="link-year-details empty" v-else-if="showDetails">
          &nbsp;
        </span>
        <template v-if="showDetailsBelowYear && hasYearDetails(year)">
          <div class="col col-year-details" v-show="yearDetailsEnabled === year">
            <ba-company-course-offer-year-details :year-details="getOfferYearDetails(year)"/>
          </div>
        </template>
      </div>
    </div>
    <div class="col col-bookmark" v-if="bookmarksEnabled">
      <template v-if="internalIsBookmarked">
        <b-btn variant="action" @click.stop="showDeleteModal = !showDeleteModal" class="has-bookmark">
          <font-awesome-icon class="action-icon" icon="bookmark" />
          <span class="text" v-if="showDetails">{{ $t('components.partials.company.course_offer.action.bookmark_remove_details') }}</span>
          <span class="text" v-else>{{ $t('components.partials.company.course_offer.action.bookmark_remove') }}</span>
        </b-btn>
        <b-modal v-model="showDeleteModal">
          <template slot="modal-title">
            <span>{{ $t('components.partials.company.course_offer.action.bookmark_remove_details') }}</span>
          </template>
          <div class="modal-body-offer-delete">
            <h4 class="subtitle">
              {{ $t('components.partials.company.course_offer.action.bookmark_remove_confirm', {studyOfferName: offerLabel, companyName: companyName}) }}
            </h4>
          </div>
          <template v-slot:modal-footer>
            <div class="modal-footer-account-delete">
              <b-button variant="primary" @click="onToggleBookmark">{{ $t('components.partials.company.course_offer.action.bookmark_remove.confirm.action.yes') }}</b-button>
              <b-link @click="showDeleteModal = !showDeleteModal" class="link-modal-close">{{ $t('components.partials.company.course_offer.action.bookmark_remove.confirm.action.cancel') }}</b-link>
            </div>
          </template>
        </b-modal>
      </template>
      <b-btn variant="action" @click.stop="onToggleBookmark" v-else>
        <font-awesome-icon class="action-icon" icon="bookmark" />
        <span class="text">{{ $t('components.partials.company.course_offer.action.bookmark_add') }}</span>
      </b-btn>
    </div>
    <template v-if="showDetails && !showDetailsBelowYear">
      <div class="col col-year-details" v-for="yearDetails in yearsWithDetails" :key="yearDetails.year" v-show="yearDetailsEnabled === yearDetails.year">
        <ba-company-course-offer-year-details :year-details="yearDetails"/>
      </div>
    </template>
  </div>
</template>

<script>
  import BaCompanyCourseOfferYearDetails from '@/components/partials/Company/BaCompanyCourseOfferYearDetails';
  import isMobileMixin from '@/mixins/isMobile';
  export default {
    mixins: [isMobileMixin],
    components: { BaCompanyCourseOfferYearDetails },
    props: {
      offer: {
        type: Object,
        required: true,
      },
      companyName: {
        type: String,
        required: true,
      },
      years: {
        type: Array,
        required: true,
      },
      showDetails: {
        type: Boolean,
        required: false,
        default: false,
      },
      isBookmarked: {
        type: Boolean,
        required: false,
        default: false,
      },
      bookmarksEnabled: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data: () => ({
      yearDetailsEnabled: null,
      internalIsBookmarked: false,
      showDeleteModal: false,
    }),
    created: function () {
      this.internalIsBookmarked = this.isBookmarked;
    },
    computed: {
      availableStudyYears: function () {
        return this.years;
      },
      offerLabel: function () {
        const courseName = this.offer.courseName;
        if (!courseName || courseName.trim().length === 0 || this.offer.studyInterest.name.indexOf(courseName) >= 0) {
          return this.offer.studyInterest.name;
        }
        return courseName + ' - ' + this.offer.studyInterest.name;
      },
      yearsWithDetails: function () {
        const values = [];
        if (this.showDetails) {
          for (let i = 0, n = this.years.length; i < n; i++) {
            const yearDetails = this.getOfferYearDetails(this.years[i]);
            if (yearDetails !== null) {
              values.push(yearDetails);
            }
          }
        }
        return values;
      },
      showDetailsBelowYear: function () {
        return this.showDetails && (this.currentViewport === 'xs' || this.currentViewport === 'sm');
      },
    },
    methods: {
      getOfferYearInfo (year) {
        if (this.offer.years) {
          const yearsObject = this.offer.years;
          if (Object.prototype.hasOwnProperty.call(yearsObject, year)) {
            return yearsObject[year];
          }
        }
        return null;
      },
      getOfferYearDetails (year) {
        if (this.offer.yearDetails) {
          const yearsObject = this.offer.yearDetails;
          if (Object.prototype.hasOwnProperty.call(yearsObject, year)) {
            if (yearsObject[year].applicationContactLastName ||
              yearsObject[year].generalPhone || yearsObject[year].applicationContactPhone ||
              yearsObject[year].generalEmail || yearsObject[year].applicationContactEmail ||
              yearsObject[year].info) {
              return yearsObject[year];
            }
          }
        }
        return null;
      },
      isAvailable (year) {
        let icon;
        const state = this.getOfferYearInfo(year);
        switch (state) {
          case true:
            icon = 'check';
            break;
          case false:
            icon = 'times';
            break;
          default:
            icon = 'question';
        }
        return icon;
      },
      onToggleBookmark: function () {
        const self = this;
        this.showDeleteModal = false;
        this.internalIsBookmarked = !this.internalIsBookmarked;
        self.$emit('bookmark-toggle', this.offer);
      },
      getBookmarkOffset: function (offer) {
        if (this.internalBookmarks && this.internalBookmarks.bookmarks) {
          const studyInterestId = offer.studyInterest.id;
          const bookmarks = this.internalBookmarks.bookmarks;
          for (let i = 0, n = bookmarks.length; i < n; i++) {
            if (parseInt(bookmarks[i].studyInterestId) === parseInt(studyInterestId)) {
              return i;
            }
          }
        }
        return null;
      },
      hasYearDetails: function (year) {
        return this.getOfferYearDetails(year) !== null;
      },
      toggleYearDetails: function (year) {
        if (this.yearDetailsEnabled === year) {
          this.yearDetailsEnabled = null;
        } else {
          this.yearDetailsEnabled = year;
        }
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .company-offer-row {
    .place-info {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      justify-content: left;
      padding-left: 32px;
      position: relative;
      min-height: 16px;
      .label-available {
        display: inline-block;
        font-size: .75rem;
        padding-left: 10px;
      }
      @include media-breakpoint-up(md) {
        flex-grow: 0;
        justify-content: center;
        padding-left: 0;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        //border: 2px solid $ba-main-color2;
        color: $ba-main-color2;
        &.available {
          //border-color: $ba-offer-available-color;
          color: $ba-offer-available-color;
        }
        &.unavailable {
          //border-color: $ba-offer-unavailable-color;
          color: $ba-offer-unavailable-color;
        }
        .label-available {
          display: none;
        }
      }
    }
    .icon {
      &.check {
        color: $ba-offer-available-color;
      }
      &.times {
        color: $ba-offer-unavailable-color;
      }
      &.question {
        color: $ba-main-color2;
      }
    }
    .place-symbol {
      font-weight: $ba-font-weight-medium;
    }
    .link-year-details {
      cursor: pointer;
      display: block;
      margin-left: auto;
      color: $ba-dark-grey-blue;
      font-size: .75rem;
      line-height: 14px;
      text-decoration: underline;
      text-align: center;
      width: 60px;
      flex: 0 0 60px;
      &.empty {
        cursor: default;
        text-decoration: none;
      }

      @include media-breakpoint-up(md) {
        margin-top: 6px;
        margin-left: 0;
        width: auto;
        flex: 0 0 auto;
      }
    }
    .label-year {
      background: $ba-default-background;
      font-weight: $ba-font-weight-medium;
      padding: 12px 16px 12px 18px;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
    .has-open-details {
      .label-year {
        background: transparent;
      }
      .active {
        background: $ba-default-background;
      }
    }
    .btn-action {
      border-radius: 0;
      padding: .5rem;
      .text {
        display: block;
        font-size: .75rem;
        line-height: 14px;
      }
      @include media-breakpoint-up(md) {
        border-radius: 0;
        font-weight: $font-weight-normal;
        //background-color: #fff;
        padding: .75rem;
        transition: all .2s linear;
        @include hover-focus-active {
          background-color: $ba-default-background;
          color: $primary;
        }
      }
    }
    &.show-details {
      .btn-action {
        .action-icon {
          width: 23px;
          height: 23px;
        }
      }
      @include media-breakpoint-up(md) {
        .btn-action {
          font-weight: $ba-font-weight-medium;
          color: $primary;
          @include hover-focus-active {
            background-color: transparent;
            color: $ba-stormy-blue;
          }
        }
      }
    }
  }
</style>

<i18n lang="yaml" locale="de">
  components.partials.company.course_offer.action.bookmark_add: 'Studienangebot merken'
  components.partials.company.course_offer.action.bookmark_remove: 'Nicht mehr merken'
  components.partials.company.course_offer.action.bookmark_remove_details: 'Studienangebot nicht mehr merken'
  components.partials.company.course_offer.action.bookmark_remove_confirm: 'Bist du sicher, dass du das Studienangebot {studyOfferName} vom Ausbildungsbetrieb {companyName} von der Merkliste entfernen möchtest?'
  components.partials.company.course_offer.action.bookmark_remove.confirm.action.yes: 'Ja, nicht mehr merken'
  components.partials.company.course_offer.action.bookmark_remove.confirm.action.cancel: 'Abbrechen'
  components.partials.company.course_offer.places_available: 'freie Ausbildungsplätze'
  components.partials.company.course_offer.no_places_available: 'keine freien Ausbildungsplätze'
  components.partials.company.course_offer.places_unknown: 'keine Angabe zu freien Ausbildungplätzen'
  components.partials.company.course_offer.places_unknown_tooltip: 'a.A. - es liegen keine aktuellen Daten zu freien Ausbildungsplätzen vor. Wenden Sie sich bei Interesse bitte direkt an den Betrieb.'
  components.partials.company.course_offer.action.show_details: 'Details'
  components.partials.company.course_offer.action.close_details: 'Details schließen'
</i18n>
