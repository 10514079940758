<template>
  <div :id="'c' + data.id" class="container text-section-content" :class="defaultFrameClasses">
    <ba-content-common-header :data="data" v-if="data.header"/>
    <ba-content-common-content :data="data"/>
  </div>
</template>

<script>
  import BaContentCommonMixin from '@/mixins/contentCommonMixin';

  export default {
    mixins: [BaContentCommonMixin],
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  @import "../../../style/content/_text";
</style>
