<template>
  <b-form-group :label="label" :label-for="id" :class="formGroupClass">
    <multiselect
      :id="id"
      v-model="internalValue"
      :options="choices"
      :multiple="multiple"
      :placeholder="placeholder ? placeholder : $t('form.select.placeholder.general')"
      open-direction="bottom"
      track-by="name"
      :label="choiceLabel"
      @input="onUpdateChoice"
      :searchable="true"
      :loading="isLoading"
      :internal-search="false"
      :clear-on-select="false"
      :close-on-select="true"
      :options-limit="300"
      :limit="3"
      :limit-text="limitText"
      :max-height="600"
      :show-no-results="false"
      :hide-selected="false"
      @search-change="asyncFind"
    >
      <template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.name }}</span><span class="custom__remove" @click="remove(option)">❌</span></span></template>
      <template slot="singleLabel">
        <span class="multiselect__tag" v-if="internalValue && internalValue.name">
          <span>{{ internalValue.name }}</span> <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @mousedown.prevent.stop="clearValue()"></i>
        </span>
        <span v-else class="multiselect__placeholder">{{ placeholder ? placeholder : $t('form.select.placeholder.general') }}</span>
      </template>
      <template slot="clear" slot-scope="props">
        <div class="multiselect__clear" v-if="internalValue && internalValue.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
      </template>
      <span slot="noResult">{{ $t('form.select.no_results') }}</span>
    </multiselect>
  </b-form-group>
</template>

<script>
  import { getMatchingLocations } from '@/api/location';
  import BaMultiSelect from '@/mixins/multiSelect.js';

  export default {
    mixins: [BaMultiSelect],
    data: () => ({
      defaultChoices: [{ id: 0, name: '', zipCode: '' }],
      choices: [],
      isLoading: false,
      lastValue: false,
    }),
    created () {
      this.assignDefaultChoices();
    },
    methods: {
      limitText: function (count) {
        return `und ${count} andere Standorte`;
      },
      assignDefaultChoices: function () {
        this.choices = JSON.parse(JSON.stringify(this.defaultChoices));
      },
      lazyLoadValues: function (query) {
        this.isLoading = true;
        getMatchingLocations(query).then(response => {
          this.assignDefaultChoices();
          for (let i = 0, n = response.data.length; i < n; ++i) {
            const choice = response.data[i];
            if (choice.zipCode) {
              choice.name = `${choice.name} (${choice.zipCode})`;
            }
            this.choices.push(choice);
          }
          this.isLoading = false;
        });
      },
      asyncFind: function (query) {
        if (query.length > 2) {
          this.lastValue = query;
          // wait 300ms and check if value has changed since
          setTimeout(() => {
            if (query === this.lastValue) {
              this.lazyLoadValues(query);
            }
          }, 300);
        }
      },
      clearValue: function () {
        this.internalValue = this.defaultChoices[0];
        this.$emit('input', this.internalValue);
      },
      clearAll: function () {
        this.internalValue = null;
      },
    },
  };
</script>

<i18n src="@/mixins/multiSelect.i18n.yaml" lang="yaml"/>
