var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-highlight" },
    [
      _c("font-awesome-icon", {
        staticClass: "highlight-icon",
        attrs: { icon: "info-circle" }
      }),
      _vm._v(" "),
      _vm.link
        ? _c(
            "a",
            {
              staticClass: "highlight-link",
              attrs: { href: _vm.link, target: "_blank" }
            },
            [
              _c("span", { staticClass: "highlight-text" }, [
                _vm._v(_vm._s(_vm.text))
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }