var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPagination
    ? _c(
        "div",
        { staticClass: "center-content bottom-pagination" },
        [
          _c("b-pagination", {
            attrs: {
              "hide-goto-end-buttons": "",
              value: _vm.value,
              "total-rows": _vm.rows,
              "per-page": _vm.perPage
            },
            on: { input: _vm.onInput },
            scopedSlots: _vm._u(
              [
                {
                  key: "prev-text",
                  fn: function() {
                    return [
                      _c("span", { staticClass: "icon-angle icon-2x left" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.partials.general.pagination.bottom.prev"
                              )
                            ) +
                            "\n      " +
                            _vm._s(
                              _vm.$t(
                                "components.partials.general.pagination.bottom.page"
                              )
                            )
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "next-text",
                  fn: function() {
                    return [
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.partials.general.pagination.bottom.next"
                              )
                            ) +
                            "\n      " +
                            _vm._s(
                              _vm.$t(
                                "components.partials.general.pagination.bottom.page"
                              )
                            )
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "icon-angle icon-2x right" })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              713594994
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }