<template>
  <div class="control-button-group form-group-save-search" :class="{'enable-input': editState, 'is-saving': waitingForSave, 'just-saved': hasJustBeenSaved}">
    <div class="form-group control-label-overlay" :class="{'has-value': editStateHasValue}">
      <b-form-input
        :id="nameInputId"
        v-model="modelValue"
        required
        @input="onSearchNameInputChange"
        type="text"
        ref="nameInput"
        :state="nameInputState"
      ></b-form-input>
      <label :for="nameInputId" class="control-label">{{ $t('components.partials.general.ba_save_named_button.search_name') }}</label>
    </div>
    <b-btn :variant="variant" class="show-default" @click="open">
      <span v-if="hasJustBeenSaved">{{ $t('components.partials.general.ba_save_named_button.search_saved') }}</span>
      <span v-else>{{ $t('components.partials.general.ba_save_named_button.save_search') }}</span>
    </b-btn>
    <b-btn variant="primary" class="show-enabled" :class="{'has-icon': saveIcon}" v-on:click="save">
      <font-awesome-icon :icon="['fas', saveIcon]" v-if="saveIcon"/>
      <span :class="{'sr-only': saveIcon}">{{ $t('components.partials.general.ba_save_named_button.save') }}</span>
    </b-btn>
  </div>
</template>
<script>
  export default {
    props: {
      variant: {
        type: String,
        required: false,
      },
      nameInputId: {
        type: String,
        required: false,
        default: 'search-name',
      },
      saveIcon: {
        type: String,
        required: false,
      },
      value: {
        type: String,
        default: '',
        required: false,
      },
      isSaving: {
        type: Boolean,
        required: false,
        default: false,
      },
      hasErrors: {
        type: Boolean,
        required: false,
        default: false,
      },
      inputState: {
        type: Boolean,
        required: false,
        default: null,
      },
    },
    data: () => ({
      editState: false,
      editStateHasValue: false,
      waitingForSave: false,
      hasJustBeenSaved: false,
      name: '',
    }),
    computed: {
      modelValue: {
        set: function (value) {
          this.name = value;
          this.$emit('input', value);
        },
        get: function () {
          return this.name;
        },
      },
      nameInputState: function () {
        if (this.hasErrors) {
          return this.inputState;
        }
        return null;
      },
    },
    watch: {
      value: function (value) {
        this.name = value;
      },
      isSaving: {
        handler: function (val, oldVal) {
          if (oldVal && !val && this.waitingForSave && !this.hasErrors) {
            this.reset();
            const self = this;
            self.hasJustBeenSaved = true;
            setTimeout(function () {
              self.hasJustBeenSaved = false;
            }, 2000);
          }
        },
        // deep: true,
      },
      hasErrors: {
        handler: function (val, oldVal) {
          if (val === true) {
            this.waitingForSave = false;
            this.hasJustBeenSaved = false;
          }
        },
        // deep: true,
      },
    },
    methods: {
      open () {
        this.editState = true;
        this.$nextTick(() => {
          this.$refs.nameInput.$el.focus();
        });
      },
      save () {
        this.waitingForSave = true;
        this.$emit('save', this.name);
      },
      close () {
        this.editState = false;
      },
      reset () {
        //this.name = '';
        this.waitingForSave = false;
        this.close();
      },
      onSearchNameInputChange: function (value) {
        this.editStateHasValue = value && value.trim().length > 0;
      },
    },
    mounted: function () {
      this.name = this.value;
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  $ba-control-button-group-height: 60px;//46px
  .control-button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 392px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    .form-control {
      border-color: $ba-stormy-blue;
      border-top-left-radius: 23px;
      border-bottom-left-radius: 23px;
      height: $ba-control-button-group-height;
      padding-left: 25px;
    }
    .form-group {
      position: relative;
      width: 0;
      overflow: hidden;
      opacity: 0;
      transition: opacity .2s linear, width .2s ease-in-out;
      z-index: 0;
      margin-bottom: 0;
    }
    .btn {
      display: flex;
      width: 260px;
      min-height: $ba-control-button-group-height;
      padding-top: 0;
      padding-bottom: 0;
      align-items: center;
      transition: background-color .2s ease-in-out, color .2s ease-in-out;
    }
    .show-enabled {
      display: none;
    }
    &.just-saved {
      .btn {
        background-color: $red;
        border-color: $red;
        color: #fff;
      }
    }
    &.enable-input {
      .show-default {
        display: none;
      }
      .btn {
        background-color: $ba-stormy-blue;
        color: #fff;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1px;
        width: 140px;
        flex: 0 0 140px;
        &.has-icon {
          padding-left: 0;
          padding-right: 0;
          width: 55px;
          text-align: center;
          flex: 0 0 55px;
        }
      }
      .show-enabled {
        display: block;
      }
      .form-group {
        flex: 1 1 auto;
        width: auto;
        opacity: 1;
      }
      &.is-saving {
        .form-control {
          background: $ba-stormy-blue !important;//Use important to override autofill background (Chrome)
          color: #fff;
        }
        .control-label {
          background: $ba-stormy-blue;
          color: #fff;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      max-width: 340px;
      margin: 0;
      &:not(:first-child) {
        margin-top: 20px;
      }
      .form-group {
        margin-bottom: 0;
      }
    }
  }
</style>

<i18n lang="yaml" locale="de">
  components.partials.general.ba_save_named_button.search_name: 'Name der Suche'
  components.partials.general.ba_save_named_button.save: 'Speichern'
  components.partials.general.ba_save_named_button.search_saved: 'Suche gespeichert'
  components.partials.general.ba_save_named_button.save_search: 'Als Suchauftrag speichern'
</i18n>
