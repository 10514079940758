var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teaser bg-light layout-0" }, [
    _c("div", { staticClass: "container box-inner" }, [
      _vm.caption
        ? _c("h3", { staticClass: "headline h2" }, [
            _vm._v(_vm._s(_vm.caption))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "body" },
        [
          _vm._t("default", [
            _vm.content ? _c("p", [_vm._v(_vm._s(_vm.content))]) : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _vm.buttonLink
                ? _c(
                    "b-btn",
                    { attrs: { variant: "primary", href: _vm.buttonLink } },
                    [_vm._v(_vm._s(_vm.buttonContent))]
                  )
                : _vm.buttonTo
                ? _c(
                    "b-btn",
                    { attrs: { variant: "primary", to: _vm.buttonTo } },
                    [_vm._v(_vm._s(_vm.buttonContent))]
                  )
                : _c(
                    "b-btn",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function(e) {
                          return _vm.$emit("click", e)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.buttonContent))]
                  )
            ],
            1
          )
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }