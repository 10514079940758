<script>
  import BaSearchForm from '@/components/partials/Search/BaSearchForm';

  import { mapGetters } from 'vuex';

  export default {
    extends: BaSearchForm,
    template: '#ba-search-form-template',
    data: () => ({
      search: {
        // place must be set or we get an error!
        place: {
          place: '',
          radius: null,
        },
      },
      defaultSearchOptions: {
        studyCourses: [],
        studyInterests: [],
        grade: '',
        qualifications: [],
        requiredDocuments: [],
        desiredStudyYears: [],
        place: {
          place: '',
          radius: 250,
        },
        excludeBookmarks: false,
        companyName: '',
        includeOccupiedCompanies: false,
      },
    }),
    computed: {
      ...mapGetters(['availableSearchDocuments', 'availableGrades', 'availableQualifications']),
      applicantSearchDocuments: function () {
        const options = [];
        const validSearchItems = this.availableSearchDocuments;
        for (const prop in this.availableSearchDocuments) {
          // noinspection JSUnfilteredForInLoop
          options.push({ text: validSearchItems[prop], value: prop });
        }
        return options;
      },
    },
  };
</script>
