var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "teaser",
      class: _vm.frameClasses,
      attrs: { id: "c" + _vm.data.id }
    },
    [
      _vm.layout !== 2
        ? _c(
            "div",
            { staticClass: "container box-inner" },
            [
              _c("ba-content-common-header", { attrs: { data: _vm.data } }),
              _vm._v(" "),
              _c("ba-content-common-media", { attrs: { data: _vm.data } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "body" },
                [
                  _c("ba-content-common-content", {
                    attrs: { data: _vm.data }
                  }),
                  _vm._v(" "),
                  _c("ba-content-common-button", { attrs: { data: _vm.data } })
                ],
                1
              )
            ],
            1
          )
        : _c("div", { staticClass: "card card-rounded" }, [
            _vm.data.assets && _vm.data.assets.length > 0
              ? _c(
                  "div",
                  { staticClass: "card-image" },
                  [
                    _c("ba-content-common-media", {
                      attrs: { data: _vm.data, "enabled-gradient": false }
                    }),
                    _vm._v(" "),
                    _vm.data.header
                      ? _c(
                          "h4",
                          {
                            staticClass: "card-title card-title-media-overlay"
                          },
                          [_vm._v(_vm._s(_vm.data.header))]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _vm.data.header &&
                !(_vm.data.assets && _vm.data.assets.length > 0)
                  ? _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.data.header))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("ba-content-common-content", { attrs: { data: _vm.data } }),
                _vm._v(" "),
                _c("ba-content-common-button", { attrs: { data: _vm.data } })
              ],
              1
            )
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }