<template>
  <div>
  <b-form-input
    v-bind="inputProperties"
    v-model="modelValue"
    :min="min"
    :max="stepCount"
    type="range"
    class="range-slider"
    :state="state"
    required
  ></b-form-input>
  <span>{{label}}</span>
  </div>
</template>

<script>
  export default {
    props: {
      id: {
        type: String,
        required: false,
      },
      value: {
        required: false,
      },
      unit: {
        type: String,
        required: false,
      },
      prefix: {
        type: String,
        required: false,
      },
      steps: {
        type: Array,
        required: false,
      },
      emptyLabel: {
        type: String,
        required: false,
      },
      mapStepToNull: {
        type: Number,
        required: false,
      },
      min: {
        type: Number,
        required: false,
        default: 0,
      },
      max: {
        type: Number,
        required: false,
        default: 100,
      },
      state: {
        type: Boolean,
        required: false,
        default: null,
      },
      customLabels: {
        type: Object,
        required: false,
        default: null,
      },
    },
    data: () => ({
      internalValue: null,
    }),
    methods: {
      stepToValue: function (step) {
        if (this.mapStepToNull === step) {
          return null;
        }
        return this.steps ? this.steps[step] : step;
      },
      valueToStep: function (value) {
        let result = value;
        if (this.mapStepToNull && value === null) {
          result = this.mapStepToNull;
        } else if (this.steps) {
          result = this.steps.indexOf(value);
          if (result < 0) {
            for (const step in this.steps) {
              const stepValue = this.steps[step];
              if (value > stepValue) {
                result = step;
              }
              if (parseFloat(value) === parseFloat(stepValue)) {
                result = step;
                break;
              }
            }
          }
        }
        return result;
      },
    },
    computed: {
      modelValue: {
        set: function (step) {
          const value = this.stepToValue(step);
          this.internalValue = value;
          this.$emit('input', value);
        },
        get: function () {
          return this.valueToStep(this.internalValue);
        },
      },
      stepCount: function () {
        return this.steps ? Object.keys(this.steps).length - 1 : this.max;
      },
      inputProperties: function () {
        const props = {
        };
        if (this.id) {
          props.id = this.id;
        }

        return props;
      },
      label: function () {
        let label = '';
        if (this.customLabels) {
          const compareValue = this.modelValue === null ? this.value : this.modelValue;
          if (this.customLabels[compareValue]) {
            label = this.customLabels[compareValue];
          }
        }
        if (!label && this.internalValue) {
          label = this.internalValue;
          if (this.prefix) {
            label = `${this.prefix} ${label}`;
          }
          if (this.unit) {
            label = `${label} ${this.unit}`;
          }
        } else if (!label && this.emptyLabel) {
          label = this.emptyLabel;
        }
        return label;
      },
    },
    created: function () {
      if (this.value) {
        this.internalValue = this.value;
      }
    },
    watch: {
      value: function (value) {
        this.internalValue = value;
      },
    },
  };
</script>
