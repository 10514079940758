<template>
  <div class="company-course-matrix" :class="{'show-details': showDetails, 'd-none': filteredStudyOffers.length === 0}" ref="matrix">
    <div class="matrix-top" v-if="showDetails">
      <h3 class="headline" v-if="showSearchedOffersState === 1">{{ $t('components.partials.company.course_matrix.headline_search_offers') }}</h3>
      <h3 class="headline" v-else-if="showSearchedOffersState === 2">{{ $t('components.partials.company.course_matrix.headline_other_offers') }}</h3>
      <h3 class="headline" v-else>{{ $t('components.partials.company.course_matrix.study_interest') }}</h3>
    </div>
    <div class="matrix-head">
      <div class="matrix-row matrix-head-row" :class="{'no-bookmarks': !bookmarksEnabled}">
        <div class="col col-label" v-if="showSearchedOffersState === 1">{{ $t('components.partials.company.course_matrix.headline_search_offers') }}</div>
        <div class="col col-label" v-else-if="showSearchedOffersState === 2">{{ $t('components.partials.company.course_matrix.headline_other_offers') }}</div>
        <div class="col col-label" v-else>{{ $t('components.partials.company.course_matrix.study_interest') }}</div>
        <div class="col col-years">
          <div class="col col-year" v-for="year in availableStudyYears" :key="year">{{ year }}</div>
        </div>
        <div class="col col-bookmark" v-if="bookmarksEnabled">&nbsp;</div>
      </div>
    </div>
    <div class="matrix-body">
      <template v-for="offer in filteredStudyOffers">
        <ba-company-course-offer
          :key="company.id + '_' + offer.studyInterest.id"
          :offer="offer"
          :company-name="company.name"
          :bookmarks-enabled="bookmarksEnabled"
          :years="years"
          :show-details="showDetails"
          :is-bookmarked="isBookmarked(offer)"
          @bookmark-toggle="onToggleBookmark"/>
      </template>
    </div>
  </div>
</template>

<script>
  import isMobileMixin from '@/mixins/isMobile';
  import { removeCompanyBookmark } from '@/api/company';
  import { addBookmark } from '@/api/bookmark';
  import { RECORD_TYPE } from '@/api/constants';
  import BaCompanyCourseOffer from '@/components/partials/Company/BaCompanyCourseOffer';

  export default {
    components: { BaCompanyCourseOffer },
    mixins: [isMobileMixin],
    props: {
      company: {
        type: Object,
        required: true,
      },
      years: {
        type: Array,
        required: true,
      },
      showDetails: {
        type: Boolean,
        required: false,
        default: false,
      },
      bookmark: {
        type: Object,
        required: false,
      },
      bookmarksEnabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      showOnlyBookmarks: {
        type: Boolean,
        required: false,
        default: false,
      },
      showSearchedOffersState: {
        type: Number,
        required: false,
        default: 0,
      },
      visibleStudyInterests: {
        type: Array,
        required: false,
        default: null,
      },
    },
    data: () => ({
      offers: {},
      internalBookmarks: {},
      filteredStudyOffers: [],
    }),
    mounted: function () {
      const self = this;
      if (self.bookmarksEnabled && self.bookmark !== null) {
        self.internalBookmarks = self.bookmark;
      }
      this.setFilteredStudyOffers();
    },
    computed: {
      availableStudyYears: function () {
        return this.years;
      },
    },
    methods: {
      setFilteredStudyOffers: function () {
        const self = this;
        let filteredOffers = [];
        if (self.bookmarksEnabled && self.internalBookmarks !== null) {
          if (self.showOnlyBookmarks || self.excludeBookmarks) {
            for (let i = 0, n = self.company.studyOffers.length; i < n; i++) {
              const offer = self.company.studyOffers[i];
              if (self.isOfferVisible(offer)) {
                filteredOffers.push(offer);
              }
            }
          } else if (self.company.studyOffers) {
            filteredOffers = self.company.studyOffers;
          }
        } else if (self.company.studyOffers) {
          filteredOffers = self.company.studyOffers;
        }
        if (this.visibleStudyInterests !== null && this.visibleStudyInterests.length > 0 && filteredOffers.length > 0) {
          const filterOffersVisibility = [];
          for (let i = 0, n = filteredOffers.length; i < n; i++) {
            const offer = filteredOffers[i];
            for (let si = 0, sn = this.visibleStudyInterests.length; si < sn; si++) {
              if (offer.studyInterest.id === this.visibleStudyInterests[si].id) {
                filterOffersVisibility.push(offer);
                break;
              }
            }
          }
          filteredOffers = filterOffersVisibility;
        }
        self.filteredStudyOffers = filteredOffers.sort((a, b) => {
          const nameA = a.courseName + ' - ' + a.studyInterest.name;
          const nameB = b.courseName + ' - ' + b.studyInterest.name;
          return nameA.localeCompare(nameB);
        });
      },
      onToggleBookmark: function (offer) {
        const self = this;
        if (this.bookmarksEnabled) {
          const bookmarkOffset = this.getBookmarkOffset(offer);
          if (bookmarkOffset !== null) {
            this.internalBookmarks.bookmarks[bookmarkOffset].studyInterest = 0;
            removeCompanyBookmark(this.company.id, offer.studyInterest.id)
              .then(({ data: response }) => {
                let hasBookmarks = false;
                for (let i = 0, n = response.length; i < n; i++) {
                  if (parseInt(response[i].id) === parseInt(self.company.id)) {
                    self.internalBookmarks = response[i];
                    hasBookmarks = true;
                    break;
                  }
                }
                if (!hasBookmarks) {
                  self.internalBookmarks = {};
                  self.$emit('all-bookmarks-removed', this.offer);
                }
                // Call this in case offer rows are only visible if they are bookmarked
                self.setFilteredStudyOffers();
              });
          } else {
            const bookmarkComment = null;
            addBookmark(RECORD_TYPE.COMPANY, {
              recordId: this.company.id,
              type: RECORD_TYPE.BOOKMARK_LIST_DEFAULT,
              comment: bookmarkComment,
              studyInterestId: offer.studyInterest.id,
            })
              .then(({ data: response }) => {
                for (let i = 0, n = response.length; i < n; i++) {
                  if (parseInt(response[i].id) === parseInt(self.company.id)) {
                    self.internalBookmarks = response[i];
                    break;
                  }
                }
              });
          }
        }
      },
      isBookmarked: function (offer) {
        return this.getBookmarkOffset(offer) !== null;
      },
      getBookmarkOffset: function (offer) {
        if (this.internalBookmarks && this.internalBookmarks.bookmarks) {
          const studyInterestId = offer.studyInterest.id;
          const bookmarks = this.internalBookmarks.bookmarks;
          for (let i = 0, n = bookmarks.length; i < n; i++) {
            if (parseInt(bookmarks[i].studyInterestId) === parseInt(studyInterestId)) {
              return i;
            }
          }
        }
        return null;
      },
      isOfferVisible: function (offer) {
        if (this.bookmarksEnabled && (this.showOnlyBookmarks || this.excludeBookmarks)) {
          const isBookmarked = this.isBookmarked(offer);
          return (this.showOnlyBookmarks && isBookmarked) || (this.excludeBookmarks && !isBookmarked);
        }
        return true;
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";

  .company-course-matrix {
    .matrix-head {
      display: none;
    }
    .matrix-row {
      position: relative;
      max-width: 100%;
      overflow: hidden;
    }
    .col {
      display: flex;
      align-items: center;
      min-height: 48px;
      padding: 0;
    }
    .col-year-details {
      display: block;
      flex: 0 0 100%;
    }
    .col-label {
      background: $ba-default-background;
      flex: 0 0 100%;
      font-weight: $ba-font-weight-medium;
      min-height: 66px;
      padding: 0 $ba-company-list-bookmark-link-width 0 18px;
    }
    .col-years {
      display: block;
      padding: 0;
    }
    .col-year {
      flex-wrap: nowrap;
      &:not(:last-child) {
        border-bottom: 1px solid $ba-default-border-color;
      }
    }
    .col-bookmark {
      position: absolute;
      top: 0;
      right: 0;
      width: $ba-company-list-bookmark-link-width;
      padding: 0;
    }
    .matrix-top {
      background: $ba-default-background;
      padding: 22px 15px 16px;
      text-align: center;
      color: $gray-900;
      display: block;
      margin-bottom: 15px;
      .headline {
        display: flex;
        font-weight: $font-weight-light;
        letter-spacing: .02px;
        line-height: 26px;
        text-align: center;
        margin-bottom: 0;
      }
    }
    .matrix-body-row {
      &.no-bookmarks {
        border-bottom: 1px solid $ba-default-border-color;
      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      @include media-breakpoint-up(md) {
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
      @include media-breakpoint-up(xl) {
        .col {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
    &.show-details {
      margin: 4px 0 15px;
      .matrix-body-row {
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }

      @include media-breakpoint-down(sm) {
        .col-bookmark {
          display: flex;
          align-items: center;
          justify-content: center;
          position: static;
          top: auto;
          right: auto;
          background: $ba-stormy-blue;
          color: #fff;
          flex: 0 0 100%;
          width: 100%;
          .btn {
            color: #fff;
            max-width: 137px;
          }
        }
        .col-label {
          background: transparent;
          padding: 20px 15px 14px;
          margin-bottom: 0;
          border-top: 1px solid $ba-default-border-color;
          border-bottom: 1px solid $ba-default-border-color;
        }
      }
    }

    @include media-breakpoint-up(md) {
      .col {
        padding: 10px 18px;
        min-height: 60px;
        &:not(:last-child) {
          border-right: 2px solid $ba-default-border-color;
        }
      }
      .col-year-details {
        padding: 0;
        min-height: 0;
      }
      .col-years {
        display: flex;
        flex: 0 1 calc(65% - #{$ba-company-list-bookmark-link-width});
        max-width: calc(65% - #{$ba-company-list-bookmark-link-width});
        padding: 0;
      }
      .no-bookmarks {
        .col-years {
          flex: 0 1 65%;
          max-width: 65%;
        }
      }
      .matrix-head {
        background-color: $ba-default-background;
        color: #000;
        display: block;
        font-weight: $ba-font-weight-medium;
        margin-bottom: 0;
        .col {
          padding-top: 17px;
          padding-bottom: 17px;
        }
        .col-label {
          display: flex;
          font-weight: $ba-font-weight-medium;
        }
        .col-years {
          padding: 0;
        }
      }
      .matrix-row {
        display: flex;
        flex-wrap: wrap;
        border-bottom-width: 0;
        &:not(:last-child) {
          border-bottom: 2px solid $ba-default-border-color;
        }
      }
      .col-label {
        background: transparent;
        flex: 1 1 35%;
        max-width: 35%;
        font-weight: $font-weight-normal;
        padding-right: 18px;
      }
      .col-year {
        flex: 1 1 0;
        height: 100%;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        &:not(:last-child) {
          border-bottom-width: 0;
        }
      }
      .col-bookmark {
        position: static;
        flex: 0 0 $ba-company-list-bookmark-link-width;
        max-width: $ba-company-list-bookmark-link-width;
        width: auto;
        padding: 0;
      }
      &.show-details {
        border-bottom: 2px solid $ba-default-border-color;
        margin-top: 2px;
        .matrix-top {
          display: none;
        }
        .matrix-head {
          color: $gray-900;
          .col-label {
            font-size: 22px;
            line-height: 31px;
            text-align: left;
          }
        }
        .col {
          min-height: 100px;
          &:not(:last-child) {
            border-right-width: 0;
          }
        }
        .col-year-details {
          min-height: 0;
        }
        .col-label {
          background: transparent;
          padding-left: 45px;
          margin-bottom: 0;
          border-top-width: 0;
          border-bottom-width: 0;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .col-label {
        padding-left: 0;
      }
    }
  }
</style>

<i18n lang="yaml" locale="de">
  components.partials.company.course_matrix.study_interest: ' Studiengang/-richtung'
  components.partials.company.course_matrix.headline_search_offers: 'Ausgewählter Studienwunsch'
  components.partials.company.course_matrix.headline_other_offers: 'Weiteres Studienangebot'
</i18n>
