var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      class: _vm.formGroupClass,
      attrs: { label: _vm.label, "label-for": _vm.id }
    },
    [
      _c(
        "multiselect",
        {
          attrs: {
            id: _vm.id,
            options: _vm.choices,
            multiple: false,
            placeholder: _vm.placeholder
              ? _vm.placeholder
              : _vm.$t("form.select.placeholder.general"),
            "open-direction": "bottom",
            "track-by": "name",
            label: "name",
            searchable: true,
            loading: _vm.isLoading,
            "internal-search": false,
            "close-on-select": true,
            "allow-empty": true,
            "options-limit": 300,
            "limit-text": _vm.limitText,
            "max-height": 600,
            "hide-selected": false
          },
          on: {
            input: _vm.onUpdateChoice,
            open: function($event) {
              return _vm.lazyLoadValues(_vm.value)
            },
            "search-change": _vm.asyncFind
          },
          scopedSlots: _vm._u([
            {
              key: "tag",
              fn: function(ref) {
                var option = ref.option
                var remove = ref.remove
                return [
                  _vm._v("TAG"),
                  _c("span", { staticClass: "custom__tag" }, [
                    _c("span", [_vm._v(_vm._s(option.name))]),
                    _c(
                      "span",
                      {
                        staticClass: "custom__remove",
                        on: {
                          click: function($event) {
                            return remove(option)
                          }
                        }
                      },
                      [_vm._v("❌")]
                    )
                  ])
                ]
              }
            },
            {
              key: "clear",
              fn: function(props) {
                return [
                  _vm.internalInternalValue && _vm.internalInternalValue.length
                    ? _c("div", {
                        staticClass: "multiselect__clear",
                        on: {
                          mousedown: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.clearAll(props.search)
                          }
                        }
                      })
                    : _vm._e()
                ]
              }
            },
            {
              key: "option",
              fn: function(props) {
                return [
                  _c("span", {
                    staticClass: "multiselect__option",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.wrapSearchString(props.option.name, props.search)
                      )
                    }
                  })
                ]
              }
            }
          ]),
          model: {
            value: _vm.internalInternalValue,
            callback: function($$v) {
              _vm.internalInternalValue = $$v
            },
            expression: "internalInternalValue"
          }
        },
        [
          _vm._v(" "),
          _c("template", { slot: "placeholder" }, [
            _vm.value
              ? _c("span", { staticClass: "multiselect__tag" }, [
                  _c("span", [_vm._v(_vm._s(_vm.value))]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "multiselect__tag-icon",
                    attrs: { "aria-hidden": "true", tabindex: "1" },
                    on: {
                      mousedown: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.clearValue()
                      }
                    }
                  })
                ])
              : _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.placeholder
                        ? _vm.placeholder
                        : _vm.$t("form.select.placeholder.general")
                    )
                  )
                ])
          ]),
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
            _vm._v(_vm._s(_vm.$t("kein Ergebnis gefunden")))
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }