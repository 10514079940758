var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      staticClass: "ba-form ba-form-search",
      class: { "has-rating": _vm.ratingsEnabled },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onExecuteSearch($event)
        }
      }
    },
    [
      _vm.ratingsEnabled
        ? _c(
            "b-form-group",
            {
              staticClass: "ba-form-group-custom ba-form-group-ratings",
              attrs: {
                label: _vm.$t("components.partials.search.form.rating"),
                "label-for": "search-ratings-0"
              }
            },
            [
              _c(
                "b-form-checkbox-group",
                {
                  staticClass: "ba-checkbox-group-default",
                  attrs: { id: "checkbox-group-2", name: "ratings" },
                  on: {
                    focusout: function() {
                      this$1.updateComponent()
                    }
                  },
                  model: {
                    value: _vm.search.ratings,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "ratings", $$v)
                    },
                    expression: "search.ratings"
                  }
                },
                [
                  _c(
                    "b-form-checkbox",
                    { attrs: { value: "positive", inline: "" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.partials.search.form.rating.positive"
                          )
                        ) + "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-checkbox",
                    { attrs: { value: "neutral", inline: "" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.partials.search.form.rating.neutral"
                          )
                        ) + "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-checkbox",
                    { attrs: { value: "negative", inline: "" } },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.partials.search.form.rating.negative"
                          )
                        ) + "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "search-ratings-0",
                        value: "unrated",
                        inline: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(
                              "components.partials.search.form.rating.unrated"
                            )
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isApplicantSearch && _vm.isBookmarkSearch
        ? _c(
            "b-form-group",
            {
              staticClass: "ba-form-group-applicant-name",
              attrs: {
                label: _vm.$t("components.partials.search.form.name"),
                "label-for": "search-applicant-name"
              }
            },
            [
              _c("b-form-input", {
                key: _vm.componentKey + "name",
                attrs: {
                  id: "search-applicant-name",
                  type: "text",
                  placeholder: _vm.$t(
                    "components.partials.search.form.name.placeholder"
                  )
                },
                on: {
                  focusout: function() {
                    this$1.updateComponent()
                  }
                },
                model: {
                  value: _vm.search.name,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "name", $$v)
                  },
                  expression: "search.name"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isCompanySearch
        ? _c(
            "b-form-group",
            {
              staticClass: "ba-form-group-company-name",
              attrs: {
                label: _vm.$t("components.partials.search.form.company_name"),
                "label-for": "search-company-name"
              }
            },
            [
              _c("ba-company-name-select", {
                key: _vm.componentKey + "companyName",
                ref: "search-company-name-select",
                attrs: {
                  id: "search-company-name",
                  "form-group-class": "form-group-search-company-name",
                  placeholder: _vm.$t(
                    "components.partials.search.form.company_name.placeholder"
                  )
                },
                on: {
                  input: function() {
                    this$1.updateComponent()
                  }
                },
                model: {
                  value: _vm.search.companyName,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "companyName", $$v)
                  },
                  expression: "search.companyName"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isCompanySearch && _vm.availableSubjectAreas.length > 0
        ? _c("ba-multi-select", {
            ref: "search-subject-areas",
            staticClass:
              "ba-form-group-subject-areas search-additional-form-group",
            class: { "search-additional-show": _vm.toggleAdditionalFields },
            attrs: {
              choices: _vm.availableSubjectAreas,
              label: _vm.$t("components.partials.search.form.subject_areas"),
              placeholder: _vm.$t(
                "components.partials.search.form.subject_areas_placeholder"
              ),
              id: "search-subject-areas",
              multiple: ""
            },
            on: {
              focusout: function() {
                this$1.updateComponent()
              }
            },
            model: {
              value: _vm.search.subjectAreas,
              callback: function($$v) {
                _vm.$set(_vm.search, "subjectAreas", $$v)
              },
              expression: "search.subjectAreas"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.studyOffersVisible
        ? _c("ba-multi-select", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ],
            ref: "search-study-interests",
            staticClass:
              "ba-form-group-study-interests search-additional-form-group",
            class: { "search-additional-show": _vm.toggleAdditionalFields },
            attrs: {
              value: _vm.tmpStudyInterests,
              choices: _vm.studyInterestGroupedChoices,
              label: _vm.$t(
                "components.partials.search.form.study_interests_default"
              ),
              placeholder: _vm.$t(
                "components.partials.search.form.study_interests_placeholder"
              ),
              id: "search-study-interests",
              multiple: "",
              grouped: ""
            },
            on: {
              input: _vm.setSelectedStudyInterests,
              focusout: function() {
                this$1.updateComponent()
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.availableStudyYears.length > 0
        ? _c(
            "b-form-group",
            {
              staticClass:
                "ba-form-group-custom ba-form-group-desired-study-years",
              attrs: {
                label: _vm.$t(
                  "components.partials.search.form.desired_study_year"
                ),
                "label-for": "search-desired-study-years"
              }
            },
            [
              _c(
                "b-form-checkbox-group",
                {
                  staticClass: "ba-checkbox-group-lg-down",
                  attrs: {
                    id: "search-desired-study-years",
                    name: "desiredStudyYears"
                  },
                  on: {
                    input: function() {
                      this$1.updateComponent()
                    }
                  },
                  model: {
                    value: _vm.search.desiredStudyYears,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "desiredStudyYears", $$v)
                    },
                    expression: "search.desiredStudyYears"
                  }
                },
                _vm._l(_vm.availableStudyYears, function(availableStudyYear) {
                  return _c(
                    "b-form-checkbox",
                    {
                      key: availableStudyYear,
                      attrs: {
                        id: "search-desired-study-year-" + availableStudyYear,
                        value: availableStudyYear,
                        inline: ""
                      }
                    },
                    [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(_vm._s(availableStudyYear))
                      ])
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          staticClass: "ba-form-group-place",
          attrs: {
            label: _vm.$t("components.partials.search.form.place"),
            "label-for": "search-place"
          }
        },
        [
          _c("ba-location-select", {
            ref: "search-location-select",
            attrs: {
              id: "search-place",
              value: _vm.tmpSearchPlace,
              "form-group-class": "ba-form-group-search-place",
              placeholder: _vm.$t(
                "components.partials.search.form.place.placeholder"
              )
            },
            on: { input: _vm.setSearchPlace }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-group",
        {
          staticClass: "ba-form-group-place-radius",
          attrs: {
            label: _vm.$t("components.partials.search.form.place.radius"),
            "label-for": "search-place-range"
          }
        },
        [
          _c("ba-range", {
            attrs: {
              value: _vm.tmpSearchRadius,
              steps: _vm.availableDistancesWithoutNull,
              unit: "km",
              prefix: "+",
              id: "search-place-range"
            },
            on: { input: _vm.setSearchRadius }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isApplicantSearch
        ? _c("ba-multi-select", {
            staticClass: "ba-form-group-qualifications",
            attrs: {
              value: _vm.tmpQualifications,
              choices: _vm.availableQualifications,
              label: _vm.$t("components.partials.search.form.qualifications"),
              placeholder: _vm.$t(
                "components.partials.search.form.qualifications_placeholder"
              ),
              id: "search-qualifications",
              multiple: ""
            },
            on: { input: _vm.setSelectedQualifications }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isApplicantSearch
        ? _c(
            "b-form-group",
            {
              staticClass: "ba-form-group-applicant-grade",
              attrs: {
                label: _vm.$t("components.partials.search.form.grade"),
                "label-for": "search-applicant-grade"
              }
            },
            [
              _c("ba-range", {
                attrs: {
                  steps: _vm.availableGrades,
                  prefix: _vm.$t(
                    "components.partials.search.form.grade.prefix"
                  ),
                  "empty-label": _vm.$t(
                    "components.partials.search.form.search.grade.empty"
                  )
                },
                model: {
                  value: _vm.search.grade,
                  callback: function($$v) {
                    _vm.$set(_vm.search, "grade", $$v)
                  },
                  expression: "search.grade"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isApplicantSearch
        ? _c(
            "b-form-group",
            {
              staticClass:
                "ba-form-group-custom ba-form-group-required-documents",
              attrs: {
                label: _vm.$t(
                  "components.partials.search.form.required_documents"
                ),
                "label-for": "search-required-documents"
              }
            },
            [
              _c("b-form-checkbox-group", {
                staticClass: "ba-checkbox-group-default",
                attrs: {
                  id: "search-required-documents",
                  options: _vm.applicantSearchDocuments,
                  name: "requiredDocuments",
                  stacked: ""
                },
                on: { input: _vm.setSelectedRequiredDocuments },
                model: {
                  value: _vm.tmpRequiredDocuments,
                  callback: function($$v) {
                    _vm.tmpRequiredDocuments = $$v
                  },
                  expression: "tmpRequiredDocuments"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAuthenticated && !_vm.isBookmarkSearch
        ? _c(
            "b-form-group",
            {
              staticClass: "ba-form-group-exclude-bookmarks",
              attrs: {
                label: _vm.$t(
                  _vm.isApplicantSearch
                    ? "components.partials.search.form.exclude_applicant_bookmarks"
                    : "components.partials.search.form.exclude_company_bookmarks"
                ),
                "label-for": "search-exclude-bookmarks"
              }
            },
            [
              _c("ba-switch", {
                attrs: {
                  id: "search-exclude-bookmarks",
                  value: _vm.tmpExcludeBookmarks
                },
                on: { input: _vm.setExcludeBookmarks }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isCompanySearch
        ? _c(
            "b-form-group",
            {
              staticClass: "ba-form-group-include-occupied",
              attrs: {
                label: _vm.$t(
                  "components.partials.search.form.exclude_occupied"
                ),
                "label-for": "search-include-occupied"
              }
            },
            [
              _c("ba-switch", {
                attrs: {
                  id: "search-include-occupied",
                  value: _vm.tmpExcludeOccupiedCompanies
                },
                on: { input: _vm.setExcludeOccupiedCompanies }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isApplicantSearch &&
      _vm.searchId &&
      _vm.availableSearchNotificationIntervals
        ? _c("ba-multi-select", {
            ref: "search-notification-interval",
            staticClass: "ba-form-group-notification-interval",
            attrs: {
              value: _vm.search.notificationInterval || {},
              choices: _vm.availableSearchNotificationIntervals,
              label: _vm.$t(
                "components.partials.search.form.notification_interval"
              ),
              id: "search-notification-interval"
            },
            on: { input: _vm.setSelectedSearchNotificationInterval }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isCompanySearch
        ? _c("ba-multi-select", {
            staticClass: "ba-form-group-region search-additional-form-group",
            class: { "search-additional-show": _vm.toggleAdditionalFields },
            attrs: {
              choices: _vm.availableRegionChoices,
              "choice-label": "text",
              label: _vm.$t("components.partials.search.form.region"),
              placeholder: _vm.$t(
                "components.partials.search.form.region_placeholder"
              ),
              id: "search-region"
            },
            model: {
              value: _vm.tmpRegion,
              callback: function($$v) {
                _vm.tmpRegion = $$v
              },
              expression: "tmpRegion"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isCompanySearch
        ? _c("ba-multi-select", {
            staticClass:
              "ba-form-group-enterprise-size search-additional-form-group",
            class: { "search-additional-show": _vm.toggleAdditionalFields },
            attrs: {
              choices: _vm.availableEnterpriseSizesChoices,
              "choice-label": "text",
              label: _vm.$t("components.partials.search.form.enterprise_size"),
              placeholder: _vm.$t(
                "components.partials.search.form.enterprise_size_placeholder"
              ),
              id: "search-enterprise-size"
            },
            model: {
              value: _vm.tmpEnterpriseSize,
              callback: function($$v) {
                _vm.tmpEnterpriseSize = $$v
              },
              expression: "tmpEnterpriseSize"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isCompanySearch
        ? _c(
            "b-form-group",
            {
              staticClass:
                "ba-form-group-custom ba-form-group-company-type search-additional-form-group",
              class: { "search-additional-show": _vm.toggleAdditionalFields },
              attrs: {
                label: _vm.$t("components.partials.search.form.company_type"),
                "label-for": "search-company-type"
              }
            },
            [
              _c(
                "b-form-checkbox-group",
                {
                  staticClass: "ba-checkbox-group-lg-down",
                  attrs: {
                    id: "search-company-type",
                    name: "company-type",
                    multiple: true
                  },
                  on: {
                    input: function() {
                      this$1.updateComponent()
                    }
                  },
                  model: {
                    value: _vm.tmpCompanyType,
                    callback: function($$v) {
                      _vm.tmpCompanyType = $$v
                    },
                    expression: "tmpCompanyType"
                  }
                },
                _vm._l(_vm.availableCompanyTypeChoices, function(
                  ctChoiceObject
                ) {
                  return _c(
                    "b-form-checkbox",
                    {
                      key: "companyType-" + ctChoiceObject.id,
                      attrs: {
                        id: "search-company-type-" + ctChoiceObject.id,
                        value: ctChoiceObject.id,
                        inline: ""
                      }
                    },
                    [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(_vm._s(ctChoiceObject.text))
                      ])
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isCompanySearch
        ? _c("ba-multi-select", {
            staticClass: "ba-form-group-focus",
            attrs: {
              choices: _vm.availableFocusChoices,
              "choice-label": "text",
              label: _vm.$t("components.partials.search.form.focus"),
              placeholder: _vm.$t(
                "components.partials.search.form.focus_placeholder"
              ),
              id: "search-focus"
            },
            model: {
              value: _vm.tmpFocus,
              callback: function($$v) {
                _vm.tmpFocus = $$v
              },
              expression: "tmpFocus"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isBookmarkSearch
        ? _c(
            "div",
            { staticClass: "row-form-actions" },
            [
              _c(
                "b-btn",
                {
                  staticClass: "btn-ba-action",
                  attrs: { variant: "primary" },
                  on: { click: _vm.onExecuteSearch }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("components.partials.search.form.actions.filter")
                      ) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  staticClass: "btn-ba-action btn-reset d-none d-xl-block",
                  attrs: { variant: "text" },
                  on: { click: _vm.onResetSearch }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("components.partials.search.form.actions.reset")
                      ) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "row-form-actions" },
            [
              _c(
                "b-btn",
                {
                  staticClass: "btn-ba-action",
                  attrs: { variant: "primary" },
                  on: { click: _vm.onExecuteSearch }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("components.partials.search.form.actions.save")
                      ) +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _vm.isActiveSearch
                ? _c(
                    "b-btn",
                    {
                      staticClass: "btn-ba-action btn-reset d-none d-xl-block",
                      attrs: { variant: "text" },
                      on: { click: _vm.onResetSearch }
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.$t(
                              "components.partials.search.form.actions.reset"
                            )
                          ) +
                          "\n    "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.saveEnabled
                ? _c("ba-save-named-button", {
                    attrs: {
                      variant: "outline-primary",
                      "save-icon": "check",
                      "is-saving": _vm.isSaving,
                      "has-errors": _vm.hasErrors,
                      "input-state": _vm.saveFormInputState
                    },
                    on: { save: _vm.onUpdateSearchName },
                    model: {
                      value: _vm.search.name,
                      callback: function($$v) {
                        _vm.$set(_vm.search, "name", $$v)
                      },
                      expression: "search.name"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.saveFormErrors && _vm.saveFormErrors.name
                ? _c("div", { staticClass: "form-errors" }, [
                    _vm.saveFormErrors.name === "unique"
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "components.partials.search.form.error_feedback.name.unique"
                                )
                              ) +
                              "\n      "
                          )
                        ])
                      : _vm.saveFormErrors.name.indexOf("UniqueSearch") >= 0
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "components.partials.search.form.error_feedback.unique_search"
                                )
                              ) +
                              "\n      "
                          )
                        ])
                      : _c("div", { staticClass: "alert alert-danger" }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "components.partials.search.form.error_feedback.name.required"
                                )
                              ) +
                              "\n      "
                          )
                        ])
                  ])
                : _vm._e()
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }