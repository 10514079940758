var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "radio-toggle" }, [
    _c("div", { staticClass: "switch-wrapper" }, [
      _c("label", { staticClass: "switch" }, [
        _c(
          "input",
          _vm._b(
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.modelValue,
                  expression: "modelValue"
                }
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.modelValue)
                  ? _vm._i(_vm.modelValue, null) > -1
                  : _vm.modelValue
              },
              on: {
                change: function($event) {
                  var $$a = _vm.modelValue,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.modelValue = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.modelValue = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.modelValue = $$c
                  }
                }
              }
            },
            "input",
            _vm.inputProperties,
            false
          )
        ),
        _vm._v(" "),
        _c("span", { staticClass: "slider round" })
      ]),
      _vm._v(" "),
      _vm.preventChange
        ? _c("div", {
            staticClass: "switch-overlay",
            on: {
              click: function($event) {
                return _vm.$emit("prevent-click")
              }
            }
          })
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "switch-label" }, [
      _vm._v(_vm._s(_vm.computedLabel))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }