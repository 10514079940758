<template>
  <b-link class="ba-icon-link" :to="to" v-if="to">
    <span class="inner-wrap">
      <font-awesome-icon class="icon" size="sm" :icon="icon"/>
      <span class="text">
        <slot></slot>
      </span>
    </span>
  </b-link>
  <b-link class="ba-icon-link" :to="route" v-else-if="route && route.name">
    <span class="inner-wrap">
      <font-awesome-icon class="icon" :icon="icon"/>
      <span class="text">
        <slot></slot>
      </span>
    </span>
  </b-link>
  <b-link class="ba-icon-link" :href="route.url" v-else-if="route && route.url">
    <span class="inner-wrap">
      <font-awesome-icon class="icon" :icon="icon"/>
      <span class="text">
        <slot></slot>
      </span>
    </span>
  </b-link>
  <b-link class="ba-icon-link" @click="onClick" v-else>
    <span class="inner-wrap">
      <font-awesome-icon class="icon" size="sm" :icon="icon"/>
      <span class="text">
        <slot></slot>
      </span>
    </span>
  </b-link>
</template>

<script>
  export default {
    props: {
      icon: {
        type: [Array, String],
        required: true,
      },
      to: {
        type: Object,
        required: false,
        default: null,
      },
      route: {
        type: Object,
        required: false,
        default: null,
      },
    },
    methods: {
      onClick: function (e) {
        this.$emit('click', e);
        if (this.to) {
          this.$router.push(this.to);
        }
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  .ba-icon-link {
    display: inline-flex;
    align-items: center;
    .inner-wrap {
      display: inline-flex;
      align-items: center;
      > .text {
        display: inline-flex;
        align-items: center;
      }
    }
    .icon {
      margin: -2px 6px 0 0;
    }
  }
</style>
