var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-result-count hide-while-loading",
      class: { "has-reset": _vm.showReset }
    },
    [
      _c("span", { staticClass: "result-count-info" }, [
        _c("span", { staticClass: "text-secondary" }, [
          _vm._v(_vm._s(_vm.results))
        ]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            _vm._s(
              _vm.$tc(
                "components.partials.general.pagination.results",
                _vm.results
              )
            )
          )
        ])
      ]),
      _vm._v(" "),
      _vm.showReset
        ? _c(
            "span",
            { staticClass: "btn-reset", on: { click: _vm.onResetSearch } },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.partials.general.actions.reset")) +
                  "\n  "
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }